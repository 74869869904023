import "./FolderOffcanvas.css";
import { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaFolderMinus } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import moment from "moment";
import MoveFile from "../modals/MoveFile";
import DeleteConfirmFile from "../modals/DeleteConfirmFile";
import RenameFile from "../modals/RenameFile";
import { MdArrowBackIosNew } from "react-icons/md";
import { Link } from "react-router-dom";
import CreateFolder from "../modals/CreateFolder";
import DeleteConfirm from "../modals/DeleteConfirm";
import { BaseUrl } from "../../common/BaseUrl";
import ShareFolder from "../modals/ShareFolder";
import ShareFile from "../modals/ShareFile";
import MainIcons from "../MainIcons/MainIcons";
const FolderOffcanvas = ({
  canvasModalStatus,
  setCanvasModalStatus,
  folderData,
}) => {
  const [createFolder, setCreateFolder] = useState(false);
  const [currentFolder, setCurrentFolder] = useState([]);
  const [folderShare, setFolderShare] = useState(false);
  const [folderShares, setFolderShares] = useState(false);
  const [downloadFolders, setDownloadFolders] = useState("");

  useEffect(() => {
    setCurrentFolder(folderData);
  }, [folderData]);

  const [show, setShow] = useState(false);
  //   const [files, setFiles] = useState("");
  const handleClose = () => {
    setPdfQr(null);
    setShow(false);
    setCanvasModalStatus(false);
    setCurrentFolder(folderData);
    setActiveFile(null);
  };

  useEffect(() => {
    setShow(canvasModalStatus);
  }, [canvasModalStatus]);

  // const setFiles = {folderData}
  console.log(currentFolder, "hellow");
  const [moveFile, setMoveFile] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const [fileReNameModal, setFileReNameModal] = useState(false);
  const [folderDatas, setFolderDatas] = useState();
  const [createFolders, SetCreateFolders] = useState();
  const [activeFile, setActiveFile] = useState();
  const regex = /([^/]+)$/;

  const fetchOpenFile = async (data) => {
    try {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.post(
        BaseUrl + `api/folder/generate-link`,
        {
          filePath: data?.Key || data?.key,
        },
        {
          headers: { Authorization: token },
        }
      );
      if (response) {
        // setDlFiles(response?.data?.data);
        window.open(response?.data?.data);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const fetchfolderDownload = async (folderId) => {
    try {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.post(
        BaseUrl + `api/folder/downloadFolder`,
        {
          folderId: folderId,
        },
        {
          headers: { Authorization: token },
        }
      );
      if (response) {
        window.open(response?.data?.data?.result);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const [pdfQr, setPdfQr] = useState();
  const handlePdfEditor = async (val) => {
    setCanvasModalStatus(true);
    setFolderDatas(val);

    localStorage.setItem("activeFile", JSON.stringify(val));

    try {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.post(
        BaseUrl + `api/folder/generate-link`,
        {
          filePath: val?.Key || val?.key,
        },
        {
          headers: { Authorization: token },
        }
      );
      if (response) {

        let token = JSON.parse(localStorage.getItem("authUser")).token;
        setPdfQr(
          "https://pdf.u-vault.io?accesstoken=" +
            token +
            "&qr-url=" +
            val?._id +
            "&add-qr=" +
            response?.data?.data
        );
      }
      setActiveFile(response?.data?.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const renderFileContent = () => {
    console.log(folderDatas, "folderDatasfolderDatas");

    const fileExtension = folderDatas?.extension?.toLowerCase();
    let token = JSON.parse(localStorage.getItem("authUser")).token;

    if ([".pdf"].includes(fileExtension)) {
      if (folderDatas?.unStamped_key) {
        return (
          <iframe
            title="Doc Viewer"
            width="100%"
            height="100%"
            src={`https://docs.google.com/viewer?embedded=true&url=${activeFile}`}
            frameBorder="0"
          />
        );
      } else {
        return (
          <iframe
            title="Doc Viewer"
            width="100%"
            height="100%"
            src={
              "https://pdf.u-vault.io?accesstoken=" +
              token +
              "&qr-url=" +
              folderDatas?._id +
              "&add-qr=" +
              activeFile
            }
            frameBorder="0"
          />
        );
      }

      //  <iframe title="Doc Viewer" width="100%" height="100%" src={"https://pdf.u-vault.io?accesstoken="+token+"&qr-url="+folderData?._id+"&add-qr="+files} frameBorder="0" />
      //  return  <iframe title="Doc Viewer" width="100%" height="100%" src={"/pdf?accesstoken="+token+"&qr-url="+folderData?._id+"&add-qr="+files} frameBorder="0" />
    } else if (
      [
        ".doc",
        ".docx",
        ".txt",
        ".csv",
        ".xml",
        ".pptx",
        ".ppt",
        ".xlsx",
        ".zip",
      ].includes(fileExtension)
    ) {
      return (
        <div class="downloadLinkDiv downloadLinkDiv02 d-flex justify-content-center text-center">
          <h4>
            We're currently unable to open this file on our platform. We're
            working on a fix but in the meantime, you can download it from the
            below.
          </h4>
          <div>
            <a href={activeFile} download="" class="downloadLink">
              Download
            </a>
          </div>
        </div>
      );
    } else if ([".png", ".jpg", ".gif", ".jpeg"].includes(fileExtension)) {
      return <img src={activeFile} alt="" />;
    } else if ([".mp4", ".mkv"].includes(fileExtension)) {
      return (
        <video width="100%" height="600" controls>
          <source src={activeFile} type="video/mp4" />
        </video>
      );
    }
    return null;
  };

  return (
    <>
      <ShareFile
        folderShare={folderShares}
        setFolderShare={setFolderShares}
        folderData={folderDatas}
      />
      <ShareFolder
        folderShare={folderShare}
        setFolderShare={setFolderShare}
        folderDatas={folderDatas}
      />
      <MoveFile
        moveFile={moveFile}
        setMoveFile={setMoveFile}
        folderData={folderDatas}
      />
      <DeleteConfirmFile
        folderData={folderDatas}
        deleteModal={deleteFileModal}
        setDeleteModal={setDeleteFileModal}
      />
      <DeleteConfirm
        folderData={currentFolder}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
      />
      <RenameFile
        folderData={folderDatas}
        fileReNameModal={fileReNameModal}
        setFileReNameModal={setFileReNameModal}
      />
      <CreateFolder
        createFolder={createFolder}
        setCreateFolder={setCreateFolder}
        createFolders={createFolders}
      />
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Link
          className="mb-5 text-dark d-flex align-items-center"
          onClick={handleClose}
        >
          <MdArrowBackIosNew /> Back
        </Link>
        <Offcanvas.Header className="p-0 mb-lg-5 mb-4">
          {pdfQr ? (
            <Offcanvas.Title className="d-flex w-100 justify-content-end">
            <span className="folder-icon align-middle">
                     <FaFolderMinus />
                     {/* <img
                       src={
                         [".png", ".jpg", ".gif", ".jpeg"].includes(
                           folderDatas?.extension
                         )
                           ? Photo
                           : [".mp4", ".mkv"].includes(folderDatas?.extension)
                           ? Video
                           : [".mp3"].includes(folderDatas?.extension)
                           ? ""
                           : [".doc", ".docx", ".txt", ".csv", ".xml", ".pdf"].includes(
                               folderDatas?.extension
                             )
                           ? File
                           : Folder
                       }
                       alt=""
                     /> */}
                     <MainIcons val={folderDatas?.extension}/>
                   </span>
                   <span className="ps-3">
                     {folderDatas?.name && folderDatas?.name?.length > 22
                       ? folderDatas?.name?.substring(0, 22) +
                         "..." +
                         folderDatas?.extension
                       : folderDatas?.name}
                   </span>
                 </Offcanvas.Title>
          ):(<Offcanvas.Title className="d-flex w-100 justify-content-between">
              <div>
                <span className="folder-icon align-middle">
                  <FaFolderMinus />
                </span>
                <span className="ps-3">
                  {currentFolder?.name?.match(regex)?.[0]}
                </span>
              </div>
              <div>
                <button
                  className="common-btn"
                  onClick={() => {
                    setCreateFolder(true);
                    SetCreateFolders(currentFolder?.name);
                  }}
                >
                  Create Folder
                </button>
              </div>
            </Offcanvas.Title>)}
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {/* {pdfQr ? (
            <iframe
              title="Doc Viewer"
              width="100%"
              height="700"
              src={pdfQr}
              frameBorder="0"
            />
          ) : activeFile ? (
            <div className={`col-xxl-4 col-md-4 col-sm-6 mb-4 order-1`}>
              <img src={activeFile} alt="" />
            </div>
          ) : ( */}
          {pdfQr ? (
            renderFileContent()
          ) : (
            <>
              <div className="d-flex">
                {currentFolder?.children &&
                  currentFolder?.children.map((val) => (
                    <div
                      className={`col-xxl-4 col-md-4 col-sm-6 mb-4 order-1 mx-4 ${val.name}`}
                      onClick={() => setCurrentFolder(val)}
                    >
                      <div className="folder-block">
                        <div className="folder-body d-flex align-items-center justify-content-between p-xl-3 px-2 py-3">
                          <div className="pointer">
                            <span className="folder-icon align-middle">
                              <FaFolderMinus />
                            </span>
                            <div className="d-inline-block align-middle ps-xl-4 ps-md-3 ps-2">
                              <p className="f-16 mb-1 position-relative">
                                {/* <span>{val?.name}</span> */}
                                <span>{val?.name?.match(regex)?.[0]}</span>
                              </p>
                              {/* <p className="f-14">{moment(val.LastModified).format('HH:mm:ss a YYYY-MM-DD')}</p> */}
                              <p className="f-14">
                                {moment(val.LastModified).format("L")}
                              </p>
                            </div>
                          </div>
                          <div
                            className="menu-dot"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Dropdown>
                              <Dropdown.Toggle
                                className="bg-transparent border-0 w-auto after-none"
                                id="f-option"
                              >
                                <BsThreeDotsVertical className="t-color" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="f-14">
                                <Dropdown.Item
                                  onClick={() => setCurrentFolder(val)}
                                >
                                  View Details
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setDeleteModal(true);
                                    setCurrentFolder(val);
                                  }}
                                >
                                  Delete
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    fetchfolderDownload(val);
                                    setDownloadFolders(val);
                                  }}
                                >
                                  Download
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setFolderShares(true);
                                    setFolderDatas(val);
                                  }}
                                >
                                  Share{" "}
                                </Dropdown.Item>
                                {/*  <Dropdown.Item onClick={() =>{ setRenameModal(true); setFolderData(val)}}>

                          {/*  <Dropdown.Item onClick={() =>{ setRenameModal(true); setFolderData(val)}}>
                      Rename
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() =>{ setRenameModal(true); setFolderData(val)}}>
                      Download
                    </Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="folder-footer d-flex align-items-center justify-content-between px-lg-4 px-3 py-3 mt-lg-3">
                          <div className="">
                            <strong className="f-12">Total Documents</strong>
                            <span className="f-12 ps-2">{val?.totalFiles}</span>
                          </div>
                          <div className="">
                            <strong className="f-12">Storage</strong>
                            <span className="f-12 ps-2">{val?.totalSize}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="table-responsive folder-info">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th className="f-14 fw-400">Date of creation</th>
                      <th className="f-14 fw-400">Number of files included</th>
                      <th className="f-14 fw-400">Storage</th>
                      <th className="f-14 fw-400">Date Modified</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className="f-14 fw-600">
                          {" "}
                          {moment(currentFolder?.createdAt).format("ll")}
                        </span>
                      </td>
                      <td>
                        <span className="f-14 fw-600">
                          {currentFolder?.totalFiles} Files
                        </span>
                      </td>
                      <td>
                        <span className="f-14 fw-600">
                          {currentFolder?.totalSize}
                        </span>
                      </td>
                      <td>
                        <span className="f-14 fw-600">
                          {" "}
                          {moment(currentFolder?.updatedAt).format("ll")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h3 className="f-20 mb-lg-5 mt-lg-4 mb-4">Document details</h3>
              <div className="recent-data table-responsive">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th>
                        Name
                        {/* <BiSolidSortAlt /> */}
                      </th>
                      <th>Received On</th>
                      <th>Document Size</th>
                      <th>Last Modified</th>
                      <th>Issuer</th>
                      <th>Document ID</th> <th>Action</th>
                    </tr>
                  </thead>
                  {currentFolder?.files?.map((data) => (
                    <tbody>
                      <tr className="pe-auto" style={{ cursor: "pointer" }}>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="file-icon">
                              {/* <img
                                src={
                                  [".png", ".jpg", ".gif", ".jpeg"].includes(
                                    data?.extension
                                  )
                                    ? Photo
                                    : [".mp4", ".mkv"].includes(data?.extension)
                                    ? Video
                                    : [".mp3"].includes(data?.extension)
                                    ? ""
                                    : [
                                        ".doc",
                                        ".docx",
                                        ".txt",
                                        ".csv",
                                        ".xml",
                                        ".pdf",
                                      ].includes(data?.extension)
                                    ? File
                                    : Folder
                                }
                                alt=""
                              /> */}
                              <MainIcons val={data?.extension}/>
                            </span>
                            <span
                              className="file-name f-14 t-color fw-500"
                              onClick={() => handlePdfEditor(data)}
                            >
                              {data?.name}
                            </span>
                          </div>
                        </td>
                        <td>{moment(data?.LastModified).format("ll")}</td>
                        <td>{data?.Size}</td>
                        <td>{moment(data?.LastModified).format("ll")}</td>{" "}
                        <td>{data?.domain}</td> <td>{data?.documentId}</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              className="bg-transparent border-0 w-auto after-none"
                              id="f-option"
                            >
                              <BsThreeDotsVertical className="t-color" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="f-14">
                              {[".pdf"].includes(data?.extension) ? (
                                <Dropdown.Item
                                  onClick={() => {
                                    handlePdfEditor(data);
                                  }}
                                >
                                  Add Stamp
                                </Dropdown.Item>
                              ) : (
                                ""
                              )}
                              <Dropdown.Item
                                onClick={() => {
                                  setMoveFile(true);
                                  setFolderDatas(data);
                                }}
                              >
                                Move File
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setDeleteFileModal(true);
                                  setFolderDatas(data);
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setFileReNameModal(true);
                                  setFolderDatas(data);
                                }}
                              >
                                Rename
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setFolderShare(true);
                                  setFolderDatas(data);
                                }}
                              >
                                Share{" "}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  fetchOpenFile(data);
                                }}
                              >
                                Download
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default FolderOffcanvas;
