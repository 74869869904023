import React, { useEffect, useState } from "react";
import TopBar from "../topbar/TopBar";
import { Link, useNavigate } from "react-router-dom";
import { BsCheckLg, BsQuestionCircle } from "react-icons/bs";
import { Form } from "react-bootstrap";
import ChangePassword from "../auth/ChangePassword";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { fireToast } from "../../common/Toster";
import { updateProfile } from "../../services/userServices";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { BaseUrl } from "../../common/BaseUrl";
import SendOtpModal from "../modals/SendOtpModal";
import { RiVerifiedBadgeFill } from "react-icons/ri";

const EditProfile = () => {
  //meta title
  document.title = "Edit Profile";
  const [value, setValue] = useState();
  const [profile, setProfile] = useState("");

  const navigate = useNavigate();

  const [myimage, setMyImage] = React.useState(null);

  const uploadImage = async (value) => {
    const localData = JSON.parse(localStorage.getItem("authUser"));
    const token = localData?.token;
    // const [file] = files;
    console.log(value,'files');
    try {
      const resp = await updateProfile({ file: value },{
        headers: {
          Authorization: token,
        },
      });
      if (resp.status === 200) {
        getMyProfile();
        window.location.reload();
      } else {
        throw new Error(resp.data.error);
      }
    } catch (err) {}
  };
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      file: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      dial_code: "",
      country_code: "",
    },
    onSubmit: async (values) => {
      try {
        // changeApiStatus(true, "");
        const loginResponse = await updateProfile(values);
        if (loginResponse.status === 200) {
          fireToast("success", loginResponse.message);
          console.log(loginResponse, "loginResponse");
          // changeApiStatus(false, "");
          navigate("/edit-profile");
        } else {
          // changeApiStatus(false, "");
          // throw new Error(loginResponse.error);
        }
      } catch (err) {
        console.log(err);
        fireToast("error", err.response.data.error);
      }
    },
  });
  const localData = JSON.parse(localStorage.getItem("authUser"));
  const token = localData?.token;

  useEffect(() => {
    getMyProfile();
  }, []);

  const getMyProfile = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(BaseUrl + "api/user/myProfile", {
        headers: {
          Authorization: token,
        },
      });

      setProfile(response?.data?.data?.userDetails);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  const [isUserIdVisible, setIsUserIdVisible] = useState(false);
  const userId = profile?.userId;

  const handleUserIdClick = () => {
    setIsUserIdVisible(!isUserIdVisible);
  };

  const renderUserId = () => {
    if (isUserIdVisible) {
      return userId;
    } else {
      return "****-****-****-****";
    }
  };

  return (
    <>
      <TopBar />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <div className="d-flex align-items-center justify-content-between mb-5">
          <h4 className="f-24 d-flex align-items-center">Your Profile</h4>
          {/* <button className="common-btn profilesave" type="submit">
            Save Changes
          </button> */}
        </div>
        <div className="row">
          <div className="col-lg-6 mb-4 mb-lg-5">
            <div className="d-flex align-items-center">
              <label htmlFor="test" className="user-img pointer">
                <img
                  src={BaseUrl + `uploads/${profile.profilePic}`}
                  alt="custom-pic"
                />
                <input
                  name="file"
                  className="form-control"
                  placeholder="First Name"
                  type="file"
                  id="test"
                  onChange={(e)=>uploadImage(e.target.files[0])}
                  onBlur={validation.handleBlur}
                  value={validation.values.file || ""}
                  invalid={
                    validation.touched.file && validation.errors.file
                      ? true
                      : false
                  }
                />
              </label>

              <div className="ps-4">
                <h6 className="f-16 text-black">
                  {profile?.firstName} {profile?.lastName}
                </h6>
                <div>
                  <div
                    className="user-id profilesave fw-700 mb-2 text-center"
                    onClick={handleUserIdClick}
                    style={{ cursor: "pointer" }}
                  >
                    User ID: {renderUserId()}
                  </div>
                  {/* Other components and content */}
                </div>
                <span className="profilesave">{profile?.email}</span>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6 mb-4 mb-lg-5">
            <div className="row align-items-start p-3 profile-bg">
              <div className="col-md-4">
                <h2 className="f-24 t-pink fw-400">
                  <span className="fw-600">Basic</span> Account
                </h2>
                <Link className="current-acc" to="/">
                  Current Account Type
                </Link>
              </div>
              <div className="col-md-8">
                <ul className="stroge-info">
                  <li>
                    <BsCheckLg className="f-28 t-pink" />{" "}
                    <span className="ps-4 f-22 text-black">
                      3 GB of storage
                    </span>
                  </li>
                  <li>
                    <BsCheckLg className="f-28 t-pink" />{" "}
                    <span className="ps-4 f-22 text-black">
                      All Files live locally
                    </span>
                  </li>
                  <li>
                    <BsCheckLg className="f-28 t-pink" />{" "}
                    <span className="ps-4 f-22 text-black">
                      Sync across only 3 devices
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
        <div className="profile-bg p-5">
          <h5 className="ms-5 mb-3">General Details</h5>
          <div className="row justify-content-around">
            <div className="col-lg-5 mb-3">
              <label className="f-14 fw-400 mb-2">First Name</label>
              <input
                name="firstName"
                className="form-control"
                placeholder="First Name"
                readOnly
                type="firstName"
                style={{ backgroundColor: "#FFFFFF" }}
                disabled
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.firstName || profile.firstName}
                invalid={
                  validation.touched.firstName && validation.errors.firstName
                    ? true
                    : false
                }
              />
              {validation.touched.firstName && validation.errors.firstName ? (
                <p className="text-danger">{validation.errors.firstName}</p>
              ) : null}
            </div>
            <div className="col-lg-5 mb-3">
              <label className="f-14 fw-400 mb-2">Last Name</label>
              <input
                name="lastName"
                readOnly
                className="form-control"
                placeholder="Last Name"
                type="lastName"
                style={{ backgroundColor: "#FFFFFF" }}
                disabled
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.lastName || profile.lastName}
                invalid={
                  validation.touched.lastName && validation.errors.lastName
                    ? true
                    : false
                }
              />
              {validation.touched.lastName && validation.errors.lastName ? (
                <p className="text-danger">{validation.errors.lastName}</p>
              ) : null}
            </div>
          </div>
          <div className="row justify-content-around">
            <div className="col-lg-5 mb-3">
              <label className="f-14 fw-400 mb-2">Email</label>
              <input
                name="email"
                className="form-control"
                placeholder="Email"
                type="email"
                style={{ backgroundColor: "#FFFFFF" }}
                disabled
                readOnly
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || profile.email}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <p className="text-danger">{validation.errors.email}</p>
              ) : null}
            </div>
            <div className="col-lg-5 mb-3">
              <div className="d-flex align-items-center justify-content-between">
                <label className="f-14 fw-400 mb-2">Phone No.</label>
                {/* {profile?.phoneNumberVerified !== true ? */}
                {/* <SendOtpModal phoneNo={value} Dialcode={dialcode}/> */}
                {/* : <div>Verified <RiVerifiedBadgeFill /></div> */}
                {/* } */}
                {/* <span className="t-color f-12">
                  <BsQuestionCircle className="t-gray pe-2 f-24 align-bottom" />
                  Verify phone number
                </span> */}
              </div>
              <PhoneInput
                readOnly
                enableSearch={true}
                value={value || "+" + profile.phoneNo}
                // onChange={(phoneNumber, v) => {
                //   setValue(phoneNumber);
                //   setDialcode(v);
                //   console.log( v, "vvvvvvvvvv");
                //   validation.setFieldValue("phoneNo", phoneNumber);
                //   validation.setFieldValue("dial_code", v?.dialCode);
                //   validation.setFieldValue("country_code", v?.countryCode);
                // }}
              />
            </div>
          </div>
        </div>
      </form>
      {/* <div className="row ms-5">
        <div className="col-lg-5 mb-3">
          <ChangePassword />
        </div>
      </div> */}
    </>
  );
};

export default EditProfile;
