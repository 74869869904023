import React, { useEffect, useState } from "react";
import TopBar from "../../../pages/Corporate/TopBar/Topbar";
import { MdAdd } from "react-icons/md";
import Processing from "../../../../assets/images/Processing.png";
import NotVerifyed from "../../../../assets/images/notVerifyed.png";
import Verifyed from "../../../../assets/images/Verifyed.png";
import { Link } from "react-router-dom";
import { DeleteDomain, verifyDomain } from "../../../../services/userServices";
import Pagination from "react-bootstrap/Pagination";
import { useNavigate } from "react-router-dom";
import { fireToast } from "../../../../common/Toster";

const Mydomains = () => {
  const [domain, setDomain] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [domainNames, setDomainNames] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    verifyDomains();
  }, [currentPage]);

  const verifyDomains = async () => {
    console.log(currentPage, "currentPage");
    try {
      const response = await verifyDomain(currentPage);
      if (response?.status === 200) {
        setDomain(response?.data?.items);
        setTotalItems(response?.data?.totalItems);
      }
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error.response?.data?.message);
    }
  };

  const totalPages = Math.ceil(totalItems / 10);

  const subminDomain = async (VerifyDomainName, id) => {
    navigate(`/Add%20My%20Domain/${VerifyDomainName}?step=2&id=${id}`);
  };

  const DeleteDomains = async (Id) => {
    try {
      const response = await DeleteDomain(Id);
      if (response?.status === 200) {
        fireToast("success", response?.message);
    verifyDomains();
      }
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error.response?.data?.message);
    }
  };

  return (
    <>
      <TopBar />
      <div className="AllDomains bg-light border-2 d-flex justify-content-between p-3 align-items-center border rounded ">
        <h5>All Domains</h5>
        <Link className="common-btn" to={"/Add My Domain"}>
          <MdAdd />
          Add Custom Domain
        </Link>
      </div>

      <div className="recent-data table-responsive mt-3">
        <table className="">
          <thead>
            <tr>
              <th>Domain Name</th>
              <th>Status</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {domain.map((data) => (
              <tr key={data?._id}>
                <td style={{ cursor: "pointer" }}>
                  <div className="d-flex align-items-center">
                    <span className="file-name f-14 t-color fw-500">
                      {data?.name}
                    </span>
                  </div>
                </td>
                <td className="file-name f-14 t-color fw-400">
                  <span>
                    <img
                      src={
                        data?.isVerified === true
                          ? Verifyed
                          : data?.isVerified === false
                          ? NotVerifyed
                          : Processing
                      }
                      alt=""
                      width={25}
                      className="me-2"
                    />
                    {data?.isVerified === true
                      ? " Verified"
                      : data?.isVerified === false
                      ? " Not Verified"
                      : "Processing"}
                  </span>
                </td>
                <td className="text-center">
                  {data?.isVerified === true ? (
                    <button
                      className="common-btn-outline"
                      onClick={() => DeleteDomains(data?._id)}
                    >
                      Delete
                    </button>
                  ) : data?.isVerified === false ? (<>
                    <button
                      className="common-btn-outline"
                      onClick={() => subminDomain(data?.name, data?._id)}
                    >
                      Verify
                    </button>{" "}
                       <button
                       className="common-btn-outline"
                       onClick={() => DeleteDomains(data?._id)}
                     >
                       Delete
                     </button></>
                  ) : (
                    <button className="common-btn-outline">View</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container d-flex mt-3 text-center justify-content-center">
        <Pagination>
          <Pagination.Prev
            onClick={() =>
              setCurrentPage((prevPage) =>
                prevPage > 1 ? prevPage - 1 : prevPage
              )
            }
          />
          <Pagination.Item>{currentPage}</Pagination.Item>
          <Pagination.Next
            onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </>
  );
};

export default Mydomains;
