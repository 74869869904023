import React, { useEffect, useState } from "react";
import { Sidebar } from "./Sidebar";
import { Link, Outlet, Navigate } from "react-router-dom";
import Logo from "../assets/images/u-vault.svg";
import { FaBell } from "react-icons/fa6";
import Dropdown from "react-bootstrap/Dropdown";
import { BiX } from "react-icons/bi";
import Bellicon from "../assets/images/n-bell.svg";
import { HiOutlineBars3CenterLeft } from "react-icons/hi2";
import Offcanvas from "react-bootstrap/Offcanvas";
import moment from "moment";
import axios from "axios";
import { BaseUrl } from "../common/BaseUrl";
import { fireToast } from "../common/Toster";
import NotificationDeleteModal from "../components/modals/NotificationDeleteModal";

const Layout = () => {
  const [show, setShow] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [notification, setNotification] = useState("");
  const [read, setRead] = useState("");
  const [folderSearch, setFolderSearch] = useState("");
  const [fileSearch, setFileSearch] = useState([]);
  const [canvasModalStatus, setCanvasModalStatus] = useState(false);
  const [folderData, setFolderData] = useState();
  useEffect(() => {
    getNotification();
  }, []);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const userInfo = JSON.parse(localStorage.getItem("authUser"));
  if (!userInfo?.token) {
    return <Navigate to="/" />;
  }

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    <Navigate to="/" />
    // window.location.reload();
  };

  const getNotification = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(BaseUrl + "api/user/myNotifications", {
        headers: {
          Authorization: token,
        },
      });
      setNotification(response?.data?.data?.all);
      setRead(response?.data?.data?.unread);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
      if (error?.response?.data?.error === "Unauthorized") {
        handleLogout();
      }
    }
  };

  const search = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(
        BaseUrl + `api/folder/searchfilefolder?search=${folderSearch}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setFileSearch(response?.data?.data?.files);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  const unread = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(BaseUrl + `api/user/readNotifications`, {
        headers: {
          Authorization: token,
        },
      });
      getNotification();
      handleCloseDropdown();
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  return (
    <>
      <div className="mobile-header d-lg-none d-flex align-items-center justify-content-between p-3">
        <div>
          <Link className="mobile-logo" to="/">
            <img src={Logo} alt="" />
          </Link>
          <span onClick={handleShow}>
            <HiOutlineBars3CenterLeft className="f-28 ms-3" />{" "}
          </span>
        </div>
        <div className="d-flex align-items-center">
          {/* <div className="user-icon">
            <img src={User} alt="" />
          </div> */}
          <div className="notify-bell ms-3">
            <Dropdown show={showDropdown} onToggle={setShowDropdown}>
              <Dropdown.Toggle
                className="position-relative bg-transparent border-0 w-auto after-none p-0 text-black"
                id="f-option"
              >
                <FaBell />
                <span
                  className={`notify-count position-absolute ${
                    read === 0 && "d-none"
                  }`}
                >
                  {read}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="notification-block f-14">
                <div className="d-flex align-items-center justify-content-between p-3">
                  <h5 className="notify-title">Notifications</h5>
                  <span>
                      <NotificationDeleteModal
                        getNotification={() => getNotification()}
                        handleCloseDropdown={() => {
                          handleCloseDropdown();
                        }}
                      />
                      <button
                        className="bg-transparent"
                        onClick={handleCloseDropdown}
                      >
                        <BiX />
                      </button>
                    </span>
                </div>
                <div className="notification-wrapper">
                  {notification &&
                    notification?.map((notifications) => (
                      <div className="noify-block position-relative d-flex align-items-start justify-content-between">
                        <img
                          className="n-bell position-absolute"
                          src={Bellicon}
                          alt=""
                        />
                        <div className="notify-box">
                          <p className="f-14">{notifications?.body}</p>
                          {/* <button className="border-btn">Move</button> */}
                        </div>
                        <div className="status-time f-12">
                          {moment(notifications?.updatedAt)?.format("LT")}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="text-center py-3">
                  <button className="common-btn" onClick={unread}>
                    Mark all as read
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className="d-flex layout-block">
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Sidebar handleClose={handleClose}/>
          </Offcanvas.Body>
        </Offcanvas>
        <div className="layout-sidebar d-lg-block d-none position-relative">
          <Sidebar />
        </div>
        <div className="layout-right p-lg-5 p-4">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
