import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MdAutoDelete, MdDelete } from "react-icons/md";
import { BaseUrl } from "../../common/BaseUrl";
import { delNotifications } from "../../services/userServices";

function NotificationDeleteModal({ getNotification, handleCloseDropdown }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deletenotification = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data?.token;
      const response = await delNotifications(token);
      getNotification();
      handleCloseDropdown();
      handleClose();
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  return (
    <>
      <MdDelete onClick={handleShow} />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title>Delete Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete all notifications?
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-evenly border-top-0">
          <button className="common-btn" onClick={handleClose}>
            Close
          </button>
          <Button variant="danger" className="p-2" onClick={deletenotification}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NotificationDeleteModal;
