import React, { useEffect, useState } from "react";
import CorporateTopBar from "../TopBar/Topbar";
import { fireToast } from "../../../../common/Toster";
import { BaseUrl } from "../../../../common/BaseUrl";
import axios from "axios";
import moment from "moment";
import { error } from "toastr";
import AuthModal from "../Modal/AuthModal";
import { getCorporateProfile } from "../../../../services/userServices";

const CorporateProfile = () => {
  const [profile, setProfile] = useState("");
  const [EditProfile, seteditProfile] = useState("");

  const [formData, setFormData] = useState({
    no_of_employees: "",
    // companyAddress: "",
    // industryType: "",
    // legalStructure: "",
    license: "",
    // establishmentDate: "",
    file: "file",
  });

  const getProfile = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data.token;
      const response = await axios.get(BaseUrl + "api/company/myProfile", {
        headers: {
          Authorization: token,
        },
      });
      setProfile(response?.data?.data?.user?.[0]);
      localStorage.setItem("UserInfo", JSON.stringify(response?.data?.data?.user[0]));
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const uploadImage = async (file) => {
    const localData = JSON.parse(localStorage.getItem("authUser"));
    const token = localData?.data?.token;

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.put(
        BaseUrl + "api/company/updateMyProfile",
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        fireToast("success", "Profile picture updated successfully");
        getProfile();
        window.location.reload();
      } else {
        fireToast("error", "Failed to upload profile picture");
      }
    } catch (error) {
      console.error("Upload Error:", error);
      fireToast("error", "Failed to upload profile picture");
      if (error?.response?.data?.error === "Session Expired!") {
        localStorage.clear();
        window.location.href = "/login"; 
      }
    }
  };

  const uploadProfile = async () => {
    const localData = JSON.parse(localStorage.getItem("authUser"));
    const token = localData?.data?.token;

    try {
      const response = await axios.put(
        BaseUrl + "api/company/updateMyProfile",
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        fireToast("success", "Profile updated successfully");
        getProfile();
      } else {
        fireToast("error", "error?response?.data?.error");
      }
    } catch (error) {
      console.error("Upload Error:", error);
    }
  };

  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadImage(file);
    }
  };

  return (
    <div>
      <CorporateTopBar />
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex">
            <img
              className="rounded-circle"
              src={BaseUrl + `uploads/${profile.profilePic}`}
              alt=""
              onClick={handleImageClick}
              style={{ cursor: "pointer", width: "100px", height: "100px" }}
            />
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />{" "}
            <div className="d-flex align-items-center ms-3">
              <div>
                <div>
                  <span>
                    {profile?.firstName} {profile?.lastName}
                  </span>
                </div>
                <div className="my-2">
                  <span className="user-info p-2">
                    {profile?.company_details?.[0]?.company_name}
                  </span>
                </div>
                <div>
                  <span>{profile?.email_address}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 mb-2">
            <strong>Security</strong>
            <div
              className="border rounded col-12 d-flex py-2 mt-2"
              style={{ width: "80%" }}
            >
              <div className="col-2 d-flex align-items-center">
                <AuthModal profile={profile} getProfile={() => getProfile()} />
              </div>
              <div className="col-10">
                <h5 className="font-weight-bold">Two-factor authentication</h5>
                <p className="f-12">
                  Activate an extra layer of security to your account by
                  requiring two types of identification using your mobile or
                  tablet device.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <form>
            <div className="row mb-3">
              <div className="col-lg-6">
                <label className="f-14 mb-2" htmlFor="numberOfEmployees">
                  <strong>Number of Employees</strong>
                </label>
                <select
                  id="no_of_employees"
                  className="form-control"
                  name="no_of_employees"
                  value={formData.no_of_employees}
                  onChange={handleChange}
                  style={{ padding: "11px 5px", border: "1px solid #a8a8a8" }}
                >
                  <option value="">{profile?.company_details?.[0]?.no_of_employees}</option>
                  <option value="5000+">5000+</option>
                  <option value="1000-5000">1000-5000</option>
                  <option value="500-999">500-999</option>
                  <option value="100-499">100-499</option>
                  <option value="20-99">20-99</option>
                  <option value="1-19">1-19</option>
                </select>
              </div>
              <div className="col-lg-6">
                <label className="f-14 mb-2" htmlFor="companyName">
                  <strong>Company Name</strong>
                </label>
                <input
                  id="companyName"
                  className="form-control"
                  name="companyName"
                  type="text"
                  value={profile?.company_details?.[0]?.company_name}
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-12">
                <label className="f-14 mb-2" htmlFor="industryType">
                  <strong>Industry Type</strong>
                </label>
                <input
                  id="industrytype"
                  className="form-control"
                  name="industrytype"
                  type="text"
                  value={profile?.company_details?.[0]?.industry_type}
                  readOnly
                />
                {/* <select
                  id="industryType"
                  className="form-control"
                  name="industryType"
                  value={formData.industryType}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="Information Technology (IT)">Information Technology (IT)</option>
                  <option value="Healthcare & Pharmaceuticals">Healthcare & Pharmaceuticals</option>
                  <option value="Finance & Banking">Finance & Banking</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Retail & Consumer Goods">Retail & Consumer Goods</option>
                  <option value="Energy & Utilities">Energy & Utilities</option>
                  <option value="Transportation & Logistics">Transportation & Logistics</option>
                  <option value="Media & Entertainment">Media & Entertainment</option>
                  <option value="Education & Training">Education & Training</option>
                  <option value="Hospitality & Tourism">Hospitality & Tourism</option>
                  <option value="Agriculture & Food Production">Agriculture & Food Production</option>
                  <option value="Government & Public Sector">Government & Public Sector</option>
                  <option value="Nonprofit & Social Services">Nonprofit & Social Services</option>
                  <option value="Other">Other</option>
                </select> */}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-12">
                <label className="f-14 mb-2" htmlFor="companyAddress">
                  <strong>Company's Official Address</strong>
                </label>
                <input
                  id="companyAddress"
                  className="form-control"
                  name="companyAddress"
                  type="text"
                  value={profile?.company_details?.[0]?.address}
                  // value={formData.companyAddress}
                  // onChange={handleChange}
                  // placeholder="Company Address"
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-12">
                <label className="f-14 mb-2" htmlFor="legalStructure">
                  <strong>Legal Structure</strong>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={profile?.company_details?.[0]?.legal_structure}
                  // value={formData.companyAddress}
                  // onChange={handleChange}
                  // placeholder="Company Address"
                  readOnly
                />
                {/* <select
                  id="legalStructure"
                  className="form-control"
                  name="legalStructure"
                  value={formData.legalStructure}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="corporation">Corporation</option>
                  <option value="partnership">Partnership</option>
                  <option value="sole proprietorship">Sole Proprietorship</option>
                  <option value="Other">Other</option>
                </select> */}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-6">
                <label className="f-14 mb-2" htmlFor="license">
                  <strong>Trade License No.</strong>
                </label>
                <input
                  id="license"
                  className="form-control"
                  name="license"
                  type="text"
                  value={
                    formData.license ||
                    profile?.company_details?.[0]?.trade_license_no
                  }
                  onChange={handleChange}
                  placeholder="Trade License No."
                  required
                />
              </div>
              <div className="col-lg-6">
                <label className="f-14 mb-2" htmlFor="establishmentDate">
                  <strong>Date of Establishment</strong>
                </label>
                {/* <input
                  id="establishmentDate"
                  className="form-control"
                  name="establishmentDate"
                  type="date"
                  value={formData.establishmentDate}
                  onChange={handleChange}
                  placeholder="Date of Establishment"
                  required
                /> */}
                <input
                  className="form-control"
                  type="text"
                  value={moment(
                    profile?.company_details?.[0]?.date_of_establishmen
                  ).format("L")}
                  readOnly
                />
              </div>
            </div>
            <button onClick={uploadProfile} className="common-btn w-100">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CorporateProfile;
