import "./FolderOffcanvas.css";
import { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaFolderMinus } from "react-icons/fa6";
import { MdArrowBackIosNew } from "react-icons/md";
import { Link } from "react-router-dom";
import MainIcons from "../MainIcons/MainIcons";


const FileSharedOpen = ({
  canvasModalStatus,
  setCanvasModalStatus,
  openfile,
}) => {
  const [show, setShow] = useState(false);
  console.log(openfile, "openfile");
  const handleClose = () => {
    setShow(false);
    setCanvasModalStatus(false);
  };

  useEffect(() => {
    setShow(canvasModalStatus);
  }, [canvasModalStatus]);

  useEffect(() => {}, [show]);

  const renderFileContent = () => {
    const fileExtension = openfile?.file_format;
    console.log(fileExtension, "fielesdfsdfds");
    if (!fileExtension) {
      console.error("Invalid file data");
      return null;
    }

    if ([".pdf"].includes(fileExtension)) {
      return (
        <>
          <iframe
            title="PDF Viewer"
            width="100%"
            height="900"
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(
              openfile?.fileLink
            )}&embedded=true`}
            frameBorder="0"
            allowFullScreen
          />
          {openfile?.download_retrict === true && (
            <a href={openfile?.fileLink} download="" class="downloadLinkfloat">
              Download
            </a>
          )}
        </>
      );
    } else if (
      [
        ".doc",
        ".docx",
        ".txt",
        ".csv",
        ".xml",
        ".pptx",
        ".ppt",
        ".xlsx",
        ".zip",
      ].includes(fileExtension)
    ) {
      return (
        // <iframe
        //   title="Doc Viewer"
        //   width="100%"
        //   height="900"
        //   src={files?.fileLink}
        //   frameBorder="0"
        // />
        <div class="downloadLinkDiv d-flex justify-content-center text-center">
          {/* <h2>oops!</h2>
        <h4>Looks like You’re Lost.</h4> */}
          <h4>
            We're currently unable to open this file on our platform. We're
            working on a fix but in the meantime, you can download it from the
            below.
          </h4>
          <div>
            {openfile?.download_retrict === true && (
              <a href={openfile?.fileLink} download="" class="downloadLink">
                Download
              </a>
            )}
          </div>
        </div>
      );
    } else if ([".png", ".jpg", ".gif", ".jpeg"].includes(fileExtension)) {
      return (
        <>
          <img src={openfile?.fileLink} alt="" />
          {openfile?.download_retrict === true && (
            <a
              href={openfile?.fileLink}
              download=""
              className="downloadLinkfloat"
            >
              Download
            </a>
          )}
        </>
      );
    } else if ([".mp4", ".mkv"].includes(fileExtension)) {
      return (
        <>
          <video width="100%" height="900" controls>
            <source src={openfile?.fileLink} type="video/mp4" />
          </video>
          {openfile?.download_retrict === true && (
            <a
              href={openfile?.fileLink}
              download=""
              className="downloadLinkfloat"
            >
              Download
            </a>
          )}
        </>
      );
    }
    console.error("Unsupported file type");
    return null;
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header className="px-4 py-3 d-flex align-items-center border-bottom">
        <Link
          className=" text-dark d-flex align-items-center"
          onClick={handleClose}
        >
          <MdArrowBackIosNew /> Back
        </Link>
        <Offcanvas.Title>
          <span className="folder-icon align-middle">
            <FaFolderMinus />
            {/* <img
              src={
                [".png", ".jpg", ".gif", ".jpeg"].includes(openfile?.extension)
                  ? Photo
                  : [".mp4", ".mkv"].includes(openfile?.extension)
                  ? Video
                  : [".mp3"].includes(openfile?.extension)
                  ? ""
                  : [".doc", ".docx", ".txt", ".csv", ".xml", ".pdf"].includes(
                      openfile?.extension
                    )
                  ? File
                  : Folder
              }
              alt=""
            /> */}
            <MainIcons val={openfile?.extension}/>
          </span>
          <span className="ps-3">{openfile?.file_name}</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">
        {renderFileContent()}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FileSharedOpen;
