import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../../assets/images/u-vault.svg";
import One from "../../../assets/images/one.gif";
import Two from "../../../assets/images/two.gif";
import Three from "../../../assets/images/three.gif";
import Qr from "../../../assets/images/qr.svg";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "./header/Header";
import { Footer } from "./footer/Footer";
import axios from "axios";
import { BaseUrl } from "../../../common/BaseUrl";
import { fireToast } from "../../../common/Toster";
import { max } from "moment";
// import video from "../../../../public/";

import Step01 from "../../../assets/images/step-img01.png";
import Step02 from "../../../assets/images/step-img02.png";
import Step03 from "../../../assets/images/step-img03.png";
import Arc01 from "../../../assets/images/Arc01.png";
import Arc02 from "../../../assets/images/Arc02.png";

import linkedin from "../../../assets/images/linkedin-icon.svg";
import facebook from "../../../assets/images/facebook.svg";
import twitter from "../../../assets/images/twitter.svg";
import BannerAnimation from "./video/video";

const LandingPage = () => {
  const [folderSearch, setFolderSearch] = useState("");
  const [detail, setDetail] = useState({});
  const [signupl, setSignupl] = useState("");

  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const getEnterotp = async () => {
    try {
      const response = await axios.get(
        BaseUrl + `api/folder/document_details_vaultid?vault_id=${inputValue}`
      );
      setDetail(response?.data?.data?.result);
      handleValidation();
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  const handleValidation = () => {
    if (inputValue.trim() !== "") {
      const lowercasedValue = inputValue.toLowerCase();
      const createdUrl = `doc/verify/${lowercasedValue}`;
      navigate(createdUrl);
    } else {
      console.log("Input value is empty");
    }
  };
  const [signupEmail, setSignupEmail] = useState("");

  const handleInputChange = (e) => {
    setSignupEmail(e.target.value);
  };
  return (
    <>
      <Header />

      <main className="home-content">
        {/* Banner Section Start From Here */}
        <section className="home-banner TopBottomSpace01">
          <Container fluid>
            <Row className="align-items-center">
              <Col
                xs={12}
                lg={6}
                className="d-lg-flex flex-column  offset-lg-1 ps-lg-0"
              >
                <div class="BannerCContent">
                  <h1 className="Font-Style poppins mb-4">
                    <span className="d-block t2-color">Vault and manage</span>{" "}
                    <span className="t1-color">
                      vital records on <br /> the go.
                    </span>
                  </h1>
                  <div className="u_vauilt_Img">
                    <p className="f-25 poppins">
                      U-Vault provides a dependable way to authenticate and
                      store your crucial records, ensuring they're readily
                      available at pivotal moments. Sign up for a free account
                      and start safeguarding what matters most to you.
                    </p>

                    <div className="vailidate-search d-flex align-items-center SignUp">
                      <input
                        className="form-control border-0"
                        type="email"
                        placeholder="Enter your email"
                        value={signupEmail}
                        onChange={handleInputChange}
                      />
                      <Link
                        to={`/sign-up/${signupEmail}`}
                        className="custom-btn SignUpButton"
                      >
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <div className="col-lg-5">
                <BannerAnimation />
              </div>
            </Row>
          </Container>
        </section>

        {/* What Does Start From Here */}
        <section className="what-does TopBottomSpace02">
          <Container>
            <h2 className="f-49 text-center mb-5">What U-Vault does</h2>
            <Row>
              <Col xs={12} sm={6} md={4}>
                <div className="text-center">
                  <img src={One} alt="" height={150} />
                  <h3 className="f-24 my-3">Organise</h3>
                  <p className="f-16">
                    Say goodbye to admin hassles and searching disparate sources
                    for records when you need them. With U-Vault original
                    credentials are systematically organized and at your
                    fingertips when it matters.
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4}>
                <div className="text-center">
                  <img src={Two} alt="" height={150} />
                  <h3 className="f-24 my-3">Authenticate</h3>
                  <p className="f-16">
                    U-Vault authenticates and vaults your records and
                    certifications confirming their origin and ownership. Once
                    ID-D is vaulted, your credential is a digital asset which is
                    immutable and verifiable within seconds by anyone
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4}>
                <div className="text-center">
                  <img src={Three} alt="" height={150} />
                  <h3 className="f-24 my-3">Secure</h3>
                  <p className="f-16">
                    Eliminate the risk of damage or loss to vital records.
                    U-Vault applies Bank grade encryption ensuring your
                    credentials are safeguarded, giving you confidence as you
                    build and maintain your personal brand.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="StepSection" id="about">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center mb-md-5">
                <h2 className="HowWork f-49">How it works in 3-Steps</h2>
              </div>
              <div className="com-dm-12 mt-5">
                <div className="Step_List">
                  <ul className="text-center">
                    <li className="StepOne">
                      <h2 className="StepTitle">Step 1</h2>
                      <div className="RoundCirvcle">
                        <div className="">
                          <img src={Step01} alt="" />
                          <h2>Sign Up</h2>
                          <p>
                            Sign Up with U-Vault and get your personalized
                            U-Vault ID
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="StepTwo">
                      <h2 className="StepTitle">Step 2</h2>
                      <div className="RoundCirvcle">
                        <div className="">
                          <img src={Step02} alt="" />
                          <h2>Share your U-Vault ID</h2>
                          <p>
                            Ask issuing third parties to send your credentials
                            or other vital records to your U-Vault ID from their
                            official email address.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="StepThree">
                      <h2 className="StepTitle">Step 3</h2>
                      <div className="RoundCirvcle">
                        <div className="">
                          <img src={Step03} alt="" />
                          <h2>Vault your credentials</h2>
                          <p>
                            Once deposited to your In-tray, emboss your
                            credentials with a Document ID(DID). This tells
                            people where the document originated and to whom it
                            belongs - “U”
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="qr-content TopBottomSpace03">
          <Container>
            <Row className="align-items-center">
              <div className="col-md-12 text-center mb-5">
                <h2 className="HowWork f-49 mb-5">Authenticate Credentials</h2>
              </div>
              <Col lg={6}>
                <h1 className="Font-Style mb-5 ">
                  With an ID you
                  <br /> can trust.
                </h1>
                <p className="f-49">Enter a Document ID</p>
                <div className="vailidate-search d-flex align-items-center SignUp SignUp02 mb-4">
                  <input
                    className="form-control border-0"
                    type="text"
                    placeholder="0000 - 0000 - 0000 - 0000"
                    maxlength="19"
                    value={inputValue}
                    onChange={(e) => {
                      const formattedValue = e.target.value
                        .replace(/[^\w\s-]/gi, "")
                        .replace(/(\w{4})(\w{4})(\w{4})(\w{4})/, "$1-$2-$3-$4");
                      setInputValue(formattedValue);
                    }}
                  />

                  <button
                    className="custom-btn SignUpButton"
                    onClick={getEnterotp}
                  >
                    Validate
                  </button>
                </div>
              </Col>
              <Col lg={6}>
                <div className="text-center ID_Img">
                  <img className="img-fluid" src={Qr} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          id="joincommunity"
          className="join-community d-flex align-items-center"
        >
          <Container>
            <Row>
              <Col lg={{ span: 6, offset: 6 }}>
                <h1 className="f-80 text-white mb-4">
                  Join our growing <br /> community.
                </h1>
                <div className="SocialIcon mt-5">
                  <a className="custom-btn SignUpButton" href="/sign-up">
                    Sign up
                  </a>
                  <a
                    className="custom-btn rounded-3 SignUpButton"
                    href="https://www.linkedin.com/company/u-vault"
                  >
                    <img className="img-fluid" src={linkedin} alt="" />
                  </a>
                  <a
                    className="custom-btn rounded-3 SignUpButton"
                    href="https://twitter.com/uvaultio"
                  >
                    <img className="img-fluid" src={twitter} alt="" />
                  </a>
                  <a
                    className="custom-btn rounded-3 SignUpButton"
                    href="https://www.facebook.com/uvaultio/"
                  >
                    <img className="img-fluid" src={facebook} alt="" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default LandingPage;
