import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Logo from "../../../assets/images/u-vault.svg";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../../common/BaseUrl";
import truecopylogo from "../../../assets/images/truecopylogo.jpg";
import { Footer } from "../Landing/footer/Footer";

const VerifyDocPage = () => {
  const { id } = useParams();
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const token = authUser ? authUser.token : null;
  const [detail, setDetail] = useState({});
  console.log(detail, "jjjj");
  const verifyId = id ? id.toUpperCase() : null;

  const getEnterotp = async () => {
    try {
      const response = await axios.get(
        BaseUrl + `api/folder/document_details_vaultid?vault_id=${verifyId}`
      );
      setDetail(response?.data?.data?.result);
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  useEffect(() => {
    getEnterotp();
  }, []);

  return (
    <>
      <Container fluid className="d-flex flex-column nopadding">
        <header className="site-header py-3">
          <Container>
            <Row>
              <Col xs={12}>
                <div className="d-flex align-items-center justify-content-between HeaderMenu">
                  <a href="/">
                    <img src={Logo} alt="" />
                  </a>
                  <div className="d-flex align-items-center justify-content-end HeaderMenuInner">
                    <ul className="manu-list d-flex align-items-center">
                      {!token && (
                        <>
                          <li>
                            <Link to="/login">Log in</Link>
                          </li>
                          <li>
                            <Link className="custom-btn" to="/sign-up">
                              Sign Up
                            </Link>
                          </li>
                        </>
                      )}
                    </ul>
                    {token && (
                      <Link className="custom-btn" to="/dashboard">
                        Dashboard
                      </Link>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        <div className="VaultId">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="UserImgDetail d-flex align-items-center">
                  <div className="UserImg overflow-hidden">
                    <img
                      src={BaseUrl + `uploads/${detail?.data_owner_pic}`}
                      alt=""
                    />
                  </div>
                  <div className="UserDetail">
                    This Document ID is owned {detail?.data_owner_firstname}{" "}
                    {detail?.data_owner_lastname} and was generated on{" "}
                    {detail?.date_vaulted}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="Pipe_C mb-3">
              <h3 className="fw-bold">true copy<sup>TM</sup></h3>
              <span className="PipeId">{detail?.VaultId}</span>
                <img src={truecopylogo} alt="" />
                
              </div>
            </div>
            <div className="col-md-8">
              <div className="CopyVault">
                <h2>True Copy Vaulted record</h2>
                <p className="Orign">Origin : {detail?.source} </p>
                <p>
                  The true copy badge signifies that the corresponding
                  credential was transmitted electronically by email and
                  emanated from the designated domain .
                </p>

                <p>
                  The credential was securely transmitted to the recipients
                  U-Vault account on the indicated date, ensuring the
                  confidentiality and integrity of the information during the
                  delivery process. The credential has been securely vaulted
                  within the U-Vault system on the specified date. This
                  additional layer of security guarantees that the document is
                  safely stored and easily accessible when needed. The badge
                  also attests that the credential stored in U-Vault has not
                  undergone any alterations or tampering since its transmission
                  and vaulting. It remains an accurate and unmodified
                  representation of the information received to the holders
                  U-Vault account. To ensure the complete integrity of the
                  credential and to personally verify its relevance and
                  credibility, we recommend requesting a digital copy of the
                  document.
                </p>

                <div className="TableDiv mt-5 mb-5">
                  <table>
                    <tr>
                      <td>Document ID:</td>
                      <td>{detail?.VaultId}</td>
                    </tr>
                    <tr>
                      <td>Date Vaulted:</td>
                      <td>{detail?.date_vaulted}</td>
                    </tr>
                    <tr>
                      <td>Time Vaulted:</td>
                      <td>{detail?.time_vaulted}</td>
                    </tr>
                    <tr>
                      <td>Origin:</td>
                      <td>{detail?.source}</td>
                    </tr>
                    <tr>
                      <td>Document Type: </td>
                      <td>{detail?.type}</td>
                    </tr>
                    <tr>
                      <td>File Name:</td>
                      <td>{detail?.file_name}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="ClearBoth"></div>
      <Footer />
    </>
  );
};

export default VerifyDocPage;
