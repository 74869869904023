import axios from "axios";
import { BaseUrl } from "../common/BaseUrl";

export const axiosApi = axios.create({
  baseURL: BaseUrl,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // const status = error.response?.data?.status;

    // if (status === 400) {
    //   localStorage.clear();
    //   window.location.href = "/login"; 
    // }
    const errore = error.response?.data?.error;

    if (errore === "Session Expired!") {
      localStorage.clear();
      window.location.href = "/login"; 
    }
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
  }

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}
export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export const apiHeader = () => {
  const obj = JSON.parse(localStorage.getItem("authUser"));
  if (obj && obj.token) {
    axiosApi.defaults.headers.common["Authorization"] = obj.token;
  } else {
    return {};
  }
};

export async function deleteF(url, data, config = {}) {
  return axiosApi
    .delete(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function putAsForm(url, data, config = {}) {
  return axiosApi
    .put(url, data, { ...config })
    .then((response) => response.data);
}