import React from 'react'
import Photo from "../../assets/images/photo.png";
import Video from "../../assets/images/video.png";
import Folder from "../../assets/images/folder.png";
import File from "../../assets/images/file.png";

const MainIcons = ({val}) => {
  return (
    <img
    src={
      [".png", ".jpg", ".gif", ".jpeg"].includes(
        val
      )
        ? Photo
        : [".mp4", ".mkv"].includes(val)
        ? Video
        : [".mp3"].includes(val)
        ? ""
        : [
            ".doc",
            ".docx",
            ".txt",
            ".csv",
            ".xml",
            ".pdf",
          ].includes(val)
        ? File
        : Folder
    }
    alt=""
    width={50}
  />
  )
}

export default MainIcons
