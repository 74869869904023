import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/u-vault-logo.svg";
import axios from "axios";
import { BaseUrl } from "../../common/BaseUrl";
import { fireToast } from "../../common/Toster";
import auth from "../../assets/images/signup-banner.png";
import form_img from "../../assets/images/girl-sit.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const Logincompany = () => {
  const [email, setEmail] = useState("");
  const [verifyemail, setVerifyEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dtoken = localStorage.getItem("deviceToken");
      const response = await axios.post(BaseUrl + `api/company/login`, {
        deviceToken: dtoken ?? "",
        password: password,
        email: email,
      });
      console.log("API Response:", response?.data?.data?.token);
      // fireToast("success", response?.data?.message);
      localStorage.setItem("companyFormData", JSON.stringify(response.data));
      if (response?.data?.data?.token) {
        navigate("/corporate-dashboard");
      } else {
        navigate("/two-factor-auth");
      }
      localStorage.setItem("authUser", JSON.stringify(response.data));
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  const navigate = useNavigate();

  const emailverify = async (e) => {
    try {
      const response = await axios.post(
        BaseUrl + `api/company/login_emailcheck`,
        {
          email: email,
        }
      );
      console.log("API Response:", response.data);
      setVerifyEmail(response?.data?.data?.secret_status);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  return (
    <>
      <div className=" auth-wrapper mxh-100 d-md-flex position-relative">
        <div className="row w-100">
          <div className=" auth-wrapper-inner lamp-box d-flex align-items-center justify-content-center mxh-100 col-md-6 col-sm-12">
            <Link className="site-logo" to="/">
              <img src={Logo} alt="" />
            </Link>
            <div className="AuthIms">
              <img src={auth} alt="auth" />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 d-flex p-5 rounded-4 FormDiv lamp-box">
            <form className="row FormStyle" onSubmit={handleSubmit}>
              <div className="col-12">
                <h2 className="mb-4 f-40">Login</h2>
                {/* <small>Password will be sent on your Email/Number.</small> */}
                <div className="mb-3">
                  <label className="f-14 mb-2 mt-4">
                    Email Address
                  </label>
                  <input
                    name="email"
                    className="form-control"
                    placeholder="Email/Number"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="f-14 mb-2">Password</label>
                  <div className="password-input">
                    <input
                      name="password"
                      className="form-control"
                      placeholder="Enter Password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className={`password-toggle-icon ${
                        showPassword ? "visible" : ""
                      }`}
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiOutlineEye />
                      )}
                    </span>
                  </div>
                </div>
                <div className="text-end mb-4">
                  <Link
                    className="f-14 fw-700 t-color"
                    to="/forgot-password-corporate"
                  >
                    Forgot Password?
                  </Link>
                </div>
                <div className="">
                  <button className="common-btn w-100 mb-3 text-light">
                    Submit
                  </button>
                  <p className="f-14">
                    Don’t have an account?{" "}
                    <Link to="/sign-up-company" className="fw-700 t-color">
                      Register here
                    </Link>
                  </p>
                </div>
              </div>
            </form>
            <figure className="form-right-figure  align-items-md-end d-none d-lg-flex">
              <img src={form_img} className="img-fluid" />
            </figure>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logincompany;
