import axios from "axios";
import QRCode from "qrcode.react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BaseUrl } from "../../../../common/BaseUrl";
import { fireToast } from "../../../../common/Toster";
import authenticator from "../../../../assets/images/google-authenticator.svg";
import authenticatorQR from "../../../../assets/images/StoreQR.svg";
import appSrote from "../../../../assets/images/app-store.svg";
import playStore from "../../../../assets/images/Google_Authenticator_for_Android_icon.svg.svg";
import MobileQr from "../../../../assets/images/MobileQr.png";
import { GiCheckMark } from "react-icons/gi";
import { FaCopy } from "react-icons/fa6";
import OTPInput from "react-otp-input";
import { TbReload } from "react-icons/tb";
import DeviceDetect from "../../../DeviceDecect/DeviceDetect";
import AuthModalOtp from '../../../../assets/images/Authmodalotp.png'

function AuthModal({ profile, getProfile }) {
  const [show, setShow] = useState(false);
  const [qrGenerated, setQrGenerated] = useState({});
  const [currentStep, setCurrentStep] = useState(1); // Track current step

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    qrGenerate();
  };
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const input = document.getElementById("secretInput");
    input.select();
    document.execCommand("copy");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const qrGenerate = async () => {
    try {
      if (profile.enable_auth !== true) {
        const token = JSON.parse(localStorage.getItem("authUser")).data.token;
        const response = await axios.get(BaseUrl + `api/company/generate_qr`, {
          headers: {
            Authorization: token,
          },
        });
        setQrGenerated(response?.data?.data?.secret);
      }
    } catch (error) {
      fireToast("error", error?.response?.data?.error);
    }
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1); // Move to the next step
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1); // Move to the previous step
  };
  const [email, setEmail] = useState("");

  const verifyAuth = async (e) => {
    e.preventDefault();
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data.token;
      const response = await axios.get(
        BaseUrl + `api/company/verify_qr?otp=${email}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      handleClose();
      getProfile();
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  const authDisable = async (e) => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data.token;
      const response = await axios.get(BaseUrl + `api/company/disable_Auth`, {
        headers: {
          Authorization: token,
        },
      });
      handleClose();
      getProfile();
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };
  return (
    <>
      {/* <button className="common-btn" onClick={handleShow}>
        Submit
      </button> */}
      <div class="form-check form-switch">
        {profile.enable_auth === true ? (
          <input
            className="form-check-input"
            onClick={() => {
              if (window.confirm("Are you sure you want to disable 2FA?")) {
                authDisable();
              }
            }}
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            checked={profile.enable_auth === true}
          />
        ) : (
          <input
            onClick={handleShow}
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            checked={profile.enable_auth === true}
          />
        )}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        className="authModal g_authentication"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="border-bottom-0">
          {currentStep === 1 && (
            <span className="f-40">Google Authentication
              <img
                src={authenticator}
                alt=""
                width={25}
                className="my-3"
              /></span>
          )}
          {currentStep === 2 && (
            <span className="f-22">
              Scan this QR code in the Google Authenticator app
            </span>
          )}
          {currentStep === 3 && (
            <span className="f-40">Google Authentication Code</span>
          )}
        </Modal.Header>
        <Modal.Body>
          {currentStep === 1 && (
            <div className="text-center">
              <p
                className="mb-3 f-20"
                style={{
                  textAlign: "left",
                  fontFamily: "inter",
                  color: "#0B2443",
                  opacity: "70%",
                }}
              >
                Please download and install the Google Authenticator app on your
                phone
              </p>
              <div className="d-flex mt-5">
                <div className="col-12 col-md-6 d-flex align-items-center justify-content-center f-14 py-3 px-4 rounded" style={{ backgroundColor: "#F3F3F3" }}>
                  <ol style={{ textAlign: "left", lineHeight: "23px" }}>
                    <li>1. Scan the Qr code to Download and install Google Authenticator app.</li>
                    <li>2. Open the app and click "Begin setup" or "+" sign.</li>
                    <li>3. Click "Next" and  scan the QR code or manually enter the provided code.</li>
                    <li>4. The app will automatically add the account and generate OTPs.</li>
                  </ol>
                  {/* <QRCode value="https://uvault.zip2box.com/u-vault-application" /> */}
                  {/* <DeviceDetect /> */}
                  {/* <img
                    src={authenticator}
                    alt=""
                    width={250}
                    className="my-3"
                  />*/}
                  {/* <img 
                  src={authenticatorQR}
                  alt=""
                  width={250}
                  className="my-3"
                /> */}
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                  <div>
                    <img src={MobileQr} alt="" className="img-fluids" width={340} style={{ bottom: "-33%", position: "absolute", right: "-3%" }} useMap="#workmap" />
                    <map name="workmap">
                      <area shape="rect" coords="130,240,210,275" alt="AppStore" href="https://apps.apple.com/us/app/google-authenticator/id388497605" />
                      <area shape="rect" coords="130,275,210,350" alt="PlayStore" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US" />
                    </map>
                  </div>
                  {/* <div className="login_btns">
                    <a href="https://apps.apple.com/us/app/google-authenticator/id388497605">
                      <img src={appSrote} alt="" width={250} />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US">
                      <img src={playStore} alt="" width={250} className="mt-3" />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <div className="text-center">
              <QRCode
                value={`otpauth://totp/U-Vault?secret=${qrGenerated?.base32}&hex=${qrGenerated?.hex}&ascii=${qrGenerated?.ascii}`} style={{ height: "136px", width: "136px" }}
              />
              <TbReload onClick={qrGenerate} />
              <p
                className="f-16 mt-3"
                style={{
                  textAlign: "left",
                  fontFamily: "inter",
                  color: "#0B2443",
                }}
              >
                If you are unable to scan the QR code, please enter this code
                manually into the app.
              </p>
              <div className="input-group mb-3">
                <input
                  id="secretInput"
                  type="text"
                  className="form-control"
                  value={`otpauth://totp/U-Vault?secret=${qrGenerated?.base32}&hex=${qrGenerated?.hex}&ascii=${qrGenerated?.ascii}`}
                  readOnly
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={copyToClipboard}
                >
                  {copied ? (
                    <GiCheckMark style={{ color: "#00ADEF" }} />
                  ) : (
                    <FaCopy style={{ color: "#00ADEF" }} />
                  )}
                </button>
              </div>
              <div style={{ textAlign: "left" }}>
                <span className="f-14">
                  Please save this key. This key will allow you to recover your
                  Google Authenticator in case of phone loss.
                </span>
              </div>
            </div>
          )}
          {currentStep === 3 && (
            <div className="text-center">
              <p
                className="mb-3 f-20"
                style={{
                  textAlign: "left",
                  fontWeight: "400",
                  fontFamily: "inter",
                  color: "#0B2443",
                }}
              >
                Enter the OTP received in your Google Authenticator app
              </p>
              <div className="px-5 auth-passwords" style={{ marginTop: "33px" }}>
                <OTPInput
                  value={email}
                  onChange={(email) => {
                    setEmail(email);
                    // if (email.length === 6) {
                    //   verifyAuth();
                    // }
                  }}
                  numInputs={6}
                  renderInput={(props, index) => (
                    <input {...props} key={index} />
                  )}
                />
              </div>
            </div>
          )}
          {currentStep === 4 && <p>444</p>}
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-top-0">
          {currentStep > 1 && (
            <button className="common-btn w-25" onClick={handlePrevious}>
              Previous
            </button>
          )}
          {currentStep < 3 && (
            <button className="common-btn w-25" onClick={handleNext}>
              Next
            </button>
          )}
          {currentStep === 3 && (
            <button onClick={verifyAuth} className="common-btn w-25">
              Submit
            </button>
          )}
          {currentStep === 4 && (
            <button onClick={verifyAuth} className="common-btn w-25">
              Ok
            </button>
          )} <br />
          {currentStep === 3 && (
            <div className="pt-4 text-center"><img src={AuthModalOtp} alt="" className=" w-75" width={304} /></div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AuthModal;
