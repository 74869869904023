import React, { useEffect, useState } from "react";
import DomainCompleted from "../../../../assets/images/DomainCompleted.png";
import UnCompleted from "../../../../assets/images/DomainCompletedError.png";
import { PiDownloadSimpleFill } from "react-icons/pi";
import { domainSubmit, getDomainById, verifyRecord } from "../../../../services/userServices";
import { fireToast } from "../../../../common/Toster";
import "../../../topbar/TopBar";

import { FaBell } from "react-icons/fa6";
import Dropdown from "react-bootstrap/Dropdown";
import { BiX } from "react-icons/bi";
import Bellicon from "../../../../assets/images/n-bell.svg";
import axios from "axios";
import { BaseUrl } from "../../../../common/BaseUrl";
import moment from "moment";
import { useLocation } from "react-router-dom";
import NotificationDeleteModal from "../../../modals/NotificationDeleteModal";
import { useSearchParams } from "react-router-dom";

const AddMyDomain = () => {
  const [searchParams] = useSearchParams();
  const step = Number(searchParams.get('step'));
  const id = searchParams.get('id');
  const [activeStep, setActiveStep] = useState(step || 1);
  const [domainName, setDomainName] = useState();
  const [domainNames, setDomainNames] = useState();
  const [domainVerify, setDomainVerify] = useState();
  const [stepFirst, setStepFirst] = useState(false);
  const [stepSecound, setStepSecound] = useState(false);
  const [succesedImg, setsuccesedImg] = useState(UnCompleted);
  const [showDropdown, setShowDropdown] = useState(false);
  const [notification, setNotification] = useState("");
  const [read, setRead] = useState("");
  const { pathname } = useLocation();
  const pathParts = pathname.split("/");
  const lastPart = pathParts[2];

  const subminDomain = async () => {
    let UserId = JSON.parse(localStorage.getItem("UserInfo"));
    const Id = UserId?.company_details[0]?._id;
    try {
      const response = await domainSubmit({
        name: domainName,
        company_id: Id,
      });
      setDomainNames(response?.data);
      setActiveStep(2);
      setStepFirst(true);
    } catch (error) {
      fireToast("error", error?.response?.data?.message);
      console.error("API Error:", error);
    }
  };

  const verifyRecords = async () => {
    const Id = domainNames?._id;
    try {
      const response = await verifyRecord(Id);
      setDomainVerify(response?.data);
      setsuccesedImg(true);
      setActiveStep(3);
      setStepSecound(true);
    } catch (error) {
      fireToast("error", error.response?.data?.message);
      setsuccesedImg(false);
      setStepSecound(true);
      setActiveStep(3);
      console.error("API Error:", error);
    }
  };

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  const getNotification = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data?.token;
      const response = await axios.get(
        BaseUrl + "api/company/myNotifications",
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setNotification(response?.data?.data?.all);
      setRead(response?.data?.data?.unread);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      if (error?.response?.data?.error === "Session Expired!") {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  };

  const unread = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data?.token;
      const response = await axios.get(
        BaseUrl + `api/company/readNotifications`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      getNotification();
      handleCloseDropdown();
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const getDomainDetails = async (id) => {
    const { data: { items: [domainDetails] } } =  await getDomainById(id);
    setDomainNames(() => { return { ...domainDetails } });
  }



  useEffect(() => {
    getNotification(unread);
    setDomainName(lastPart);
    if (id) {
      getDomainDetails(id);
    }
    // search();
  }, []);
  return (
    <>
      <>
        <div className="page-top-search mb-4">
          <div className="row">
            <div className="col-md-6 mb-md-0 mb-4">
              <h3 className="f-28 text-capitalize">My Domain</h3>
            </div>
            <div className="col-md-6 d-md-flex justify-content-end align-items-center">
              <div className="notify-bell ms-4 d-lg-block d-none">
                <Dropdown show={showDropdown} onToggle={setShowDropdown}>
                  <Dropdown.Toggle
                    className="position-relative bg-transparent border-0 w-auto after-none p-0 text-black"
                    id="f-option"
                  >
                    <FaBell />
                    <span
                      className={`notify-count position-absolute ${
                        read === 0 && "d-none"
                      }`}
                    >
                      {read}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="notification-block f-14">
                    <div className="d-flex align-items-center justify-content-between p-3">
                      <h5 className="notify-title">Notifications</h5>{" "}
                      <span>
                        <NotificationDeleteModal
                          getNotification={() => getNotification()}
                          handleCloseDropdown={() => {
                            handleCloseDropdown();
                          }}
                        />
                        <button
                          className="bg-transparent"
                          onClick={handleCloseDropdown}
                        >
                          <BiX />
                        </button>
                      </span>
                    </div>
                    <div className="notification-wrapper">
                      {notification &&
                        notification?.map((notifications) => (
                          <div
                            className={`noify-block position-relative d-flex align-items-start justify-content-between ${
                              notifications?.isSeen !== true &&
                              "unreadnotifications"
                            }`}
                          >
                            <img
                              className="n-bell position-absolute"
                              src={Bellicon}
                              alt=""
                            />
                            <div className="notify-box">
                              <p className="f-14">{notifications?.body}</p>
                            </div>
                            <div className="status-time f-10">
                              {moment(notifications?.createdAt).format("lll")}
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="text-center py-3">
                      <button className="common-btn" onClick={unread}>
                        Mark all as read
                      </button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </>
      <div className="bg-light d-flex justify-content-between align-items-center border p-2 rounded col-12">
        <div className="col-4" style={{ borderRight: "1px solid #dee2e6" }}>
          <div className="text-left my-5 mx-5">
            <span className="fw-600 fs-4">
              Claim Ownership <br />
              of your Domain
            </span>
          </div>
          <div className="row my-5 mx-lg-5">
            <div onClick={() => setActiveStep(1)}>
              <span
                className={activeStep >= 1 ? "completeStep" : "UncompleteStep"}
              >
                1
              </span>
              <strong class=""> Add Domain</strong>
            </div>
            <div
              className={
                activeStep >= 1 ? "completeStepBorder" : "UncompleteStepBorder"
              }
            >
              <br />
              <br />
            </div>
            <div onClick={stepFirst === true ? () => setActiveStep(2) : null}>
              <span
                className={activeStep >= 2 ? "completeStep" : "UncompleteStep"}
              >
                2
              </span>
              <strong class=""> Add DNS records</strong>
            </div>
            <div
              className={
                activeStep >= 2 ? "completeStepBorder" : "UncompleteStepBorder"
              }
            >
              <br />
              <br />
            </div>
            <div onClick={stepSecound === true ? () => setActiveStep(3) : null}>
              <span
                className={activeStep >= 3 ? "completeStep" : "UncompleteStep"}
              >
                3
              </span>
              <strong class=""> Verification Status</strong>
            </div>
          </div>
        </div>
        <div className="col-8 ps-4">
          {activeStep === 1 && (
            <div className="DomainStep1">
              <span className="fw-600 fs-4 mb-5">
                Type in your Domain address
              </span>
              <p>Domain Name</p> <br />
              <input
                type="text"
                className="w-50 mb-4 py-1 px-2"
                value={domainName}
                placeholder="Example.com"
                onChange={(e) => setDomainName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    subminDomain();
                  }
                }}
              />
              <br />
              <button onClick={subminDomain} className="common-btn px-3">
                Next
              </button>
            </div>
          )}
          {activeStep === 2 && (
            <div className="DomainStep2">
              <span className="fw-600 fs-4 mb-5">
                Set up DNS for your domain
              </span>
              <br />
              <br />
            
                <span>
                 Record Type: {' '}
                <strong>{domainNames?.records[0]?.type}</strong>
                </span>
              <br />
              <span>
                Host: <strong> {domainNames?.name}</strong>
              </span>
              {/* <br />
              <br /> */}
              {/* <strong>
                <span>
                  A RECORD (@)<sup className="text-danger fs-6">*</sup>
                </span>
              </strong> */}

              <br />
              <span>
                Data Value: <strong>{domainNames?.records[0]?.value}</strong>
              </span>
              <br />
              <br />
              {/* <button onClick={() => setActiveStep(3),subminDomain} className="common-btn"> */}
              <button onClick={verifyRecords} className="common-btn px-3">
                I am Done
              </button>
              <br />
              <div className="mt-3" style={{fontSize:"13px"}}>
                <small>
                  <sup className="text-danger" style={{fontSize:"12px"}}>*</sup>To assert ownership
                  of the domain, kindly incorporate<br/><></><strong>TXT</strong> record
                  within your domain's DNS settings.
                </small>
                <br />
                <br />
                <small>
                  For detailed guidance on adding <strong>TXT</strong> record to your
                  domain, please <br/>download the PDF provided below. It contains
                  step-by-step guidance tailored <br/>to assist you in adding records
                  to your domain.
                </small>
                <br />
                <a
                  type="button"
                  target="_blank"
                  href="https://trailant.s3.us-west-2.amazonaws.com/Verifying++domain_U-VAULT.pdf"
                  className="btn btn-outline-secondary mt-3"
                >
                  <PiDownloadSimpleFill /> Download
                </a>
              </div>
            </div>
          )}
          {activeStep === 3 && (
            <div className="DomainStep3 text-center">
              <img
                src={succesedImg === true ? DomainCompleted : UnCompleted}
                alt=""
                width={400}
              />
              <br />
              {succesedImg === true ? (
                <div className="mt-4">
                  {" "}
                  <span className="fw-600 fs-6 mb-5">
                    Domain Verification Completed!
                  </span>
                  <p>Your domain has been successfully verified.</p>
                </div>
              ) : (
                <div className="mt-4">
                  <span className="fw-600 fs-6 mb-5">
                    Domain Verification Not Completed!
                  </span>
                  <p>Your domain has not been successfully verified.</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddMyDomain;
