import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { forgotPassword } from '../../services/userServices';
import { fireToast } from '../../common/Toster';
import auth from '../../assets/images/auth.webp';
import Logo from '../../assets/images/u-vault-logo-white.svg';

const ForgotPassword = () => {
  //meta title
  document.title = 'Forgot Password';

  const navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid User').required('This Field is Required'),
    }),
    onSubmit: async (values) => {
      try {
        setButtonDisabled(true); // Disable the button
        const loginResponse = await forgotPassword(values);
        if (loginResponse.status === 200) {
          fireToast('success', loginResponse.message);
          localStorage.setItem('contact-info', values.email);
          navigate('/verify-otp');
        } else {
          fireToast('error', loginResponse.error);
          setButtonDisabled(false); // Enable the button in case of an error
        }
      } catch (err) {
        console.log(err);
        fireToast('error', err.response.data.error);
        setButtonDisabled(false); // Enable the button in case of an error
      }
    },
  });

  return (
    <>
      <div className="auth-wrapper mxh-100 d-md-flex position-relative">
        <div className="row w-100">
          {/* Left Side Part */}
          <div className=" auth-wrapper user-login auth-wrapper-inner d-flex align-items-center justify-content-center mxh-100 col-md-6 col-sm-12">
            <Link className="site-logo" to="/">
              <img src={Logo} alt="" />
            </Link>
            <div className="AuthIms">
              <img src={auth} alt="auth" />
            </div>
            <div className="row w-100">
              <div className="Welcome">
                <h1 className="text-white f-48">Let's Get You Back In</h1>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 d-flex bg-white p-5 rounded-4 FormDiv">
            <form
              className="row FormStyle ForGot"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="col-12">
                <Link className="logo d-none d-block" to="/">
                  <img src={Logo} alt="" />
                </Link>
              </div>
              <div className="col-12">
                <h2 className="mb-2 f-40">Forgot password</h2>
                <p className="f-16 mb-lg-4 mb-0">Add Email ID to reset password</p>
                <div className="mb-4">
                  <label className="f-14 mb-2">Email ID</label>
                  <input
                    name="email"
                    className="form-control"
                    placeholder="Enter email"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ''}
                    disabled={isButtonDisabled} // Disable input field if the button is disabled
                    invalid={validation.touched.email && validation.errors.email ? true : false}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <p className="text-danger">{validation.errors.email}</p>
                  ) : null}
                </div>
                <div className="">
                  <button className="common-btn w-100 mb-3" disabled={isButtonDisabled}>
                    {isButtonDisabled ? 'Submitting...' : 'Submit'}
                  </button>
                  <p className="f-14">
                    Already have an account?{' '}
                    <Link to="/login" className="fw-700 t-color">
                      Log in here
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
