import "./Modals.css";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getCreateFolder } from "../../services/userServices";
import { fireToast } from "../../common/Toster";

const CreateFolder = ({ createFolder, setCreateFolder, createFolders }) => {
  const [show, setShow] = useState(false);
  const [folderName, setFolderName] = useState("");
  const handleClose = () => {
    setFolderName(""); 
    setShow(false);
    setCreateFolder(false);
  };

  useEffect(() => {
    setShow(createFolder);
  }, [createFolder]);

  const newFolder =  createFolders === undefined  ? folderName : createFolders + "/" + folderName
  console.log(newFolder,"newFoldernewFolder");
  const folderCreate = async () => {
    try {
      const resp = await getCreateFolder({
        folder_name: newFolder,
      });
      if (resp.status === 200) {
        handleClose(); 
        fireToast("success", resp.message);
        window.location.reload();
      } else {
        fireToast("error", resp.error);
      }
    } catch (error) {
      console.log(error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="border-0">
        <div>
          <Modal.Title className="f-20 fw-500">Create new folder</Modal.Title>
          <p className="f-14 text-black">
            Unique name is required to create a new folder
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form
          className="my-4"
          onSubmit={(e) => {
            e.preventDefault();
            folderCreate();
          }}
        >
          <label className="mb-3">Folder name</label>
          <input
            className="form-control"
            type="text"
            onChange={(e) => setFolderName(e.target.value)}
          />
          <div className="d-flex align-items-center justify-content-end mt-5">
            <button
              className="border-btn me-3"
              type="button" // Set the type to "button" to prevent form submission
              onClick={handleClose}
            >
              Cancel
            </button>
            <button className="common-btn" type="submit">
              Create
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateFolder;
