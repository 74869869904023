import React, { useState } from "react";
import FolderCard from "../folder-card/FolderCard";
import TopBar from "../topbar/TopBar";
import CreateFolder from "../modals/CreateFolder";
import RenameFolder from "../modals/RenameFolder";
import RecentFiles from "../recent-files/RecentFiles";

const Dashboard = () => {
  document.title = "U-Vault";
  const [renameModal, setRenameModal] = useState(false);
  const [createFolder, setCreateFolder] = useState(false);

  return (
    <>
      <RenameFolder renameModal={renameModal} setRenameModal={setRenameModal} />
      <CreateFolder
        createFolder={createFolder}
        setCreateFolder={setCreateFolder}
      />
      <TopBar />
      <div className="d-flex align-items-center justify-content-between mb-5">
        <h4 className="f-24 d-flex align-items-center">Folders</h4>
        <button className="common-btn" onClick={() => setCreateFolder(true)}>
          Create Folder
        </button>
      </div>
      <div className="row mb-lg-5 mb-4">
        <FolderCard />
      </div>
      <div className="d-flex align-items-center justify-content-between mb-5">
        <h4 className="f-24">Recent Documents</h4>
      </div>
      <RecentFiles />
    </>
  );
};

export default Dashboard;
