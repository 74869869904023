import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/u-vault-logo-white.svg";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { resetPassword } from "../../services/userServices";
import { fireToast } from "../../common/Toster";
// Password Eye
import Icon from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";

import auth from "../../assets/images/auth.webp";
import PasswordChecklist from "react-password-checklist";

const ResetPassword = () => {
  //meta title
  document.title = "ResetPassword";
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      new_password: "",
      confirm: "",
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        // .email("Invalid User")
        .required("This Field is Required"),
      new_password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol"),
      confirm: Yup.string().oneOf(
        [Yup.ref("new_password"), null],
        'Must match "password" field value'
      ),
    }),
    onSubmit: async (values) => {
      const obj = localStorage.getItem("contact-info");
      console.log(obj, "testing");
      try {
        // changeApiStatus(true, "");
        const loginResponse = await resetPassword(values.new_password, obj);
        console.log(loginResponse, "loginResponse");
        if (loginResponse.status === 200) {
          fireToast("success", loginResponse.message);
          navigate("/login");
        } else {
          // changeApiStatus(false, "");

          throw new Error(loginResponse.error);
        }
      } catch (err) {
        // changeApiStatus(false, "");
        fireToast("error", err.response.data.error);
      }
    },
  });
  return (
    <>
      <div className="auth-wrapper mxh-100 d-md-flex position-relative">
        <div className="row w-100">
          {/* Left Side Part */}
          <div className=" auth-wrapper user-login auth-wrapper-inner d-flex align-items-center justify-content-center mxh-100 col-md-6 col-sm-12">
            <Link className="site-logo" to="/">
              <img src={Logo} alt="" />
            </Link>
            <div className="AuthIms">
              <img src={auth} alt="auth" />
            </div>
            <div className="row w-100">
              <div className="Welcome">
                <h1 className="text-white f-48">Unlock a Fresh Start</h1>
                <p className="text-white f-32">Reset Your Password Now!</p>
              </div>
            </div>
            {/* <img src={auth} alt="" /> */}
          </div>
          {/* <div className=" auth-wrapper d-flex align-items-center justify-content-center mxh-100 col-md-6"> */}
            {/* <Link className="site-logo" to="/">
              <img src={Logo} alt="" />
            </Link>
            <div className="AuthIms">
              <img src={auth} alt="auth" />
            </div>
            <div className="row w-100">
              <div className="Welcome">
                <h1 className="text-white f-48">Unlock a Fresh Start</h1>
                <p className="text-white f-32">Reset Your Password Now!</p>
              </div>
            </div> */}
            {/* <img src={auth} alt="" /> */}
            
          {/* </div> */}
          {/* Left Side Part Ends */}

          <div className="auth-right d-flex align-items-center justify-content-center mxh-100 col-md-6 bg-white">
            <div className="row w-100">
              <div className="col-md-8 offset-md-2 col-xxl-6 offset-xxl-3">
                <form
                  className="row FormStyle"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  {/* <div className="col-12 mt-lg-5 mb-4">
                                    <Link className='logo d-lg-none d-block' to="/login">
                                        <img src={Logo} alt="" />
                                    </Link>
                                </div> */}
                  <div className="col-12">
                    <h2 className="mb-2 f-40">Reset password</h2>
                    <p className="f-16 mb-5">Create a new password</p>
                    <div className="mb-4">
                      <label className="f-14 mb-2">New Password</label>
                      <div className="pass-eye position-relative">
                        <input
                          name="new_password"
                          className="form-control"
                          placeholder="Enter password"
                          type={type}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.new_password || ""}
                          invalid={
                            validation.touched.new_password &&
                            validation.errors.new_password
                              ? true
                              : false
                          }
                        />
                        <span
                          className="position-absolute"
                          onClick={handleToggle}
                        >
                          <Icon className="mr-10" icon={icon} size={18} />
                        </span>
                      </div>
                      {validation.values.new_password ? (
                        <div className=" commonIcon mt-2 " id="passwordCheck">
                          <PasswordChecklist
                            className="text-xs d-flex  w-100  "
                            iconSize={8}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                            rules={[
                              "capital",
                              "specialChar",
                              "minLength",
                              "lowercase",
                              "number",
                            ]}
                            minLength={8}
                            value={validation.values.new_password}
                            messages={{
                              capital: "At least one uppercase letter (A-Z)",
                              specialChar:
                                "At least one special character (e.g., !, @, #, $, etc.)",
                              minLength: " At least 8 digit",
                              lowercase: "At least one lowercase letter (a-z)",
                              number: " At least one number (0-9)",
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <label className="f-14 mb-2">Confirm Password</label>
                      <input
                        name="confirm"
                        className="form-control"
                        placeholder="Enter confirm"
                        type="confirm"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.confirm || ""}
                        invalid={
                          validation.touched.confirm &&
                          validation.errors.confirm
                            ? true
                            : false
                        }
                      />
                      {validation.touched.confirm &&
                      validation.errors.confirm ? (
                        <p className="text-danger">
                          {validation.errors.confirm}
                        </p>
                      ) : null}
                    </div>
                    <div className="">
                      <button className="common-btn w-100 mb-3">
                        Reset Password
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
