import axios from "axios";
import "./Modals.css";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { fireToast } from "../../common/Toster";
import { BaseUrl } from "../../common/BaseUrl";

const RenameFolder = ({ renameModal, setRenameModal, folderData }) => {
  const [show, setShow] = useState(false);
  const [newFolderName, setNewFolderName] = useState(""); // Added state for the new folder name

  const handleClose = () => {
    setShow(false);
    setRenameModal(false);
  };
console.log(folderData,"rename folder");
  useEffect(() => {
    setShow(renameModal);
  }, [renameModal]);

  const fetchRenameFolder = async () => {
    try {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.patch(
        BaseUrl + `api/folder/renameFolder/${folderData?._id}`,
        {
          newFolderPath:newFolderName
        },
        {
          headers: { Authorization: token },
        }
      );
      if (response) {
        fireToast(
          "success",
          response?.message || "Folder Renamed Successfully"
        );
        window.location.reload();
        setShow(false);
      }
    } catch (error) {
      console.error("API Error:", error);
      fireToast(
        "error",
        error?.response?.data?.error || "Folder not found to rename"
      );
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      fetchRenameFolder();
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="border-0">
          <div>
            <Modal.Title className="f-20 fw-500">Rename folder</Modal.Title>
            <p className="f-14 text-black">Enter a new name for this folder.</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form className="my-4" action="">
            <label className="mb-3">Folder name</label>
            <input
              className="form-control"
              type="text"
              name=""
              id=""
              value={newFolderName} // Set the input value from state
              onChange={(e) => setNewFolderName(e.target.value)} // Update the state on input change
              onKeyPress={handleKeyPress}
            />
          </form>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button className="border-btn" onClick={handleClose}>
            Cancel
          </button>
          <button className="common-btn" onClick={fetchRenameFolder}>
            Rename
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RenameFolder;
