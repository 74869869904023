import React, { useEffect, useState } from "react";
import { MdFilterList } from "react-icons/md";
import axios from "axios";
import { fireToast } from "../../../../common/Toster";
import { BaseUrl } from "../../../../common/BaseUrl";
import moment from "moment";
import Pagination from "react-bootstrap/Pagination";
import { useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { FaBell } from "react-icons/fa6";
import NotificationDeleteModal from "../../../modals/NotificationDeleteModal";
import { BiX } from "react-icons/bi";
import Bellicon from "../../../../assets/images/n-bell.svg";
import {
  myNotifications,
  readNotifications,
} from "../../../../services/userServices";

const CorporateDashboard = () => {
  const [dashboard, setDashboard] = useState([]);
  const [domaindashboard, setDomainDashboard] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("0");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const totalPage = Math.ceil(totalPages / 10);
  const [showDropdown, setShowDropdown] = useState(false);
  const [notification, setNotification] = useState("");
  const [read, setRead] = useState("");
  const [folderSearch, setFolderSearch] = useState("");
  const { pathname } = useLocation();
  const pathParts = pathname.split("/");
  const lastPart = pathParts[1];

  const handleDashboard = async (pageNumber) => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data?.token;
      const response = await axios.get(
        BaseUrl +
          `api/files/dashboard?pageNumber=${pageNumber}&pageLimit=10&days_specification=${selectedDuration}&domain=${selectedDomain}&start_date=${startDate}&end_date=${endDate}&search=${folderSearch}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response) {
        setDashboard(response?.data?.data?.data);
        setTotalPages(response?.data?.data?.total);
        setDomainDashboard(response?.data?.data?.domains);
      }
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  useEffect(() => {
    handleDashboard(currentPage);
  }, [
    currentPage,
    selectedDuration,
    selectedDomain,
    startDate,
    endDate,
    folderSearch,
  ]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    getNotification(unread);
  }, []);

  const getNotification = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data?.token;
      const response = await myNotifications(token);
      console.log(response, "myNotifications");
      setNotification(response?.data?.all);
      setRead(response?.data?.unread);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const unread = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data?.token;
      const response = await readNotifications(token);
      getNotification();
      handleCloseDropdown();
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  return (
    <div>
      <div className="page-top-search mb-lg-5 mb-4">
        <div className="row">
          <div className="col-md-6 mb-md-0 mb-4">
            <h3 className="f-28 text-capitalize">Dashboard</h3>
          </div>
          <div className="col-md-6 d-md-flex justify-content-end align-items-center">
            {/* <div className="search-block position-relative">
              <div className="d-flex align-items-center">
                <input
                  className="form-control ms-auto"
                  type="text"
                  value={folderSearch}
                  onChange={(e) => setFolderSearch(e.target.value)}
                  placeholder="Search domain and receiver name"
                />
              </div>
            </div> */}
            <div className="search-block position-relative">
              <div className="d-flex align-items-center">
                <input
                  className="form-control ms-auto"
                  type="text"
                  value={folderSearch}
                  onChange={(e) => setFolderSearch(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  placeholder="Search domain and receiver name"
                />
              </div>
            </div>

            <div className="notify-bell ms-4 d-lg-block d-none">
              <Dropdown show={showDropdown} onToggle={setShowDropdown}>
                <Dropdown.Toggle
                  className="position-relative bg-transparent border-0 w-auto after-none p-0 text-black"
                  id="f-option"
                >
                  <FaBell />
                  <span
                    className={`notify-count position-absolute ${
                      read === 0 && "d-none"
                    }`}
                  >
                    {read}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="notification-block f-14">
                  <div className="d-flex align-items-center justify-content-between p-3">
                    <h5 className="notify-title">Notifications</h5>{" "}
                    <span>
                      <NotificationDeleteModal
                        getNotification={() => getNotification()}
                        handleCloseDropdown={() => {
                          handleCloseDropdown();
                        }}
                      />
                      <button
                        className="bg-transparent"
                        onClick={handleCloseDropdown}
                      >
                        <BiX />
                      </button>
                    </span>
                  </div>
                  <div className="notification-wrapper">
                    {notification &&
                      notification?.map((notifications) => (
                        <div
                          className={`noify-block position-relative d-flex align-items-start justify-content-between ${
                            notifications?.isSeen !== true &&
                            "unreadnotifications"
                          }`}
                        >
                          <img
                            className="n-bell position-absolute"
                            src={Bellicon}
                            alt=""
                          />
                          <div className="notify-box">
                            <p className="f-14">{notifications?.body}</p>
                          </div>
                          <div className="status-time f-10">
                            {moment(notifications?.createdAt).format("lll")}
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="text-center py-3">
                    <button className="common-btn" onClick={unread}>
                      Mark all as read
                    </button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex mb-3 justify-content-around align-items-center">
        <div className="w-20 mx-1">
          <span className="w-100">
            <MdFilterList className="me-2" />
            Filter By
          </span>
        </div>
        <div className="w-20 mx-1">
          <select
            style={{ backgroundColor: "white", border: "1px solid black" }}
            id="Domain"
            className="w-100 py-2 px-3 rounded"
            value={selectedDomain}
            onChange={(e) => setSelectedDomain(e.target.value)}
          >
            <option value="">Domain</option>
            {domaindashboard.map((val) => (
              <option key={val?._id} value={val?.name}>
                {val?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-20 mx-1">
          <select
            id="dropdown1"
            style={{ backgroundColor: "white", border: "1px solid black" }}
            className="w-100 py-2 px-3 rounded"
            value={selectedDuration}
            onChange={(e) => setSelectedDuration(e.target.value)}
          >
            <option value="0">All</option>
            <option value="1">Last 7 Days</option>
            <option value="2">Last 30 Days</option>
            <option value="3">Last 3 Month</option>
            <option value="4">Last 6 Month</option>
            <option value="4">Last 1 Year</option>
          </select>
        </div>
        <div className="w-20 mx-1">
          <input
            type="date"
            id="dateFrom"
            className="w-100 py-2 px-3 rounded"
            style={{ border: "1px solid" }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="w-20 mx-1">
          <input
            type="date"
            id="dateTo"
            className="w-100 py-2 px-3 rounded"
            style={{ border: "1px solid" }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      <div className="recent-data table-responsive">
        <table className="">
          <thead>
            <tr>
              <th>Receiver Name</th>
              <th> U-Vault ID</th>
              <th>Date </th>
              <th>Document ID</th>
              <th>Domain ID</th>
              <th>Domain Name</th>
            </tr>
          </thead>
          <tbody>
            {dashboard?.map((val) => (
              <tr key={val.id}>
                <td style={{ cursor: "pointer" }}>
                  <div className="d-flex align-items-center">
                    <span className="file-name f-14 t-color fw-500">
                      {val?.firstname} {val?.lastname}
                    </span>
                  </div>
                </td>
                <td>{val?.uservaultid}</td>
                <td>{moment(val?.file_details?.createdAt).format("ll")}</td>
                <td>{val?.file_details?.metadata?.VaultId}</td>
                <td>{val?.domain_id}</td>
                <td>{val?.domain}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination className={`justify-content-center mt-4`}>
        <Pagination.First onClick={() => handlePageChange(1)} />
        {currentPage > 1 && (
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
        )}
        {Array.from(
          { length: Math.ceil(totalPages / 10) },
          (_, i) => i + 1
        ).map((page) => (
          <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Pagination.Item>
        ))}
        {currentPage < Math.ceil(totalPages / 10) && (
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
        )}
        <Pagination.Last
          onClick={() => handlePageChange(Math.ceil(totalPages / 10))}
        />
      </Pagination>
    </div>
  );
};

export default CorporateDashboard;
