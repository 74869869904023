import React, { useEffect, useState } from "react";
import { Link, NavLink, Route, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/u-vault.svg";
import User from "../../assets/images/u.svg";
import Cloud from "../../assets/images/cloud.svg";
import { FaCopy, FaRegFolderClosed } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa6";
import { FaWhmcs } from "react-icons/fa6";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { getMyFolder, logOut } from "../../services/userServices";
import ProgressBar from "react-bootstrap/ProgressBar";
import { GiCheckMark } from "react-icons/gi";
import { fireToast } from "../../common/Toster";
import { BaseUrl } from "../../common/BaseUrl";
import axios from "axios";
import { RiContactsLine } from "react-icons/ri";
import { MdOutlineContacts } from "react-icons/md";
import { TfiWorld } from "react-icons/tfi";


// const routes = [
//   const role = JSON.parse(localStorage.getItem("authUser")).data.role;
//   {
//     path: "/corporate-dashboard",
//     name: "Dashboard",
//     icon: <FaRegFolderClosed />,
//   },
//   {role === "Admin" && (
//   {
//     path: "/corporate-user",
//     name: "User Management",
//     icon: <FaRegUser />,
//   })},
//   {
//     path: "/corporate-edit-profile",
//     name: "Edit Profile",
//     icon: <MdOutlineContacts />,
//   },
//   {
//     path: "/corporate-settings",
//     name: "Settings",
//     icon: <FaWhmcs />,
//   },
//   {
//     path: "/Help-support",
//     name: "Help & Support",
//     icon: <FaRegCircleQuestion />,
//   },
// ];

const role = JSON.parse(localStorage.getItem("authUser"))?.data?.role;
const routes = [
  {
    path: "/corporate-dashboard",
    name: "Dashboard",
    icon: <FaRegFolderClosed />,
  },
  role === "Admin" && {
    path: "/corporate-user",
    name: "User Management",
    icon: <FaRegUser />,
  },
  {
    path: "/corporate-edit-profile",
    name: "Edit Profile",
    icon: <MdOutlineContacts />,
  },
  {
    path: "/my domain",
    name: "My Domain",
    icon: <TfiWorld />,
  },
  {
    path: "/corporate-settings",
    name: "Settings",
    icon: <FaWhmcs />,
  },
  {
    path: "/Help-support",
    name: "Help & Support",
    icon: <FaRegCircleQuestion />,
  },
].filter(Boolean); 

export default routes;


export const CorporateSidebar = ({ handleClose }) => {
  const [profile, setProfile] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const Navigate = useNavigate;

  const handleCopy = ({ text }) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    fireToast("success", "Copied");
    // Reset the "Copied" state after a short delay
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const getProfile = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data.token;
      const response = await axios.get(BaseUrl + "api/company/myProfile", {
        headers: {
          Authorization: token,
        },
      });
      setProfile(response?.data?.data?.user[0]);
      localStorage.setItem("UserInfo", JSON.stringify(response?.data?.data?.user[0]));
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
      if (error?.response?.data?.error === "Unauthorized") {
        handleLogout();
      }
    }
  };
  const navigate = useNavigate();

  const handleLogout = async() => {
    try {
      const localId = JSON.parse(localStorage.getItem("authUser"));
      const id = localId?.data?.id
      console.log(id.data?.id,"cccccc");
      const response = await axios.get(BaseUrl + `api/company/logout?userId=${id}`);
      localStorage.clear();
      // window.location.href = "/";
            navigate("/");
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      localStorage.clear();
      window.location.href = "/";
    }
    // // api/company/logout?userId=q
    // localStorage.clear();
    // //  navigate("/")
    // window.location.href = "/";
  };
  useEffect(() => {
    getProfile();
  }, []);
  return (
    <>
      <div className="sidebar-box" onClick={handleClose}>
        <Link className="d-lg-block d-none" to="/">
          <img src={Logo} alt="" />
        </Link>
        <div className="user-info d-flex align-items-center mt-xl-5 mt-4 p-1 mb-4">
          <div className="user-icon">
            <img
            className="img-fluid"
              src={BaseUrl + `uploads/${profile.profilePic}`}
              alt="custom-pic"
            />
          </div>
          <div className="user-info ps-2">
            <strong className="f-12 d-block">
              {profile?.company_details?.[0]?.company_name}
            </strong>
            <span className="f-12 fw-400">
              {profile?.firstName} {profile?.lastName}
              <span
                className="ms-1"
                onClick={() => handleCopy({ text: profile?.firstName +  profile?.lastName })}
                style={{ cursor: "pointer" }}
              >
                {isCopied ? <GiCheckMark /> : <FaCopy />}
              </span>
            </span>
          </div>
        </div>
        <ul className="pages-links h-100">
          {routes.map((route) => (
            <NavLink to={route.path} key={route.name}>
              <span className="link-icon align-middle">{route.icon}</span>
              <label className="link-name align-middle ps-2 pointer">
                {route.name}
              </label>
            </NavLink>
          ))}
        </ul>
        <button
          className="common-btn position-absolute logout-btn"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
    </>
  );
};
