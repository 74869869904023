import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../../common/BaseUrl";
import { fireToast } from "../../../../common/Toster";
import Logo from "../../../../assets/images/u-vault-logo.svg";

const CorporateAddUser = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const value = searchParams.get("value");
  const [userInfo, setUserInfo] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordsMatch, setIsPasswordsMatch] = useState(false);

  const addUserInfo = async () => {
    try {
      const response = await axios.get(
        BaseUrl + `api/company/join_subadmin?value=${value}`
      );
      if (response.data) {
        setUserInfo(response?.data?.data);
      } else {
      }
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };
  const navigate = useNavigate();

  const passwordSubadmin = async () => {
    try {
      const id = userInfo?.user?._id;
      const response = await axios.post(
        BaseUrl + `api/company/password_subadmin`,
        {
          password: password,
          userId: id,
        }
      );
      if (response.data) {
        navigate("/");
      }
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (value) {
      addUserInfo();
    }
  }, [value]);

  useEffect(() => {
    // Check if passwords match
    setIsPasswordsMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isPasswordsMatch) {
      passwordSubadmin();
    } else {
      fireToast("error", "Passwords do not match.");
    }
  };
  console.log(userInfo, "userInfo");
  return (
    <>
      <div>
        <div className="d-md-flex position-relative py-5 verify-box ">
          <Link className="site-logoo d-lg-block d-none" to="/">
            <img src={Logo} alt="" />
          </Link>
          <div className="w-100 d-flex mt-5 align-items-center justify-content-center">
            <div className="container">
              <div className="w-50 mx-auto">
                <div className="">
                  <form
                    className="row"
                    onSubmit={handleSubmit}
                    style={{ backgroundColor: "#f9f9f9" }}
                  >
                    {userInfo ? (
                      <div className="col-12 text-center p-4 rounded">
                        <h2 className="mb-2 f-40">
                          Join {userInfo?.company_name} on U-Vault
                        </h2>
                        <p className="f-16 mb-lg-5 mb-4">
                          Please enter your information below. This credential
                          will be your U-Vault login ID
                        </p>
                        <div className="mb-4" style={{ textAlign: "left" }}>
                          <div className="col-lg-12 d-flex">
                            <div className="w-100 mb-3">
                              <label className="f-14 mb-2">
                                <p>First Name</p>
                              </label>
                              <input
                                name="firstName"
                                className="form-control"
                                placeholder="First Name"
                                type="text"
                                value={userInfo?.user?.firstName}
                                readOnly
                              />
                            </div>
                            <div className="w-100 ms-2 mb-3">
                              <label className="f-14 mb-2">
                                <p>Last Name</p>
                              </label>
                              <input
                                name="lastName"
                                className="form-control"
                                placeholder="Last Name"
                                type="text"
                                value={userInfo?.user?.lastName}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 mb-3">
                            <label className="f-14 mb-2">
                              <p>Email</p>
                            </label>
                            <input
                              name="email"
                              className="form-control"
                              placeholder="Last Name"
                              type="email"
                              value={userInfo?.user?.email_address}
                              readOnly
                            />
                          </div>
                          <div className="col-lg-12 d-flex">
                            <div className="w-100 mb-3">
                              <label className="f-14 mb-2">
                                <p>Set Password</p>
                              </label>
                              <input
                                required
                                name="password"
                                className="form-control"
                                placeholder="Enter Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                            <div className="w-100 ms-2 mb-3">
                              <label className="f-14 mb-2">
                                <p>Confirm Password</p>
                              </label>
                              <input
                                required
                                name="confirmPassword"
                                className="form-control"
                                placeholder="Confirm Password"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <button
                            className="common-btn w-100 mb-3"
                            type="submit"
                            disabled={!isPasswordsMatch}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 text-center p-4 rounded">
                        <h2 className="mb-2 f-40">The link has expired!</h2>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CorporateAddUser;
