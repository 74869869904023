import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/u-vault-logo.svg";
import { BsInfoCircle } from "react-icons/bs";
import auth from "../../assets/images/signup-banner.png";
import form_img from "../../assets/images/girl-sit.png";
import axios from "axios";
import { BaseUrl } from "../../common/BaseUrl";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { fireToast } from "../../common/Toster";
import PasswordChecklist from "react-password-checklist";

const SignUpCompanyRepresentative = () => {
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState(""); // Message for password mismatch

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone_no: "",
    domain_name: "",
    email_address: "",
    designation: "",
    level: "",
    date_of_establishment: "",
    trade_license_no: "",
    legal_structure: "",
    zip_code: "",
    state: "",
    city: "",
    address: "",
    industry_type: "",
    company_name: "",
    no_of_employees: "",
    password: "",
  });

  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem("companyFormData"));
    // console.log("Stored Form Data:", storedFormData);

    if (storedFormData) {
      setFormData({
        firstName: "",
        lastName: "",
        phone_no: "",
        email_address: "",
        designation: "",
        level: "",
        password: "",
        date_of_establishment: storedFormData.dateOfEstablishment || "",
        trade_license_no: storedFormData.tradeLicenseNo || "",
        legal_structure: storedFormData.legal_structure || "",
        zip_code: storedFormData.zipCode || "",
        state: storedFormData.State || "",
        country: storedFormData.Country || "",
        address: storedFormData.companyAddress || "",
        industry_type: storedFormData.industryType || "",
        company_name: storedFormData.companyName || "",
        no_of_employees: storedFormData.numberOfEmployees || "",
      });
    }
  }, []);

  useEffect(() => {
    setPasswordMatch(formData.password === formData.confirmPassword);
    // if (confirmPassword.length < 3) {
    //   setPasswordMessage('Password must be at least 3 characters long.');
    // } else {
    //   setPasswordMessage('');
    // }
    setPasswordMessage(
      formData.password === formData.confirmPassword
        ? "" // Reset message if passwords match
        : "Passwords do not match"
    );
  }, [formData.password, formData.confirmPassword]);

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, confirmPassword: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      fireToast("error", "Passwords do not match");
      return; // Stop further execution
    }
    try {
      const response = await axios.post(
        BaseUrl + `api/company/signup_company`,
        formData
      );
      console.log("API Response:", response.data);
      localStorage.setItem("companyFormData", JSON.stringify(response.data));
      fireToast("success", response?.data?.message);
      navigate("/company-verify");
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  return (
    <>
      <div className="auth-wrapper mxh-100 d-md-flex position-relative">
        <div className="mxh-100 w-100 d-md-flex position-relative">
          <div formattedValue className="row  w-100">
            <div className="  auth-wrapper-inner lamp-box d-flex align-items-center justify-content-center col-md-5 col-sm-12">
              <Link className="site-logoo" to="/">
                <img src={Logo} alt="" />
              </Link>
              <div className="AuthIms">
                <img src={auth} alt="auth" />
              </div>
            </div>
            <div className="col-md-7 col-sm-12 d-flex p-5 rounded-4 FormDiv lamp-box">
              <form
                className="row ms-5 FormStyle auth-form"
                onSubmit={handleSubmit}
              >
                <div className="col-md-12">
                  <h2 className="mb-3 f-36">Representative Details</h2>
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="f-14 mb-2">
                        <p>First Name</p>
                      </label>
                      <input
                        name="firstName"
                        className="form-control"
                        placeholder="First Name"
                        type="text"
                        required
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label className="f-14 mb-2">
                        <p>Last Name</p>
                      </label>
                      <input
                        name="lastName"
                        className="form-control"
                        placeholder="Last Name"
                        type="text"
                        required
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-12">
                      <label className="f-14 mb-2">
                        <p>Phone Number</p>
                      </label>
                      <PhoneInput
                        country={"us"}
                        value={formData.phone_no}
                        onChange={(phone) =>
                          setFormData({ ...formData, phone_no: phone })
                        }
                        inputProps={{
                          required: true,
                        }}
                      />
                    </div>
                    <div className="col-lg-12">
                      <label className="f-14 mb-2">
                        <p>Email Address</p>
                      </label>
                      <input
                        name="email_address"
                        className="form-control"
                        placeholder="Email Address"
                        type="email"
                        value={formData.email_address}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-lg-12">
                      <label className="f-14 mb-2">
                        <p>Designation</p>
                      </label>
                      <input
                        name="designation"
                        className="form-control"
                        placeholder="Designation"
                        type="text"
                        value={formData.designation}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-lg-12">
                      <label className="f-14 mb-2" htmlFor="level">
                        <p>Level</p>
                      </label>
                      <select
                        style={{
                          padding: "10px",
                          borderRadius: "6px",
                          backgroundColor: "white",
                          border: "1px solid #a8a8a8",
                        }}
                        id="level"
                        className="NumberofEmployees form-control"
                        value={formData.level}
                        onChange={handleChange}
                        name="level"
                        required
                      >
                        <option value="">Select</option>
                        <option value="Intern">Intern</option>
                        <option value="Associate">Associate</option>
                        <option value="Junior">Junior</option>
                        <option value="Senior">Senior</option>
                        <option value="Manager">Manager</option>
                        <option value="Executive">Executive</option>
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <label className="f-14 mb-2">
                        <p>Set Password</p>
                      </label>
                      <input
                        required
                        name="password"
                        className="form-control"
                        placeholder="Enter Password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label className="f-14 mb-2">
                        <p>Confirm Password</p>
                      </label>
                      <input
                        required
                        name="confirmPassword"
                        className="form-control"
                        placeholder="Confirm Password"
                        type="password"
                        value={formData.confirmPassword}
                        onChange={handleConfirmPasswordChange}
                      />
                      {passwordMessage &&
                        formData?.confirmPassword?.length > 3 && (
                          <p className="text-danger">{passwordMessage}</p>
                        )}
                    </div>
                    {formData.password.length >= 1 && (
                    <div className="col-lg-12">
                      {/* <PasswordChecklist
                              className="PasswordChecklist"
                              iconSize={10}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                              rules={[
                                "capital",
                                "specialChar",
                                "minLength",
                                "lowercase",
                                "number",
                              ]}
                              minLength={8}
                              value={formData.password}
                              messages={{
                                capital: "(A-Z)",
                                specialChar:
                                  "(!, @, #, $)",
                                minLength: "8 digits",
                                lowercase: "(a-z)",
                                number: "(0-9)",
                              }}
                                /> */}
                      <PasswordChecklist
                        className="text-xs d-flex w-100"
                        iconSize={8}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                        rules={[
                          "capital",
                          "specialChar",
                          "minLength",
                          "lowercase",
                          "number",
                        ]}
                        minLength={8}
                        value={formData.password}
                        messages={{
                          capital: "At least one uppercase letter (A-Z)",
                          specialChar:
                            "At least one special character (e.g., !, @, #, $, etc.)",
                          minLength: "At least 8 digits",
                          lowercase: "At least one lowercase letter (a-z)",
                          number: "At least one number (0-9)",
                        }}
                      />
                    </div>)}
                    <div className="col-12 mt-4">
                      <button
                        className={`common-btn w-100 mb-3 text-light`}
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <figure className="form-right-figure  align-items-md-end d-none d-lg-flex">
                <img src={form_img} className="img-fluid" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpCompanyRepresentative;
