import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Nav,
  Row,
} from "react-bootstrap";
import Logo from "../../../../assets/images/u-vault.svg";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../../../common/BaseUrl";
import { fireToast } from "../../../../common/Toster";

export const Header = () => {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const token = authUser ? authUser.token : null;
  const [profile, setProfile] = useState("");
  const [profileEmail, setProfileEmail] = useState("");
  console.log(profile, "profile");

  useEffect(() => {
    getMyProfile();
  }, []);

  const getMyProfile = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(BaseUrl + "api/user/myProfile", {
        headers: {
          Authorization: token,
        },
      });

      setProfile(response?.data?.data?.userDetails);
      setProfileEmail(response?.data?.data?.emails[0]?.email);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      if (error?.response?.data?.error === "Unauthorized") {
      }
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("user");

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    setIsOpen(true); // Close the dropdown when a tab is clicked
  };

  return (
    <>
      <header className="site-header HeaderMain py-3">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="d-flex align-items-center justify-content-between HeaderMenu">
                <div className="d-flex mb-w-100">
                  <a href="/" className="logo">
                    <img src={Logo} alt="" />
                  </a>
                  <div className="mb-btn d-flex ms-auto">
                    {/* <Link className="custom-btn " to="/login">
                      Log in
                    </Link>
                    <Link className="custom-btn ms-3" to="/sign-up">
                      {" "}
                      Sign Up{" "}
                    </Link> */}
                    {/* <div style={{ position: "relative" }}>
                      <button
                        className="dropdown-button custom-btn"
                        onClick={handleDropdownClick}
                      >
                        Register
                      </button>
                      {isOpen && (
                        <div
                          className="dropdown-content mt-2 register-dropdown"
                          style={{
                            position: "absolute",
                            zIndex: "10",
                            right: "0px",
                          }}
                        >
                          <div className="tab-header">
                            <button
                              className={`tab w-50 ${
                                activeTab === "user" ? "active" : ""
                              }`}
                              onClick={() => handleTabChange("user")}
                            >
                              User
                            </button>
                            <button
                              className={`tab w-50 ${
                                activeTab === "company" ? "active" : ""
                              }`}
                              onClick={() => handleTabChange("company")}
                            >
                              Company
                            </button>
                          </div>
                          <div className="tab-content">
                            {activeTab === "user" && (
                              <>
                                <Link to="/login" className="d-block">
                                  Log in
                                </Link>
                                <Link to="/sign-up" className="d-block">
                                  Sign up
                                </Link>
                              </>
                            )}
                            {activeTab === "company" && (
                              <>
                                <Link to="/login-company" className="d-block">
                                  Log in
                                </Link>{" "}
                                <Link to="/sign-up-company" className="d-block">
                                  Sign up
                                </Link>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div> */}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end HeaderMenuInner">
                  <ul className="manu-list d-flex align-items-center">
                    <li>
                      <a href="#joincommunity">Join the community</a>
                    </li>
                    <li>
                      <a href="#about">About</a>
                    </li>
                    {/* {!token && (
                      <>
                        <li className="mb-none">
                          <Link to="/login">Log in</Link>
                        </li>
                        <li className="mb-none">
                          <Link className="custom-btn" to="/sign-up">
                            Sign Up
                          </Link> */}
                          {/* <div style={{ position: "relative" }}>
                            <button
                              className="dropdown-button custom-btn"
                              onClick={handleDropdownClick}
                            >
                              Register
                            </button>
                            {isOpen && (
                              <div
                                className="dropdown-content mt-2 register-dropdown"
                                style={{ position: "absolute", zIndex: "10" }}
                              >
                                <div className="tab-header">
                                  <button
                                    className={`tab w-50 ${
                                      activeTab === "user" ? "active" : ""
                                    }`}
                                    onClick={() => handleTabChange("user")}
                                  >
                                    User
                                  </button>
                                  <button
                                    className={`tab w-50 ${
                                      activeTab === "company" ? "active" : ""
                                    }`}
                                    onClick={() => handleTabChange("company")}
                                  >
                                    Company
                                  </button>
                                </div>
                                <div className="tab-content">
                                  {activeTab === "user" && (
                                    <>
                                      <Link to="/login" className="d-block">
                                        Log in
                                      </Link>
                                      <Link to="/sign-up" className="d-block">
                                        Sign up
                                      </Link>
                                    </>
                                  )}
                                  {activeTab === "company" && (
                                    <>
                                      <Link
                                        to="/login-company"
                                        className="d-block"
                                      >
                                        Log in
                                      </Link>{" "}
                                      <Link
                                        to="/sign-up-company"
                                        className="d-block"
                                      >
                                        Sign up
                                      </Link>
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                          </div> */}
                        {/* </li>
                      </>
                    )} */}
                    {profile?.profilePic === undefined ? (
                              <>
                              <li className="mb-none">
                                <Link to="/login">Log in</Link>
                              </li>
                              <li className="mb-none">
                                <Link className="custom-btn" to="/sign-up">
                                  Sign Up
                                </Link>
                                </li>
                                </>
                    ):(
                      <li className="profileButton">
                        <Link
                          className="custom-btn d-flex w-100"
                          to="/dashboard"
                        >
                          <img
                            src={BaseUrl + `/uploads/${profile?.profilePic}`}
                            alt=""
                            width={40}
                            height={40}
                            className="rounded-circle ms-3 col-2 "
                          />
                          <div className="text-white col-10 ps-2 text-start">
                            <h6 className="mb-0">
                              {profile?.firstName} {profile?.lastName}
                            </h6>
                            <p className="f-11 text-white"> {profileEmail}</p>
                          </div>
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};
