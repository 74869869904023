import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/u-vault.svg";
import OTPInput from "react-otp-input";
import axios from "axios";
import { BaseUrl } from "../../common/BaseUrl";
import { fireToast } from "../../common/Toster";
import form_img from "../../assets/images/verify-otp.png";
import userIcon from "../../assets/images/user-otp.png";
const VerifyCorporateforgotOtp = () => {
  document.title = "Verify Email";

  const storedFormData = JSON.parse(localStorage.getItem("companyFormData"));
  const data = storedFormData;
console.log(data, "fffffffff");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(BaseUrl + `api/company/verifyOtp`, {
        email: data,
        otp: otp,
      });

      localStorage.setItem("companyFormData", JSON.stringify(response.data));
      fireToast("success", response?.data?.message);
      navigate("/reset_password");
    } catch (error) {
      // Handle error
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  const handleresend = async (e) => {
    try {
      const response = await axios.post(BaseUrl + `api/company/resendotp`, {
        email: data,
      });
      fireToast("success", response?.data?.message);
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  return (
    <>
      <div className="lamp-box verify-corporate-otp">
        <div className="auth-wrapper mxh-100 d-md-flex position-relative py-5 verify-box ">
          <Link className="site-logo d-lg-block d-none" to="/login">
            <img src={Logo} alt="" />
          </Link>
          <div className="w-100 d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="opt-box-corporate">
                <div className="">
                  <form className="row" onSubmit={handleSubmit}>
                    <div className="col-12 text-center">
                      <h2 className="mb-2 f-40">Email Verification</h2>
                      <p className="f-16 mb-lg-5 mb-4">
                        Enter the OTP sent on {data}
                      </p>
                      <div className="mb-4 otp-input">
                        <OTPInput
                          value={otp}
                          onChange={(enteredOtp) => {
                            setOtp(enteredOtp);
                            // Check if OTP length is 6, then submit form
                            // if (enteredOtp.length === 6) {
                            //   handleSubmit(); // Submit the form
                            // }
                          }}
                          numInputs={6}
                          renderInput={(props, index) => (
                            <input {...props} key={index} />
                          )}
                        />
                      </div>
                      <div className="">
                        <button className="common-btn w-100 mb-3" type="submit">
                          Verify OTP
                        </button>
                        <p className="f-14">
                          Didn’t receive the OTP ?{" "}
                          <Link
                            onClick={handleresend}
                            className="fw-700 t-color"
                          >
                            Resend OTP
                          </Link>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <figure className="form-right-figure  align-items-md-end d-none d-lg-flex">
          <img src={form_img} className="img-fluid" />
        </figure>
      </div>
    </>
  );
};

export default VerifyCorporateforgotOtp;
