import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BsQuestionCircle } from "react-icons/bs";
import { BaseUrl } from "../../common/BaseUrl";
import axios from "axios";
import { fireToast } from "../../common/Toster";

function SendOtpModal({phoneNo, Dialcode}) {
  const [show, setShow] = useState(false);
  const [otp, setOtp] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
console.log(phoneNo,"phoneNo");
  const getotp = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(
        BaseUrl + `api/user/sendotp-verify?phone=${phoneNo}&dial_code=${Dialcode?.dialCode}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  // useEffect(() => {
  //   getotp();
  // }, []);

  const getEnterotp = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(
        BaseUrl + `api/user/verifynumber?otp=${otp}&phone=${phoneNo}&dial_code=${Dialcode?.dialCode}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      fireToast("success", response?.data?.message)
      handleClose();
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error)
    }
  };

  return (
    <>
      {/* <span className="t-color f-12" onClick={handleShow (getotp)}>
        <BsQuestionCircle className="t-gray pe-2 f-24 align-bottom" />
        Verify phone number
      </span> */}
<span className="t-color f-12" onClick={() => { handleShow(); getotp(); }}>
  <BsQuestionCircle className="t-gray pe-2 f-24 align-bottom" />
  Verify phone number
</span>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Verify Phone Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter the OTP sent to your mobile number:</p>
          <Form.Control
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="Enter OTP"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={getEnterotp}>
            Verify OTP
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SendOtpModal;
