import React, { useState } from "react";
import ticket_raise from "../../../../assets/images/raise-ticket.png";
import axios from "axios";
import { fireToast } from "../../../../common/Toster";
import { BaseUrl } from "../../../../common/BaseUrl";
import CorporateTopBar from "../TopBar/Topbar";
const HelpCenter = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  // const navigate = useNavigate;
  const localData = JSON.parse(localStorage.getItem("authUser"));
  const token = localData?.data?.token;
  const handlehelpSupport = async () => {
    try {
      const response = await axios.post(
        BaseUrl + "api/company/userQuery",
        {
          message: message,
          title: title,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response) {
        console.log("API Response:", response.data);
        fireToast("success", "Your query has been successfully submitted");
        setMessage("");
        setTitle("");
      }
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendButtonClick = () => {
    handlehelpSupport();
  };
  return (
    <>
      <CorporateTopBar />
      <div className="contact-element position-relative">
        <div>
          <div className="row">
            <div class="d-flex align-items-center justify-content-between mb-5">
              <h4 class="f-24 d-flex align-items-center">Raise Ticket</h4>
            </div>
            <div className="col-md-6 mb-4">
              <div className="shadow rounded-2 p-4">
                <div className="form-group mb-4">
                  <label className="f-14 fw-bold mb-2">Subject</label>
                  <input
                    name="name"
                    className="form-control"
                    placeholder="Enter subject here, 80 character limit."
                    value={title}
                    required
                    maxLength={80}
                    onChange={handleTitleChange}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="f-14 fw-bold mb-2">Message</label>
                  <textarea
                    name="message"
                    className="form-control"
                    placeholder="Enter your message here, 360 character limit."
                    value={message}
                    onChange={handleMessageChange}
                    required
                    maxLength={360}
                  />
                </div>
                <button
                  className="common-btn w-100 py-3 border-0"
                  onClick={handleSendButtonClick}
                >
                  Send
                </button>
              </div>
            </div>
            <div className="col-md-6 position-relative content-area ps-md-4">
              <div className="mb-4">
                <h4>Step 1: Enter a subject:</h4>
                <div className="d-flex contact-step align-items-center">
                  <div className="contact-number">
                    <span>01</span>
                  </div>
                  <div className="contact-content ps-4">
                    <p>Tell us what this is about</p>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <h4>Step 2: Message:</h4>
                <div className="d-flex contact-step align-items-center">
                  <div className="contact-number">
                    <span>02</span>
                  </div>
                  <div className="contact-content ps-4">
                    <p>
                      Provide some detail so we understand your concern better
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <h4>Step 3: Click send:</h4>
                <div className="d-flex contact-step align-items-center">
                  <div className="contact-number">
                    <span>03</span>
                  </div>
                  <div className="contact-content ps-4">
                    <p>We're on it and should get back within the next 48hrs</p>
                  </div>
                </div>
              </div>
              <figure className="mb-0 position-absolute figure-box text-end">
                <img src={ticket_raise} className="img-fluid" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpCenter;
