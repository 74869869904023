import './Modals.css'
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { deleteFolder } from '../../services/userServices';
import { fireToast } from '../../common/Toster';
import axios from 'axios';
import { BaseUrl } from '../../common/BaseUrl';

const DeleteConfirm = ({ deleteModal, setDeleteModal, folderData, currentFolder }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); setDeleteModal(false) }
console.log(folderData,"Delete confirmation");
  useEffect(() => {
    setShow(deleteModal)
  }, [deleteModal])

  const fetchDeleteFolder = async () => {
    try {
  let token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.delete(BaseUrl + `api/folder/deleteFolder?folder_id=${folderData?._id}`,{
        headers: { Authorization: token },
      })
      if (response) {
        fireToast("success", response?.message || "Folder Deleted Successfully")
        setShow(false);
        window.location.reload();
      }
    } catch (error) {
      console.error('API Error:', error)
      fireToast("error", error?.response?.error || "This folder cannot be deleted!")
    }
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className='border-0'>
          <div>
            <Modal.Title className='f-20 fw-500'>Delete confirmation</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p className='f-18'>This action cannot be undone. Are you sure you want to permanently delete this folder?</p>
          <div className="d-flex align-items-center justify-content-end mt-5">
            <button className='border-btn me-3' onClick={handleClose}>
              Cancel
            </button>
            <Button className='common-btn' variant="danger" onClick={fetchDeleteFolder}>
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}


export default DeleteConfirm