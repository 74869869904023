import React, { useEffect, useState } from "react";
import "./FolderCard.css";
import { FaFolderMinus } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
import FolderOffcanvas from "../folder-sidebar/FolderOffcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import RenameFolder from "../modals/RenameFolder";
import DeleteConfirm from "../modals/DeleteConfirm";
import { getMyFolder } from "../../services/userServices";
import moment from "moment";
import ShareFolder from "../modals/ShareFolder";
import ShareFile from "../modals/ShareFile";
import axios from "axios";
import { BaseUrl } from "../../common/BaseUrl";

const FolderCard = () => {
  const [canvasModalStatus, setCanvasModalStatus] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [folderData, setFolderData] = useState();
  const [dlfiles, setDlFiles] = useState();

  const [myFolders, setMyFolders] = useState([]);
  console.log(myFolders, "FolderCardFolderCardFolderCard");
  const fetchFolder = async () => {
    try {
      const resp = await getMyFolder();
      // console.log(resp, "resp");
      if (resp.status === 200) {
        setMyFolders(resp.data.data);
      }
    } catch (error) {
      // console.log(error, "error");
    }
  };
  useEffect(() => {
    fetchFolder();
  }, []);

  const [folderShare, setFolderShare] = useState(false);

  const handleFolderBlockClick = () => {
    setCanvasModalStatus(true);
  };

  // const fetchOpenFile = async (val) => {
  //   try {
  //     let token = JSON.parse(localStorage.getItem("authUser")).token;
  //     const response = await axios.post(
  //       BaseUrl + `api/folder/generate-link`,
  //       {
  //         filePath: val?.Key || val?.key,
  //       },
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );
  //     if (response) {
  //       setDlFiles(response?.data?.data);
  //       window.open(response?.data?.data);
  //     }
  //   } catch (error) {
  //     console.error("API Error:", error);
  //   }
  // };

  const fetchfolderDownload = async (folderData) => {
    try {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.post(
        BaseUrl + `api/folder/downloadFolder`,
        {
          folderId: folderData?._id,
        },
        {
          headers: { Authorization: token },
        }
      );
      if (response) {
        // setDlFiles(response?.data?.data);
        window.open(response?.data?.data);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  return (
    <>
      <ShareFile
        folderShare={folderShare}
        setFolderShare={setFolderShare}
        folderData={folderData}
      />
      <FolderOffcanvas
        folderData={folderData}
        canvasModalStatus={canvasModalStatus}
        setCanvasModalStatus={setCanvasModalStatus}
      />
      <RenameFolder
        folderData={folderData}
        renameModal={renameModal}
        setRenameModal={setRenameModal}
      />
      <DeleteConfirm
        folderData={folderData}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
      />

      {myFolders?.map((val) => (
        <div
          className={`col-xxl-4 col-sm-6 mb-4 order-1 ${
            val.name === "In tray" ? "In Tray" : ""
          }`}
        >
          <div
            className="folder-block"
            onClick={() => {
              handleFolderBlockClick();
              setFolderData(val);
            }}
          >
            <div className="folder-body d-flex align-items-center justify-content-between p-xl-3 px-2 py-3">
              <div className="pointer">
                <span className="folder-icon align-middle">
                  <FaFolderMinus />
                </span>
                <div className="d-inline-block align-middle ps-xl-4 ps-md-3 ps-2">
                  <p className="f-16 mb-1 position-relative">
                    <span>{val.name}</span>{" "}
                  </p>
                  {/* <p className="f-14">{moment(val.LastModified).format('HH:mm:ss a YYYY-MM-DD')}</p> */}
                  <p className="f-14">{moment(val.createdAt).format('ll')}</p>
                </div>
              </div>
              <div className="menu-dot" onClick={(e) => e.stopPropagation()}>
                <Dropdown>
                  <Dropdown.Toggle
                    className="bg-transparent border-0 w-auto after-none"
                    id="f-option"
                  >
                    <BsThreeDotsVertical className="t-color" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="f-14">
                    <Dropdown.Item
                      onClick={() => {
                        setCanvasModalStatus(true);
                        setFolderData(val);
                      }}
                    >
                      View Details
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setFolderShare(true);
                        setFolderData(val);
                      }}
                    >
                      Share{" "}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setDeleteModal(true);
                        setFolderData(val);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setRenameModal(true);
                        setFolderData(val);
                      }}
                    >
                      Rename
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      onClick={() => {
                        fetchOpenFile(val);
                      }}
                    >
                      Download
                    </Dropdown.Item> */}
                    <Dropdown.Item
                            onClick={fetchfolderDownload}
                          >
                            Download
                          </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="folder-footer d-flex align-items-center justify-content-between px-lg-4 px-3 py-3 mt-lg-3">
              <div className="">
                <strong className="f-12">Total Documents</strong>
                <span className="f-12 ps-2">{val.totalFiles}</span>
              </div>
              <div className="">
                <strong className="f-12">Storage</strong>
                <span className="f-12 ps-2">{val.totalSize}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default FolderCard;
