import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../../../assets/images/u-vault.svg";
import { Link } from "react-router-dom";
import Icon1 from "../../../../assets/images/linkedin.svg";
import Icon2 from "../../../../assets/images/x.svg";
import Icon3 from "../../../../assets/images/fb.svg";

export const Footer = () => {
  return (
    <>
      <footer className="home-footer">
        <Container>
          <Row className="align-items-center footer-inner">
            <Col xs={12} className="mb-4 mb-lg-5 text-start">
              <Link to="/">
                <img className="filter" src={Logo} alt="" />
              </Link>
            </Col>
            </Row>
            <Row className="align-items-end footer-inner">
            <Col md={12} lg={3}>
            <Link to="/sign-up-company" className="d-block">
               <h5 className="text-white mb-0">Company Site</h5> 
            </Link>
              {/* <ul className="page-link text-start d-md-flex align-items-center">
                <li className="mb-3 mb-md-0">
                  <Link to="/login-company" className="d-block">
                    Log in
                  </Link>
                </li>
                <li className="mb-3 mb-md-0 ms-3">
                  <Link to="/sign-up-company" className="d-block">
                    Sign up
                  </Link>
                </li>
              </ul> */}
            </Col>
            <Col md={12} lg={5} className="d-flex align-items-end">
              <ul className="page-link text-start d-md-flex align-items-center">
                <li className="mb-3 mb-md-0">
                  <Link className="" to="/contactus">
                    Contact
                  </Link>
                </li>
                <li className="mb-3 mb-md-0">
                  <Link className="mx-md-4" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </li>
                <li className="mb-3 mb-md-0">
                  <Link className="" to="/terms-conditions">
                    Terms & Conditions{" "}
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md={12} lg={4} className="d-flex align-items-end">
              <p className="f-18 text-center text-white Copyright">
                Copyright ©2024 U-Vault All rights reserved
              </p>
            </Col>
            {/* <Col md={12} lg={3}>
                            <ul className='social-link d-flex align-items-center justify-content-lg-end'>
                                <li><Link className='' to="https://www.linkedin.com/company/u-vault"><img src={Icon1} alt="" /></Link></li>
                                <li><Link className='mx-4' to="https://twitter.com/uvaultio"><img src={Icon2} alt="" /></Link></li>
                                <li><Link className='' to="https://www.facebook.com/uvaultio/"><img src={Icon3} alt="" /></Link></li>
                            </ul>
                        </Col> */}
          </Row>
        </Container>
      </footer>
    </>
  );
};
