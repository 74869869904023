import axios from "axios";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaAngleRight } from "react-icons/fa6";
import { BaseUrl } from "../../common/BaseUrl";
import moment from "moment";

function UnblockDomain({ data, onSettingHandler  }) {
  const [show, setShow] = useState(false);
  const [blockedDomains, setBlockedDomains] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

console.log(onSettingHandler, "onSettingHandler");

  useEffect(() => {
    getBlockedDomains();
    onSettingHandler();
  }, []);

  const getBlockedDomains = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(
        BaseUrl + `api/folder/blockDomainListing`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setBlockedDomains(response?.data?.data?.result || []);
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  const unblockDomain = async (domainId) => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.post(
        BaseUrl + `api/folder/unBlockADomain`,
        {
          domain_id: domainId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      onSettingHandler();
      getBlockedDomains();
      handleClose();
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  return (
    <>
      <FaAngleRight onClick={handleShow} className="mt-1" />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title>Blocked Domains ({data?.domaincount})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            {data?.domaincount === 0 && <p>No domains blocked.</p>}
            {blockedDomains.length > 0 && (
              <ul>
                {blockedDomains.map((unblock) => (
                  <li key={unblock?._id}>
                    <div className="d-flex justify-content-around BlockedDomains">
                      <div>{unblock?.alias}</div>
                      <div>{moment(unblock?.updatedAt).format("L")}</div>
                      <button onClick={() => unblockDomain(unblock?._id)}>
                        Unblock
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UnblockDomain;
