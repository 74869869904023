import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { verifyOtp, forgotPassword } from "../../services/userServices";
import { fireToast } from "../../common/Toster";
import OtpInput from "react-otp-input";
import auth from "../../assets/images/auth.webp";
import Logo from "../../assets/images/u-vault-logo-white.svg";

const VerifyOtp = () => {
  document.title = "Verify OTP";

  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [showsend, setShowsend] = useState(true);
  const [timer, setTimer] = useState(300);
  useEffect(() => {
    if (timer === 0) {
      setShowsend(false);
    } else {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);
  // useEffect(() => {
  //   const countdown = setInterval(() => {
  //     setTimer((prevTimer) => prevTimer - 1);
  //   }, 1000);

  //   // Cleanup function to clear the interval when the component is unmounted
  //   return () => clearInterval(countdown,setShowsend(false));
  // }, []);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds} ${
      minutes < 1 ? "secounds" : "minutes"
    }`;
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: localStorage.getItem("contact-info"),
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .min(5, "Invalid otp")
        .max(6, "Invalid otp")
        .required("Please check the mail and enter the OTP."),
    }),
    onSubmit: async (values) => {
      try {
        const loginResponse = await verifyOtp(values);
        if (loginResponse.status === 200) {
          fireToast("success", loginResponse.message);
          localStorage.setItem("contact-info", loginResponse.data.token);
          navigate("/reset-password");
        } else {
        }
      } catch (err) {
        fireToast("error", err.response.data.error);
      }
    },
  });

  const email = localStorage.getItem("contact-info");

  const resendOtp = async () => {
    try {
      const loginResponse = await forgotPassword({ email });
      if (loginResponse.status === 200) {
        fireToast("success", loginResponse.message);
        setTimer(300);
        setShowsend(true);
      } else {
        throw new Error(loginResponse.error);
      }
    } catch (err) {
      fireToast("error", err.response.data.error);
    }
  };

  return (
    <>
      <div className="mxh-100 w-100 d-md-flex position-relative">
        <div className="row w-100">
        <div className=" auth-wrapper user-login auth-wrapper-inner d-flex align-items-center justify-content-center mxh-100 col-md-6 col-sm-12">
            <Link className="site-logo" to="/">
              <img src={Logo} alt="" />
            </Link>
            <div className="AuthIms">
              <img src={auth} alt="auth" />
            </div>
            <div className="row w-100">
              <div className="Welcome">
                <h1 className="text-white f-48">Security check!</h1>
                <p className="text-white f-32">
                  Enter the OTP to Reset Password
                </p>
              </div>
            </div>
          </div>

          <div className="auth-right d-flex align-items-center justify-content-center mxh-100 col-md-6 bg-white">
            <div className="row w-100">
              <div className="opt-screen d-flex align-items-center justify-content-center bg-white margin-auto">
                <form
                  className="row FormStyle"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="col-12">
                    <h2 className="mb-2 f-40">OTP Verification</h2>
                    <p className="f-16 mb-lg-5 mb-4">Enter the OTP sent on </p>
                    <div className="mb-4 otp-input">
                      <OtpInput
                        value={otp}
                        onChange={(enterOtp) => {
                          setOtp(enterOtp);
                          validation.setFieldValue("otp", enterOtp);
                          if (enterOtp.length === 6) {
                            validation.handleSubmit();
                          }
                        }}
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                      />
                      {validation.touched.otp && validation.errors.otp ? (
                        <p className="text-danger">{validation.errors.otp}</p>
                      ) : null}
                    </div>
                    <div className="">
                      <button className="common-btn w-100 mb-3">Submit</button>
                      {/* <p className="f-14">
                        Didn’t receive the OTP?{" "}
                        <Link className="fw-700 t-color" onClick={resendOtp}>
                          Resend OTP
                        </Link>
                      </p> */}
                      {showsend ? (
                        <>
                          <p className="text-black text-center pt-3">
                            Please Resend OTP in {formatTime(timer)}
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="f-14">
                            Didn’t receive the OTP?{" "}
                            <Link
                              className="fw-700 t-color"
                              onClick={resendOtp}
                            >
                              Resend OTP
                            </Link>
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;
