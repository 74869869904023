import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BaseUrl } from "../../../../common/BaseUrl";
import { fireToast } from "../../../../common/Toster";

function AddUser({ getUser }) {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [platformRole, setPlatformRole] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleAddUser = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data.token;
      const userData = {
        platform_role: platformRole,
        lastName: lastName,
        firstName: firstName,
        email_address: email,
      };
      const response = await axios.post(
        BaseUrl + "api/company/adduser",
        userData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // Handle success response
      console.log("User added successfully:", response.data);
      fireToast("success", "User added successfully");
      handleClose();
      getUser();
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error || "Failed to add user");
    }
  };

  return (
    <>
      <button className="common-btn" onClick={handleShow}>
        Add User
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <label>Enter Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-md-6 my-3">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="col-md-6 my-3">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="col-md-12 my-2">
                <strong>Role & Access</strong>
              </div>
              <div className="col-md-12 mt-2">
                <label>Platform Role</label>
                <select
                  id="platformRole"
                  className="form-control"
                  value={platformRole}
                  onChange={(e) => setPlatformRole(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  <option value="Sub-admin">Sub-admin</option>
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex mt-3 justify-content-around align-items-center">

            <button className="common-btn" onClick={handleAddUser}>
              Save Changes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddUser;
