import React, { useEffect, useState } from "react";
import TopBar from "../topbar/TopBar";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PiPhoneCallThin } from "react-icons/pi";
import { PiEnvelopeSimpleLight } from "react-icons/pi";
import PrivacyModal from "../modals/PrivacyModal";
import axios from "axios";
import { BaseUrl } from "../../common/BaseUrl";
import { FaAngleRight } from "react-icons/fa6";
import UnblockDomain from "../modals/UnblockDomain";
import ChangePwd from "../modals/ChangePwd";
import ChangePassword from "../auth/ChangePassword";
import PolicyModal from "./privacy/PolicyModal";
import TermModal from "./Landing/terms/TermModal";
import { fireToast } from "../../common/Toster";

const Settings = () => {
  document.title = "Settings";

  const [privacyInfo, setPrivacyInfo] = useState(false);
  const [settin, setSettin] = useState(0);
  const [notificationSettings, setNotificationSettings] = useState("");

  const settinghandler = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(BaseUrl + "api/user/mySettings", {
        headers: {
          Authorization: token,
        },
      });
      setNotificationSettings(response?.data?.data?.settings || {});
      setSettin(response?.data?.data || "");
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };
  const emails_enabled = JSON.stringify(notificationSettings.emails_enabled);
  const restrict_download = JSON.stringify(
    notificationSettings.restrict_download
  );
  const notifications_enabled = JSON.stringify(
    notificationSettings.notifications_enabled
  );

  useEffect(() => {
    settinghandler();
  }, []);
  const settingchangehandler = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      await axios.put(
        BaseUrl + "api/user/user-settings",
        {
          emails_enabled: emails_enabled,
          notifications_enabled: notifications_enabled,
          restrict_download: restrict_download,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      settinghandler();
      fireToast("success", "Setting updated")
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleCheckboxChange = (settingName) => {
    setNotificationSettings((prevSettings) => ({
      ...prevSettings,
      [settingName]: !prevSettings[settingName],
    }));
  };

  return (
    <>
      <PrivacyModal privacyInfo={privacyInfo} setPrivacyInfo={setPrivacyInfo} />
      <TopBar />
      <div>
        <div className="d-flex align-items-center justify-content-between mb-5">
          <h4 className="f-24 d-flex align-items-center">Account Settings</h4>
          <button className="common-btn" onClick={settingchangehandler}>
            Save Changes
          </button>
        </div>

        <div className="row border-bottom mb-md-4">
          <div className="col-lg-6 mb-md-4">
            <h5 className="f-20 fw-500 mb-1">Change Password</h5>
            <p className="f-14 fw-400">You can manage your password</p>
            <div className="row">
              <div className="col-lg-6"></div>
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div className="mt-4 change-pw">
              <ChangePassword />
            </div>
          </div>
        </div>

        <div className="row border-bottom mb-4">
          <div className="col-lg-6 mb-md-4 py-3">
            <h5 className="f-20 fw-500 mb-1">Email Notification</h5>
            <p className="f-14 fw-400">Notifications</p>
          </div>
          <div className="col-lg-6 mb-4">
            <div className="mt-4">
              <Form.Check
                type="checkbox"
                id="privacy7"
                label="Notify me when I receive a new message"
                checked={notificationSettings?.emails_enabled}
                onChange={() => handleCheckboxChange("emails_enabled")}
              />
              <p className="ps-4 f-12">
                We’ll notify you by email when you receive a document
              </p>
            </div>
            <div className="mt-4">
              <Form.Check
                type="checkbox"
                id="privacy6"
                label="Enable Browser Notification
                "
                checked={notificationSettings?.notifications_enabled}
                onChange={() => handleCheckboxChange("notifications_enabled")}
              />
              <p className="ps-4 f-12">
                A notification is sent to your browser when you receive a
                document
              </p>
            </div>
          </div>
        </div>

        <div className="row border-bottom mb-4">
          <div className="col-lg-6 mb-md-4">
            <h5 className="f-20 fw-500 mb-1"> Privacy preferences</h5>
            <p className="f-14 fw-400">Permission on shared files</p>
          </div>
          <div className="col-lg-6 mb-md-4">
            <div className="mt-4">
              <Form.Check
                type="checkbox"
                id="privacy7"
                label="Allow download, print & copy files shared"
                checked={notificationSettings?.restrict_download}
                onChange={() => handleCheckboxChange("restrict_download")}
              />
              <p className="ps-4 f-12">
              Allow people to download shared files
              </p>
            </div>
          </div>
        </div>

        <div className="row border-bottom mb-4">
          <div className="col-lg-6 mb-4">
            <h5 className="f-20 fw-500 mb-1">Block Domain</h5>
            <p className="f-14 fw-400">
              View and enable the blocked source domains
            </p>
          </div>
          <div className="col-lg-6 mb-4">
            <div className="mt-3 d-flex align-item-center">
              <p className="f-16">
                View Blocked Domain ({settin?.domaincount})
              </p>
              <UnblockDomain data={settin} onSettingHandler={settinghandler} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 mb-4">
            <div className="f-20 mb-4">Contact Us:</div>
            <p className="f-12 fw-400 mb-4">
            Please reach out to us through our <Link to={"/contactus"}>Contact Us</Link> page <br />and anticipate a response within 48 hours. 
            </p>
          </div>
          <div className="col-lg-6 mb-4">
            <p className="f-14 fw-400 text-primary">
              <TermModal />
            </p>
            <p className="f-14 fw-400 text-primary">
              <PolicyModal />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
