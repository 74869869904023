import { fireToast } from "../../../../common/Toster";
import CorporateTopBar from "../TopBar/Topbar";
import { useEffect, useState } from "react";
import { BaseUrl } from "../../../../common/BaseUrl";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";
import { GoVerified } from "react-icons/go";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  changePassword,
  changePasswordCorporate,
} from "../../../../services/userServices";
import PasswordChecklist from "react-password-checklist";
import { TfiReload } from "react-icons/tfi";
import { ImFolderDownload } from "react-icons/im";

const CorporateSettings = () => {
  document.title = "Settings";
  const [domains, setDomains] = useState([""]); // Initial domain
  const [domain, setDomain] = useState([""]); // Initial domain
  const [isRotating, setIsRotating] = useState(false);

  const handleAddMore = () => {
    setDomains([...domains, ""]); // Add an empty domain
  };

  const handleCancel = (index) => {
    setDomains(domains.filter((_, i) => i !== index)); // Remove domain by index
  };

  const handleSubmit = () => {
    console.log("Submitted domains:", domains);
    const formattedDomains = domains
      .map((domain) => domain.trim())
      .filter((domain) => domain !== "");
    console.log("Formatted Domains:", formattedDomains);
    custom_domains(formattedDomains);
  };
  const localData = JSON.parse(localStorage.getItem("authUser"));
  const token = localData?.data?.token;
  const custom_domains = async (formattedDomains) => {
    try {
      const response = await axios.post(
        BaseUrl + `api/custom_domains/verification-file-settings`,
        {
          name: formattedDomains,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      fireToast("success", response?.data?.message);
      getdomains();
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  const getdomains = async () => {
    try {
      const response = await axios.get(
        BaseUrl + `api/custom_domains/mydomains`,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setDomain(response?.data?.data?.domains);
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  useEffect(() => {
    getdomains();
  }, []);

  const validation = useFormik({
    // enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("This Field is Required"),
      newPassword: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol")
        .required("This Field is Required"),
      confirm: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        'Must match "password" field value'
      ),
    }),
    onSubmit: async (values) => {
      try {
        const localData = JSON.parse(localStorage.getItem("authUser"));
        const token = localData?.token;
        const resetPassword = await changePasswordCorporate(values, token);
        if (resetPassword.status === 200) {
          fireToast("success", resetPassword.message);
        } else {
          throw new Error(resetPassword.error);
        }
      } catch (err) {
        fireToast("error", err.response?.data?.error || "An error occurred");
      }
    },
  });

  const verifyDomain = async (userId, domainName) => {
    setIsRotating(true);
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data.token;
      const response = await axios.get(
        BaseUrl +
          `api/custom_domains/verify-domain?user_id=${userId}&name=${domainName}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      fireToast("success", response?.message);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    } finally {
      // Stop rotating after 5 seconds
      setTimeout(() => {
        setIsRotating(false);
      }, 5000);
    }
  };
  const handleClick = () => {
    const userId = domain[0]?._id; // Set the userId value here
    const name = domain.map((val) => val.name); // Get the domain names
    const jsonData = {
      data: {
        userId: userId,
        name: name,
      },
    };
    const jsonString = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "index.json";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <>
      <CorporateTopBar />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <div className="d-flex">
          <div className="col-lg-6 mb-md-12">
            <h5 className="f-20 fw-500 mb-1">Change Password</h5>
            <p className="f-14 fw-400">You can manage your Password</p>
          </div>
          <div
            className="col-lg-6 mb-4 p-4 rounded"
            style={{ backgroundColor: "#F9F9F9" }}
          >
            <div className="mb-3">
              <label className="f-14 fw-400 mb-2">Old Password</label>
              <input
                name="oldPassword"
                className="form-control"
                placeholder="Enter Old Password"
                type="password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.oldPassword || ""}
              />
              {validation.touched.oldPassword &&
                validation.errors.oldPassword && (
                  <p className="text-danger">{validation.errors.oldPassword}</p>
                )}
            </div>
            <div className="col-12 mb-4">
              <label className="f-14 fw-400 mb-2">New Password</label>
              <input
                name="newPassword"
                className="form-control"
                placeholder="Enter New Password"
                type="password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.newPassword || ""}
              />
              {validation.values.newPassword && (
                <div className="commonIcon mt-2" id="passwordCheck">
                  <PasswordChecklist
                    className="text-xs d-flex w-100"
                    iconSize={8}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    rules={[
                      "capital",
                      "specialChar",
                      "minLength",
                      "lowercase",
                      "number",
                    ]}
                    minLength={8}
                    value={validation.values.newPassword}
                    messages={{
                      capital: "At least one uppercase letter (A-Z)",
                      specialChar:
                        "At least one special character (e.g., !, @, #, $, etc.)",
                      minLength: "At least 8 digits",
                      lowercase: "At least one lowercase letter (a-z)",
                      number: "At least one number (0-9)",
                    }}
                  />
                </div>
              )}
            </div>
            <div className="col-12 text-end">
              <button type="submit" className="common-btn profilesave w-100">
                Change Password
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* <hr /> */}
      {/* <div>
        <div className="row border-bottom mb-md-4">
          <div className="col-lg-6 mb-md-4 d-flex p-3">
            <div className="col-lg-6">
              <h5 className="f-20 fw-500 mb-1">Domain Listing</h5>
              <p className="f-14 fw-400">You can manage domain</p>
            </div>
            <div className="row">
              <div>
                <h5 className="f-20 fw-500 mb-1">Your Domain</h5>
                <p className="f-14 fw-400">Your existing domain</p>
                <ol>
                  {domain?.map((val) => (
                    <li style={{ listStyleType: "disk" }}>
                      {val?.name}{" "}
                      {val?.isVerified === true ? (
                        <GoVerified
                          className={
                            val?.isVerified === true
                              ? "text-success"
                              : "text-danger"
                          }
                        />
                      ) : (
                        <TfiReload
                          onClick={() => verifyDomain(val?._id, val?.name)}
                          className={`${
                            val?.isVerified === true
                              ? "text-success"
                              : "text-danger"
                          }${isRotating ? " rotate" : ""}`}
                        />
                      )}
                    </li>
                  ))}
                </ol>
                <div className="d-flex">
                  <button
                    className="d-flex common-btn mt-2"
                    onClick={handleClick}
                  >
                    <ImFolderDownload className="me-2" /> JSON
                  </button>
                  <button
                    className="d-flex common-btn mt-2 ms-2"
                    onClick={() => verifyDomain()}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 mb-4 p-4 rounded"
            style={{ backgroundColor: "#F9F9F9" }}
          >
            <div className="mt-4 change-pw">
              <div>
                <h2>Domain Listing</h2>
                <p>Enter your domain name</p>
                <ul>
                  {domains.map((domain, index) => (
                    <li key={index} className="my-2 d-flex">
                      <div style={{ width: "90%" }}>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="@sample.com"
                          value={domain}
                          onChange={(e) =>
                            setDomains(
                              domains.map((d, i) =>
                                i === index ? e.target.value : d
                              )
                            )
                          }
                        />
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "10%" }}
                      >
                        <span
                          className={`ms-2 ${
                            domains.length === 1 ? "disabled" : ""
                          }`}
                          onClick={() =>
                            domains.length > 1 && handleCancel(index)
                          }
                        >
                          <RxCross2
                            className="f-32"
                            style={{
                              border: "2px solid black",
                              borderRadius: "22px",
                            }}
                          />
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
                <button onClick={handleAddMore} className="me-3 common-btn ">
                  Add More
                </button>
                <button onClick={handleSubmit} className="common-btn ">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default CorporateSettings;
