import React, { useEffect, useState } from "react";
import "../../../topbar/TopBar.css";
import { FaSistrix } from "react-icons/fa6";
import { FaBell } from "react-icons/fa6";
import Dropdown from "react-bootstrap/Dropdown";
import { BiX } from "react-icons/bi";
import Bellicon from "../../../../assets/images/n-bell.svg";
import axios from "axios";
import { BaseUrl } from "../../../../common/BaseUrl";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import { MdAutoDelete } from "react-icons/md";
import Swal from "sweetalert2";
import NotificationDeleteModal from "../../../modals/NotificationDeleteModal";

const CorporateTopBar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [notification, setNotification] = useState("");
  const [read, setRead] = useState("");
  const [folderSearch, setFolderSearch] = useState("");
  const { pathname } = useLocation();
  const pathParts = pathname.split("/");
  const lastPart = pathParts[1];

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    getNotification(unread);
    // search();
  }, []);

  const getNotification = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data?.token;
      const response = await axios.get(
        BaseUrl + "api/company/myNotifications",
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setNotification(response?.data?.data?.all);
      setRead(response?.data?.data?.unread);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      if (error?.response?.data?.error === "Session Expired!") {
        localStorage.clear();
        window.location.href = "/login"; 
      }
    }
  };

  const unread = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data?.token;
      const response = await axios.get(
        BaseUrl + `api/company/readNotifications`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      getNotification();
      handleCloseDropdown();
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleDashboard = async (pageNumber) => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data?.token;
      const response = await axios.get(
        BaseUrl +
          `api/files/dashboard?search=${folderSearch}&pageNumber=1&pageLimit=10`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response) {
        // setDashboard(response?.data?.data?.data);
      }
    } catch (error) {
      console.error("API Error:", error);
      // fireToast("error", error?.response?.data?.error);
    }
  };

  useEffect(() => {
    handleDashboard();
  }, [folderSearch]);

  return (
    <>
      <div className="page-top-search mb-4">
        <div className="row">
          <div className="col-md-6 mb-md-0 mb-4">
            {/* <h3 className="f-28 text-capitalize">
              {window.location.pathname.split("/").pop() == ""
                ? "Dashboard"
                : window.location.pathname.split("/").pop()}
            </h3> */}
            <h3 className="f-28 text-capitalize">
            {pathname
                .replace("/corporate-", "")
                .split("/")
                .pop()
                .replace(/%20/g, " ")}
            </h3>
          </div>
          <div className="col-md-6 d-md-flex justify-content-end align-items-center">
            {window.location.pathname
              .replace("/corporate-", "")
              .split("/")
              .pop() === "dashboard" && (
              <div className="search-block position-relative">
                <div className="d-flex align-items-center">
                  <input
                    className="form-control ms-auto"
                    type="text"
                    value={folderSearch}
                    onChange={(e) => setFolderSearch(e.target.value)}
                    placeholder="Search domain and receiver name"
                  />
                </div>
              </div>
            )}
            <div className="notify-bell ms-4 d-lg-block d-none">
              <Dropdown show={showDropdown} onToggle={setShowDropdown}>
                <Dropdown.Toggle
                  className="position-relative bg-transparent border-0 w-auto after-none p-0 text-black"
                  id="f-option"
                >
                  <FaBell />
                  <span
                    className={`notify-count position-absolute ${
                      read === 0 && "d-none"
                    }`}
                  >
                    {read}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="notification-block f-14">
                  <div className="d-flex align-items-center justify-content-between p-3">
                    <h5 className="notify-title">Notifications</h5>{" "}
                    <span>
                      <NotificationDeleteModal
                        getNotification={() => getNotification()}
                        handleCloseDropdown={() => {
                          handleCloseDropdown();
                        }}
                      />
                      <button
                        className="bg-transparent"
                        onClick={handleCloseDropdown}
                      >
                        <BiX />
                      </button>
                    </span>
                  </div>
                  <div className="notification-wrapper">
                    {notification &&
                      notification?.map((notifications) => (
                        <div
                          className={`noify-block position-relative d-flex align-items-start justify-content-between ${
                            notifications?.isSeen !== true &&
                            "unreadnotifications"
                          }`}
                        >
                          <img
                            className="n-bell position-absolute"
                            src={Bellicon}
                            alt=""
                          />
                          <div className="notify-box">
                            <p className="f-14">{notifications?.body}</p>
                          </div>
                          <div className="status-time f-10">
                            {moment(notifications?.createdAt).format("lll")}
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="text-center py-3">
                    <button className="common-btn" onClick={unread}>
                      Mark all as read
                    </button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CorporateTopBar;
