import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./layout/Layout";
import CorporateLayout from "./layout/CorporateLayout/Layout";
import Login from "./components/auth/Login";
import Dashboard from "./components/pages/Dashboard";
import CorporateDashboard from "./components/pages/Corporate/Dashboard/Dashboard";
import EditProfile from "./components/pages/EditProfile";
import Settings from "./components/pages/Settings";
import SignIn from "./components/auth/SignIn";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import HelpSupport from "./components/pages/HelpSupport";
import { useEffect } from "react";
import { apiHeader } from "./utils/api";
import Notification from "./firebaseNotifications/Notification";
import VerifySignup from "./components/auth/VerifySignup";
import VerifiedEmail from "./components/auth/VerifiedEmail";
import VerifyOtp from "./components/auth/VerifyOtp";
import SharedPage from "./components/pages/SharedPage";
import LandingPage from "./components/pages/Landing";
import PrivacyPolicy from "./components/pages/privacy/PrivacyPolicy";
import TermsConditoin from "./components/pages/Landing/terms/TermsConditoin";
import VerifyPage from "./components/pages/VerifyPage/VerifyPage";
import SharedFolder from "./components/pages/SharedFolder";
import VerifyDocPage from "./components/pages/VerifyPage/VerifyDocPage";
import ContactUs from "./components/pages/Landing/ContactUs";
import Logincompany from "./components/auth/Login-company";
import SignUpCompany from "./components/auth/SignUpCompanyRepresentative";
import SignUpCompanyRepresentative from "./components/auth/SignUpCompany copy";
import VerifyCorporateOtp from "./components/auth/VerifyCorporateOtp";
import DomainListing from "./components/auth/DomainListing";
import VerifyYourDomain from "./components/auth/Verify your domain/VerifyYourDomain";
import LoginPwdcompany from "./components/auth/Login-Pwd-company";
import CorporateUser from "./components/pages/Corporate/User/Corporate-User";
import CorporateProfile from "./components/pages/Corporate/CorporateProfile/CorporateProfile";
import HelpCenter from "./components/pages/Corporate/Help-center/HelpCenter";
import CorporateSettings from "./components/pages/Corporate/Setting/CorporateSetting";
import TwoFactorAuth from "./components/auth/TwoFactorAuth";
import CorporateAddUser from "./components/pages/Corporate/AddUser/AppUser";
import ForgetCompany from "./components/auth/Forget-company";
import VerifyCorporateforgotOtp from "./components/auth/VerifyCorporateforgotOtp";
import ResetCompanyPassword from "./components/auth/ResetPwd";
import DeviceDetect from "./components/DeviceDecect/DeviceDetect";
import Mydomains from "./components/pages/Corporate/Mydomain/Mydomains";
import AddMyDomain from "./components/pages/Corporate/Mydomain/AddMyDomains";

function App() {
  document.title = "U-Vault";
  useEffect(() => {
    apiHeader();
  }, [window?.location?.pathname]);
// console.clear("")
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/terms-conditions" element={<TermsConditoin />}></Route>
          <Route path="/contactus" element={<ContactUs />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/sign-up" element={<SignIn />}></Route>
          {/* corporate  */}
          <Route path="/login-company" element={<Logincompany />}></Route>
          <Route path="/LoginPwdcompany" element={<LoginPwdcompany />}></Route>
          <Route path="/forgot-password-corporate" element={<ForgetCompany />}></Route>
          <Route path="/sign-up-company" element={<SignUpCompany />}></Route>
          <Route path="/sign-up-company-Representative-Details" element={<SignUpCompanyRepresentative />}></Route>
          <Route path="/u-vault-application" element={<DeviceDetect />}></Route>
          <Route path="/company-verify" element={<VerifyCorporateOtp />}></Route>  
          <Route path="/Verify-forgot-pwd" element={<VerifyCorporateforgotOtp />}></Route>
          <Route path="/reset_password" element={<ResetCompanyPassword />}></Route>
          <Route path="/domainlisting" element={<DomainListing />}></Route>
          <Route path="/VerifyYourDomain" element={<VerifyYourDomain />}></Route>
          <Route path="/" element={<CorporateLayout />}>
          <Route path="/corporate-dashboard" element={<CorporateDashboard />}></Route>
          <Route path="/corporate-user" element={<CorporateUser />}></Route>
          <Route path="/corporate-edit-profile" element={<CorporateProfile />}></Route>
          <Route path="/corporate-settings" element={<CorporateSettings />}></Route>
          <Route path="/my domain" element={<Mydomains />}></Route>
          <Route path="/Add My Domain" element={<AddMyDomain />}></Route>
          <Route path="/Add My Domain/:domainName" element={<AddMyDomain />}></Route>
          <Route path="/help-support" element={<HelpCenter />}></Route>
          </Route>
          <Route path="/corporate-verify-email" element={<CorporateAddUser />}></Route>
          <Route path="/two-factor-auth" element={<TwoFactorAuth />}></Route>
          {/* corporate  */}
          <Route path="/sign-up/:email" element={<SignIn />}></Route>
          <Route path="/verify-signup" element={<VerifySignup />}></Route>
          <Route path="/verify-email" element={<VerifiedEmail />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="/verify-otp" element={<VerifyOtp />}></Route>
          <Route path="/shared/:id" element={<SharedPage />}></Route>
          <Route
            path="/shared-folder/:id/:folderid/"
            element={<SharedFolder />}
          ></Route>
          <Route path="/doc/:id" element={<VerifyPage />}></Route>
          <Route path="/doc/verify/:id" element={<VerifyDocPage />}></Route>
          <Route path="/" element={<Layout />}>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/edit-profile" element={<EditProfile />}></Route>
            <Route path="/settings" element={<Settings />}></Route>
            <Route path="/help-support" element={<HelpSupport />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
      <Notification />
    </>
  );
}

export default App;
