import axios from "axios";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { fireToast } from "../../common/Toster";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { BaseUrl } from "../../common/BaseUrl";
// import Logo from "../../assets/images/u-vault.svg";
import downloadFolder from "../../assets/images/downloadfolder.png";

import Logo from "../../assets/images/u-vault-logo-white.svg";
import auth from "../../assets/images/auth.webp";
import { Header } from "./Landing/header/Header";
import { HeaderPublic } from "./Landing/header/Header copy";

const SharedPage = () => {
  const { id } = useParams();
  const sharedId = id.slice(3);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordChecking, setPasswordChecking] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  const [files, setFiles] = useState([]);

  useEffect(() => {
    passwordCheck();
  }, []);

  const passwordCheck = async () => {
    try {
      const response = await axios.get(
        BaseUrl + `api/file-shares/passwordExists?fileShareId=${sharedId}`
      );
      setPasswordChecking(response?.data?.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchFileShare = async (e) => {
    e.preventDefault();

    try {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      let response;

      if (passwordChecking === true) {
        response = await axios.post(
          BaseUrl + `api/file-shares/view/${sharedId}`,
          {
            email: email,
            password: password,
          }
        );
      } else {
        response = await axios.post(
          BaseUrl + `api/file-shares/view/${sharedId}`,
          {
            email: email,
          }
        );
      }

      if (response) {
        setFiles(response?.data?.data);
        setIsLoggedIn(true);
      }
    } catch (error) {
      console.error("API Error:", error);
      console.log("Full error object:", error);
      fireToast("error", "Enter credentials again!");
    }
  };

  const renderFileContent = () => {
    const fileExtension = files?.file_format;
    console.log(fileExtension, "fielesdfsdfds");
    if (!fileExtension) {
      console.error("Invalid file data");
      return null;
    }

    if ([".pdf"].includes(fileExtension)) {
      return (
        <>
          <iframe
            title="PDF Viewer"
            width="100%"
            height="900"
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(
              files?.fileLink
            )}&embedded=true`}
            frameBorder="0"
            allowFullScreen
          />
          {files?.download_retrict === true && (
            <a href={files?.fileLink} download="" class="downloadLinkfloat">
              Download
            </a>
          )}
        </>
      );
    } else if (
      [
        ".doc",
        ".docx",
        ".txt",
        ".csv",
        ".xml",
        ".pptx",
        ".ppt",
        ".xlsx",
        ".xls",
        ".zip",
      ].includes(fileExtension)
    ) {
      return (
        <div class="downloadLinkDiv d-flex justify-content-center text-center">
          {files?.download_retrict === true ? (
            <>
              <h4>
                We're currently unable to open this file on our platform. We're
                working on a fix but in the meantime, you can download it from
                the below.
              </h4>
              <div>
                <a href={files?.fileLink} download="" class="downloadLink">
                  Download
                </a>
              </div>
            </>
          ) : (
            <>
              <h4>
                We can't open the file on our platform right now. Our team is
                fixing it. Unfortunately, downloading isn't possible as the
                sender has disabled it.
              </h4>
              <div>
                <a class="downloadLink">Disabled</a>
              </div>
            </>
          )}
        </div>
      );
    } else if ([".png", ".jpg", ".gif", ".jpeg"].includes(fileExtension)) {
      return (
        <>
          <img src={files?.fileLink} alt="" />
          {files?.download_retrict === true && (
            <a href={files?.fileLink} download="" className="downloadLinkfloat">
              Download
            </a>
          )}
        </>
      );
    } else if ([".mp4", ".mkv"].includes(fileExtension)) {
      return (
        <>
          <video width="100%" height="900" controls>
            <source src={files?.fileLink} type="video/mp4" />
          </video>
          {files?.download_retrict === true && (
            <a href={files?.fileLink} download="" className="downloadLinkfloat">
              Download
            </a>
          )}
        </>
      );
    }
    console.error("Unsupported file type");
    return null;
  };

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const token = authUser ? authUser.token : null;

  return (
    <>
      <div>
        <Row>
          <div>
            {isLoggedIn ? (
              <>
                <HeaderPublic />
                {renderFileContent()}
              </>
            ) : (
              <>
                <div className="mxh-100 d-md-flex position-relative">
                  <div className="row w-100">
                    <div className=" auth-wrapper user-login auth-wrapper-inner d-flex align-items-center justify-content-center mxh-100 col-md-6 col-sm-12">
                      <Link className="site-logo" to="/">
                        <img src={Logo} alt="" />
                      </Link>
                      <div className="AuthIms">
                        <img src={auth} alt="auth" />
                      </div>
                      <div className="row w-100">
                        <div className="Welcome">
                          <h1 className="text-white f-48">Restricted Access</h1>
                          <p className="text-white f-32">
                            Enter your email and the password you received to
                            access this file.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="auth-right d-flex align-items-center justify-content-center mxh-100 col-md-6 col-sm-12 bg-white">
                      <form className="FormStyle">
                        <h2 className="mb-4 f-40">Enter Credentials</h2>
                        <Form>
                          <Form.Group controlId="formEmail">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Form.Group>
                          {passwordChecking === true && (
                            <Form.Group
                              controlId="formPassword"
                              className="mt-3"
                            >
                              <Form.Label>Password:</Form.Label>
                              <Form.Control
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </Form.Group>
                          )}
                          <button
                            onClick={fetchFileShare}
                            className="common-btn w-100 my-3"
                          >
                            Submit
                          </button>
                        </Form>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Row>
      </div>
    </>
  );
};

export default SharedPage;
