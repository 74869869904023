import React from 'react'
import { Link } from 'react-router-dom'
import { BsFillPatchCheckFill } from "react-icons/bs";
import Logo from '../../assets/images/u-vault.svg'

const VerifiedEmail = () => {
    //meta title
  document.title = "Verify Email";
    return (
        <>
            <div className="auth-wrapper mxh-100 d-md-flex position-relative py-5">
                <Link className='site-logo d-lg-block d-none' to="/login">
                    <img src={Logo} alt="" />
                </Link>
                <div className="w-100 d-flex align-items-center justify-content-center">
                    <div className="row w-100">
                        <div className="col-md-6 offset-md-3 col-lg-6 offset-lg-3 mx-auto">
                            <form className='row' action="">
                                <div className="col-12 mb-5 d-lg-none d-block">
                                    <Link className='logo' to="/">
                                        <img src={Logo} alt="" />
                                    </Link>
                                </div>
                                <div className="col-12 text-center">
                                    <BsFillPatchCheckFill className='f-100 t-color' />
                                    <h2 className='mb-2 mt-4 f-32'>Verified your Email.</h2>
                                    <p className='f-20 mb-lg-5 mb-4'>Please click on below button to continue.</p>
                                    <div className="">
                                        <Link className='common-btn d-block mx-auto pointer' style={{ width:'200px' }} to="/login">Login</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifiedEmail