import './Modals.css'
import { useEffect, useState } from 'react';
import { FaSistrix } from "react-icons/fa6";
import { BsDot } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';
import Photo from '../../assets/images/photo.png'

const PrivacyModal = ({ privacyInfo, setPrivacyInfo }) => {

  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false); setPrivacyInfo(false)}

  useEffect(() => {
    setShow(privacyInfo)
  }, [privacyInfo])

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className='border-0'>
          <div>
            <Modal.Title className='f-20 fw-500'>Move file</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className='privacy-block f-14 text-black pt-0'>
        <p className='mb-1'> U-vault drive Privacy Policy</p>
        <p>We respect your privacy and are committed to protecting your data. This privacy policy explains what data we collect, how we use it, and how we protect it.</p>
        <p>Data We Collect</p>
        <p>We collect the following data from you:</p>
        <ul>
          <li>Account information, such as your name, email address, and password</li>
          <li>Usage data, such as the files you store and access on U-vault drive</li>
          <li>Device information, such as the type of device you are using and your IP address</li>
        </ul>                   
        <p>How We Use Your Data</p>
        <p>We use your data to provide and improve the U-vault driveservice. We also use your data to:</p>
        <ul>
          <li>Communicate with you about your account and the U-vault driveservice</li>
          <li>Send you marketing and promotional materials (if you have opted in to receive them)</li>
          <li>Improve our security measures</li>
          <li>Comply with legal requirements</li>
        </ul>
        <p>How We Protect Your Data</p>
        <p>We use a variety of security measures to protect your data, including:</p>
        <ul>
          <li>Encryption: We encrypt all data at rest and in transit.</li>
          <li>Access control: We limit access to your data to authorized personnel.</li>
          <li>Security monitoring: We monitor our systems for security threats and take action to mitigate those threats.</li>
        </ul>
        <p>Your Choices</p>
        <p>You have the following choices about your data:</p>
        
        <ul>
          <li>You can access and manage your data through the U-vault drivewebsite.</li>
          <li>You can opt out of receiving marketing and promotional materials from us.</li>
          <li>You can delete your account and all of your data at any time.</li>
        </ul>
        <p>Changes to This Privacy Policy</p>
        <p>We may update this privacy policy from time to time. If we make any significant changes, we will notify you by email or through a notice on the U-vault drivewebsite.</p>
        <p>Contact Us</p>
        <p>If you have any questions about this privacy policy, please contact us at [email protected]</p>
        <p>Your Consent</p>
        <p>By using U-vault drive, you consent to the collection, use, and disclosure of your data as described in this privacy policy.</p>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PrivacyModal