// import { get, post, postAsForm, put } from "helpers/api_helper.js";
import { get, post, postAsForm, put, deleteF, putAsForm, patch } from "../utils/api";
import appendData from "../utils/formData";

export const userLogin = (data) => {
  return post("/api/user/login", data);
};

export const userSignup = (data) => {
  return post("/api/user/sign-up", data);
};

export const forgotPassword = (data) => {
  return post("/api/user/forgotPassword", data);
};

export const verifyOtp = (data) => {
  return post("/api/user/verifyOtp", data);
};

export const resendOtp = (data) => {
  return post("/api/user/resendotp", data);
};

// Reset Password
export const resetPassword = (new_password, token) => {
  return post(
    "/api/user/resetPassword",
    { new_password },
    {
      headers: { Authorization: token },
    }
  );
};

// Change Password API Code
export const changePassword = (values) => {
  let token = JSON.parse(localStorage.getItem("authUser")).token;
  return post("/api/user/changePassword", values, {
    headers: { Authorization: token },
  });
};

// Logout Api Code
export const logOut = (userId) => {
  let token = JSON.parse(localStorage.getItem("authUser")).token;
  return get(`/api/user/logout?userId=${userId}`, {
    headers: { Authorization: token },
  });
};

export const updateProfile = (values) => {
  const formData = appendData(values);
  return putAsForm("/api/user/updateMyProfile", formData);
};

export const helpAndSupport = (values) => {
  let token = JSON.parse(localStorage.getItem("authUser")).token;
  return post("/api/user/helpAndSupport", values, {
    headers: { Authorization: token },
  });
};

// myRecentFiles Api Code
export const getMyRecentFiles = () => {
  let token = JSON.parse(localStorage.getItem("authUser")).token;
  return get(`/api/folder/myRecentFiles`,  {
    headers: { Authorization: token },
  });
};

export const getCreateFolder = (data) => {
  let token = JSON.parse(localStorage.getItem("authUser")).token;

  return post("/api/folder/createFolder", data,  {
    headers: { Authorization: token },
  });
};

export const getMyFolder = () => {
  let token = JSON.parse(localStorage.getItem("authUser")).token;
  return get(`/api/folder/myFoldersNew`,  {
    headers: { Authorization: token },
  });
};

export const deleteFolder = (data) => {
  return deleteF("/api/folder/deleteFolder", data);
};

// Content Api Code
export const getTermsConditions = () => {
  return get(`api/user/content?type=termsAndConditions`,  {
  });
};

export const getPrivacyContent = () => {
  return get(`api/user/content?type=privacyPolicy`,  {
  });
};

//company

export const changePasswordCorporate = (values) => {
  let token = JSON.parse(localStorage.getItem("authUser")).data?.token;
  return post("api/company/changePassword", values, {
    headers: { Authorization: token },
  });
};

export const readNotifications = () => {
  let token = JSON.parse(localStorage.getItem("authUser")).data?.token;
  return get("api/company/readNotifications", {
    headers: { Authorization: token },
  });
};
export const myNotifications = () => {
  let token = JSON.parse(localStorage.getItem("authUser")).data?.token;
  return get("api/company/myNotifications", {
    headers: { Authorization: token },
  });
};

export const delNotifications = () => {
  let token = JSON.parse(localStorage.getItem("authUser")).data?.token;
  return deleteF("api/company/delNotifications", {
    headers: { Authorization: token },
  });
};
export const verifyDomain = (currentPage) => {
  let token = JSON.parse(localStorage.getItem("authUser")).data?.token;
  return get(`api/domains?page=${currentPage}`, {
    headers: { Authorization: token },
  });
};

export const getDomainById = (id) => {
  let token = JSON.parse(localStorage.getItem("authUser")).data?.token;
  return get(`api/domains?id=${id}`, {
    headers: { Authorization: token },
  });
};



export const domainSubmit = (data) => {
  let token = JSON.parse(localStorage.getItem("authUser")).data?.token;
  return post("api/domains",data,{
    headers: { Authorization: token },
  });
};
export const verifyRecord = (Id) => {
  let token = JSON.parse(localStorage.getItem("authUser")).data?.token;
  return patch(`api/domains/${Id}/verify-record`,{},{
    headers: { Authorization: token },
  });
};
export const DeleteDomain = (Id) => {
  let token = JSON.parse(localStorage.getItem("authUser")).data?.token;
  return deleteF(`api/domains/${Id}`,{
    headers: { Authorization: token },
  });
};