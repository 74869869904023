import React, { useState, useEffect } from 'react'
import { Header } from '../header/Header'
import { Footer } from '../footer/Footer'
import { Col, Container, Row } from 'react-bootstrap'
import { getTermsConditions } from '../../../../services/userServices';

const TermsConditoin = () => {
  const [content, setContent] = useState([])
  const fetchData = async () => {
    try {
      const resp = await getTermsConditions();
      // console.log(resp, "resp");
      if (resp.status === 200) {
        console.log(resp, "testing ");
        setContent(resp.data.content);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  return (
    <>
      <Header />
      <Container>
        <Row className='py-5'>
          <Col >

            <div dangerouslySetInnerHTML={{ __html: content }}></div>

          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default TermsConditoin