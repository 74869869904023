import React, { useState } from "react";
import { HeaderPublic } from "./header/Header copy";
import { Button, Container, Row } from "react-bootstrap";
import { Footer } from "./footer/Footer";
import { BaseUrl } from "../../../common/BaseUrl";
import axios from "axios";
import contact_img from "../../../assets/images/contact-img.png";
import { fireToast } from "../../../common/Toster";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(BaseUrl + "api/user/contactUs", {
        name: formData.name,
        email: formData.email,
        message: formData.message,
      });
      if (response) {
        // Handle success, e.g., show a success message
        console.log("Contact form submitted successfully");
        fireToast("success", response?.data?.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  return (
    <div>
      <HeaderPublic />
        <div className="contact-element position-relative">      
      <Container>
        <Row>
        <div className="col-md-12 text-center pb-5"><h2 className="f-49 fw-bold ">Contact Us</h2>
        <p>Any question or remarks? We'll love to hear from you</p></div>
          <div className="col-md-5 mb-4" >
            <div className="shadow rounded-2 p-4">
              <div className="form-group mb-4">              
              <label className="f-14 fw-bold mb-2">Name</label>
              <input
                name="name"
                className="form-control"
                placeholder="Please enter your name, 80 character limit."
                value={formData.name}
                onChange={handleChange}
              />
              </div>
              <div className="form-group mb-4">     
              <label className="f-14 fw-bold mb-2">Email</label>
              <input
                name="email"
                className="form-control"
                placeholder="Enter your email here..."
                value={formData.email}
                onChange={handleChange}
                maxLength={80}
              />
              </div>
              <div className="form-group mb-4">     
              <label className="f-14 fw-bold mb-2">Message</label>
              <textarea
                name="message"
                className="form-control"
                placeholder="Enter your message here, 360 character limit."
                value={formData.message}
                onChange={handleChange}
                maxLength={360}
              />
              </div>
            <Button onClick={handleSubmit} className="bg-default w-100 py-3 btn-default border-0">Send</Button>
            </div>
          </div>
                    
            <div className="col-md-6 offset-md-1 position-relative content-area">
              <div className="mb-4">
                <h4>Step 1: Share your name:</h4>
                <div className="d-flex contact-step align-items-center">
                  <div className="contact-number">
                    <span>01</span>
                  </div>
                  <div className="contact-content ps-4">
                    <p>
                    How would you like us to address you </p>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <h4>Step 2: Email:</h4>
                <div className="d-flex contact-step align-items-center">
                  <div className="contact-number">
                    <span>02</span>
                  </div>
                  <div className="contact-content ps-4">
                    <p>Share an email that we can reach you on and expect a <br/> response from our support team <a href="mailto:support@trailant.com">(support@trailant.com)</a>.</p>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <h4>Step 3: Click send</h4>
                <div className="d-flex contact-step align-items-center">
                  <div className="contact-number">
                    <span>03</span>
                  </div>
                  <div className="contact-content ps-4">
                    <p>
                    We'll be in touch
                    </p>
                  </div>
                </div>
              </div>
              <figure className="mb-0 position-absolute figure-box text-end">
                <img src={contact_img} className="img-fluid" alt="" />
              </figure>
            </div>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
