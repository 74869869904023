import axios from "axios";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { fireToast } from "../../common/Toster";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { BaseUrl } from "../../common/BaseUrl";
import Logo from "../../assets/images/u-vault.svg";
import auth from "../../assets/images/auth.webp";
import { FaFolderMinus } from "react-icons/fa6";
import moment from "moment";
import Photo from "../../assets/images/photo.png";
import Video from "../../assets/images/video.png";
import Folder from "../../assets/images/folder.png";
import File from "../../assets/images/file.png";
import FileSharedOpen from "../folder-sidebar/FilesharedOpen";
import { Header } from "./Landing/header/Header";
import { HeaderPublic } from "./Landing/header/Header copy";
// http://localhost:3000/shared-folder/id:658d795ff025dc5e7e68b25a/folderid:658bce1f5b80d40172b8a226

const SharedFolder = () => {
  const { id } = useParams();
  const { folderid } = useParams();
  const sharedfolderid = folderid.slice(9);
  const sharedId = id.slice(3);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  const [files, setFiles] = useState([]);
  const sharedDoc = JSON?.parse(localStorage?.getItem("sharedDoc"));
  const [folderFiles, setFolderFiles] = useState([]);
  const [fileOpen, setFileOpen] = useState("");
  const [openfileData, setOpenfileData] = useState("");
  const [canvasModalStatus, setCanvasModalStatus] = useState(false);
  const [passwordChecking, setPasswordChecking] = useState("");

  console.log(fileOpen?._id, "sharedFolderId");
  const fetchFolderShare = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        BaseUrl + `api/folder-shares/view/${sharedId}`,
        {
          email: email,
          password: password,
          folderId: sharedfolderid,
        }
      );

      if (response) {
        setFiles([response?.data?.data?.getAllFiles]);
        setIsLoggedIn(true);
        localStorage?.setItem("sharedDoc", JSON.stringify({ email, password }));
      }
    } catch (error) {
      console.error("API Error:", error);
      console.log("Full error object:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  const fileopenn = async (e) => {
    try {
      const response = await axios.post(
        BaseUrl + `api/folder-shares/view/${sharedId}`,
        {
          email: sharedDoc?.email,
          password: sharedDoc?.password,
          fileId: openfileData,
        }
      );

      if (response) {
        setFileOpen(response?.data?.data);
        setIsLoggedIn(true);
      }
    } catch (error) {
      console.error("API Error:", error);
      console.log("Full error object:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };
  const regex = /([^/]+)$/;

  useEffect(() => {
    passwordCheck();
  }, []);

  const passwordCheck = async () => {
    try {
      const response = await axios.get(
        BaseUrl + `api/folder-shares/passwordExists?folderShareId=${sharedId}`
      );
      setPasswordChecking(response?.data?.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  return (
    <>
      <FileSharedOpen
        openfile={fileOpen}
        canvasModalStatus={canvasModalStatus}
        setCanvasModalStatus={setCanvasModalStatus}
      />
      <div>
        <Row>
          <div>
            {isLoggedIn ? (
              <>
                <div className="page-top-search mb-lg-5 mb-4">
                  <div className="row">
                    <div className=" mb-md-0 mb-4 py-2 px-5">
                      <Link className="" to="/">
                        <img src={Logo} alt="" />
                      </Link>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end align-items-center"></div>
                  </div>
                </div>
                <div className={`col-xxl-4 col-sm-6 mb-4 order-1 p-3`}>
                  {files &&
                    files.map((value, index) => {
                      return (
                        <div
                          className="folder-block"
                          key={index}
                          onClick={() => {
                            setFiles(value?.children);
                            setFolderFiles(value?.files);
                          }}
                        >
                          <div className="folder-body d-flex align-items-center justify-content-between p-xl-3 px-2 py-3">
                            <div className="pointer">
                              <span className="folder-icon align-middle">
                                <FaFolderMinus />
                              </span>
                              <div className="d-inline-block align-middle ps-xl-4 ps-md-3 ps-2">
                                <p className="f-16 mb-1 position-relative">
                                  <span>
                                    {value?.folderPath?.match(regex)?.[0]}
                                  </span>{" "}
                                </p>
                                <p className="f-14">
                                  {moment(value?.LastModified).format("ll")}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="folder-footer d-flex align-items-center justify-content-between px-lg-4 px-3 py-3 mt-lg-3">
                            <div className="">
                              <strong className="f-12">Total Documents</strong>
                              <span className="f-12 ps-2">{files?.length}</span>
                            </div>
                            <div className="">
                              <strong className="f-12">Storage</strong>
                              {/* <span className="f-12 ps-2">{val.totalSize}</span> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="table-responsive">
                  {folderFiles &&
                    folderFiles.map((file, ind) => {
                      return (
                        <div className="recent-data table-responsive" key={ind}>
                          <h3 className="f-20 mb-lg-5 mt-lg-4 mb-4">
                            Document details
                          </h3>
                          <table className="w-100">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Received On</th>
                                <th>Document Size</th>
                                <th>Last Modified</th>
                                <th>Issuer</th>
                                <th>Document ID</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                className="pe-auto"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  fileopenn();
                                  setOpenfileData(file?._id);
                                  setCanvasModalStatus(true);
                                }}
                              >
                                <td>
                                  <div className="d-flex align-items-center">
                                    <span className="file-icon">
                                      <img
                                        src={
                                          [
                                            ".png",
                                            ".jpg",
                                            ".gif",
                                            ".jpeg",
                                          ].includes(file?.extension)
                                            ? Photo
                                            : [".mp4", ".mkv"].includes(
                                                file?.extension
                                              )
                                            ? Video
                                            : [".mp3"].includes(file?.extension)
                                            ? ""
                                            : [
                                                ".doc",
                                                ".docx",
                                                ".txt",
                                                ".csv",
                                                ".xml",
                                                ".pdf",
                                              ].includes(file?.extension)
                                            ? File
                                            : Folder
                                        }
                                        alt="ssss"
                                      />
                                    </span>
                                    <span className="file-name f-14 t-color fw-500">
                                      {file?.name}
                                    </span>
                                  </div>
                                </td>
                                <td>{moment(file?.createdAt).format("ll")}</td>
                                <td>{file?.formated_size}</td>{" "}
                                <td>{moment(file?.updatedAt).format("ll")}</td>
                                <td>{file?.metadata?.SourceDomain}</td>
                                <td>{file?.metadata?.VaultId} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      );
                    })}
                </div>
              </>
            ) : (
              <>
                <div className="mxh-100 d-md-flex position-relative">
                  <div className="row w-100">
                    <div className=" auth-wrapper user-login auth-wrapper-inner d-flex align-items-center justify-content-center mxh-100 col-md-6 col-sm-12">
                      <Link className="site-logo" to="/">
                        <img src={Logo} alt="" />
                      </Link>
                      <div className="AuthIms">
                        <img src={auth} alt="auth" />
                      </div>
                      <div className="row w-100">
                        <div className="Welcome">
                          <h1 className="text-white f-48">Restricted Access</h1>
                          <p className="text-white f-32">
                            {" "}
                            Enter your email and the password you received to
                            access this file .
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="auth-right d-flex align-items-center justify-content-center mxh-100 col-6 bg-white">
                      <div className="row w-100">
                        <div className="col-md-8 offset-md-2 col-xxl-6 offset-xxl-3">
                          <form className="FormStyle">
                            <h2 className="mb-4 f-40">Enter Credentials</h2>
                            <Form>
                              <Form.Group controlId="formEmail">
                                <Form.Label>Email:</Form.Label>
                                <Form.Control
                                  type="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </Form.Group>
                              {passwordChecking === true && (
                                <Form.Group
                                  controlId="formPassword"
                                  className="mt-3"
                                >
                                  <Form.Label>Password:</Form.Label>
                                  <Form.Control
                                    type="password"
                                    value={password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              )}
                              <button
                                onClick={fetchFolderShare}
                                className="common-btn w-100 my-3"
                              >
                                Submit
                              </button>
                              <p className="f-14">
                                Don’t have an account?{" "}
                                <Link to="/sign-up" className="fw-700">
                                  Register here
                                </Link>
                              </p>
                            </Form>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Row>
      </div>
    </>
  );
};

export default SharedFolder;
