import React, { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/u-vault-logo.svg";
import "react-phone-input-2/lib/bootstrap.css";
import auth from "../../assets/images/auth.webp";
import form_img from "../../assets/images/signup-banner.png";
import form_imgg from "../../assets/images/girl-sit.png";
import countriesData from "../../services/countries.json";
import { Dropdown } from "react-bootstrap";

const SignUpCompany = () => {
  document.title = "SignUp";
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    numberOfEmployees: "",
    companyName: "",
    industryType: "",
    companyAddress: "",
    Country: "",
    State: "",
    zipCode: "",
    legal_structure: "",
    tradeLicenseNo: "",
    dateOfEstablishment: "",
  });

  const handleChange = (e, fieldName) => {
    setFormData({ ...formData, [fieldName]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Save form data to local storage
    localStorage.setItem("companyFormData", JSON.stringify(formData));
    // Redirect or perform other actions
    navigate("/sign-up-company-Representative-Details");
  };

  const handleCountryChange = (e) => {
    setFormData({
      ...formData,
      Country: e.target.value,
    });
  };

  // Convert the countries object into an array of objects
  const countryOptions = Object.entries(countriesData).map(
    ([code, country]) => ({
      value: code,
      label: `${country.emoji} ${country.name}`,
      image: country.image,
    })
  );


  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const onlyNumbers = /^[0-9]*$/;
    if (onlyNumbers.test(inputValue) || inputValue === '') {
      setFormData({
        ...formData,
        zipCode: inputValue,
      });
    }
  };
  console.log(countryOptions,"countryOptions");
  return (
    <>
      <div className="auth-wrapper mxh-100 d-md-flex position-relative">
        <div className="mxh-100 w-100 d-md-flex position-relative">
          <div formattedValue className="row  w-100">
            <div className=" auth-wrapper-inner lamp-box d-flex align-items-center justify-content-center col-md-4 col-sm-12">
              <Link className="site-logoo" to="/">
                <img src={Logo} alt="" />
              </Link>
              <div className="d-flex align-items-end h-100">
                <img src={form_img} alt="auth" />
              </div>
              {/* <div className="row w-100">
                <div className="Welcome">
                  <h1 className="text-white f-48">Get vaulted!</h1>
                  <p className="text-white f-32">Your journey starts here…</p>
                </div>
              </div> */}
            </div>
            <div className="col-md-8 col-sm-12 d-flex p-4 rounded-4 FormDiv lamp-box">
              <form className="row FormStyle auth-form" onSubmit={handleSubmit}>
                <div className="col-md-12">
                  <h2 className="mb-3 f-36">Company Details</h2>
                  <div className="row">
                    <div className="col-lg-6 mb-1">
                      <label className="f-14 mb-2" htmlFor="cars">
                        <p>Number of Employees</p>
                      </label>
                      <select
                        style={{
                          padding: "10px",
                          borderRadius: "6px",backgroundColor: "white", border: "1px solid #a8a8a8"
                        }}
                        id="numberOfEmployees"
                        className="NumberofEmployees form-control"
                        // className="NumberofEmployees border border-1 w-100 border-dark"form-control
                        value={formData?.numberOfEmployees || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            numberOfEmployees: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Select</option>
                        <option value="5000+">5000+</option>
                        <option value="1000-5000">
                          1000-5000
                        </option>
                        <option value="500-999">
                          500-999
                        </option>
                        <option value="100-499">
                          100-499
                        </option>
                        <option value="20-99">20-99</option>
                        <option value="1-19">1-19</option>
                      </select>
                    </div>
                    <div className="col-lg-6 mb-1">
                      <label className="f-14 mb-2">
                        <p>Company Name</p>
                      </label>
                      <input
                        name="Company Name"
                        className="form-control"
                        placeholder="Company Name"
                        type="text"
                        required
                        value={formData?.companyName || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            companyName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-lg-12 mb-1">
                      <label className="f-14 mb-2" htmlFor="cars">
                        <p>Industry Type</p>
                      </label>
                      <br />
                      {/* <select
                       style={{
                        padding: "11px 0px",
                        borderRadius: "6px",
                      }}
                        id="numberOfEmployees"
                        className="NumberofEmployees border border-1 border-dark w-100"
                        value={formData?.industryType || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            industryType: e.target.value,
                          })
                        }
                      >
                        <option value="">Select</option>
                        <option value="Intern">Intern</option>
                        <option value="Associate">Associate</option>
                        <option value="Junior">Junior</option>
                        <option value="Senior">Senior</option>
                        <option value="Manager">Manager</option>
                        <option value="Executive">Executive</option>
                      </select> */}
                      <select
                        style={{
                          padding: "10px",
                          borderRadius: "6px",backgroundColor: "white", border: "1px solid #a8a8a8"
                        }}
                        id="numberOfEmployees"
                        className="NumberofEmployees form-control"
                        value={formData?.industryType || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            industryType: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Select</option>
                        <option value="Information Technology (IT)">
                          Information Technology (IT)
                        </option>
                        <option value="Healthcare & Pharmaceuticals">
                          Healthcare & Pharmaceuticals
                        </option>
                        <option value="Finance & Banking">
                          Finance & Banking
                        </option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Retail & Consumer Goods">
                          Retail & Consumer Goods
                        </option>
                        <option value="Energy & Utilities">
                          Energy & Utilities
                        </option>
                        <option value="Transportation & Logistics">
                          Transportation & Logistics
                        </option>
                        <option value="Media & Entertainment">
                          Media & Entertainment
                        </option>
                        <option value="Education & Training">
                          Education & Training
                        </option>
                        <option value="Hospitality & Tourism  ">
                          Hospitality & Tourism{" "}
                        </option>
                        <option value="Agriculture & Food Production">
                          Agriculture & Food Production
                        </option>
                        <option value="Government & Public Sector">
                          Government & Public Sector
                        </option>
                        <option value="Nonprofit & Social Services">
                          Nonprofit & Social Services
                        </option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="col-lg-6 mb-1">
                      <label className="f-14 mb-2">
                        <p>Company's Address</p>
                      </label>
                      <input
                        name="Company Name"
                        className="form-control"
                        placeholder="Company address"
                        type="Company Name"
                        required
                        value={formData?.companyAddress || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            companyAddress: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-lg-6 mb-1">
                      <label className="f-14 mb-2">
                        <p>State</p>
                      </label>
                      <input
                        name="State"
                        className="form-control"
                        placeholder="State"
                        type="text"
                        required
                        value={formData?.State || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            State: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-lg-6 mb-1">
                      <label className="f-14 mb-2">
                        <p>Country</p>
                      </label>
                      {/* <input
                        name="Country"
                        className="form-control"
                        placeholder="Country"
                        type="text"
                        required
                        value={formData?.Country || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            Country: e.target.value,
                          })
                        }
                      /> */}
  {/* <Dropdown>
      <Dropdown.Toggle variant="transparent" id="dropdown-basic Country" 
                        className="NumberofEmployees border border-1 border-dark w-100 text-left"
                        value={formData.label}
                        onChange={handleCountryChange}
                        required>
        Select
      </Dropdown.Toggle>

      <Dropdown.Menu>
      {countryOptions.map((country) => (
        <Dropdown.Item href="#/action-1" key={country.value} value={country.label}>{country.label}</Dropdown.Item>
      ))}
      </Dropdown.Menu>
    </Dropdown> */}
                      <select
                        style={{
                          padding: "10px",
                          borderRadius: "6px",backgroundColor: "white", border: "1px solid #a8a8a8"
                        }}
                        id="Country"
                        className="NumberofEmployees form-control"
                        value={formData.label}
                        onChange={handleCountryChange}
                        required
                      >
                        <option value="">Select</option>
                        {countryOptions.map((country) => (
                          <option key={country.value} value={country.label}>
                           {/* <img src={country.image} alt={country.value}/> */}
                            {country.label}     
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-6 mb-1">
                      <label className="f-14 mb-2">
                        <p>Zip Code</p>
                      </label>
                      <input
        name="Zip Code"
        className="form-control"
        placeholder="Zip Code"
        type="text"
        required
        value={formData.zipCode}
        onChange={handleInputChange}
      />
                    </div>
                    <div className="col-lg-12 mb-1">
                      <label className="f-14 mb-2" htmlFor="legal_structure">
                        <p>Legal Structure</p>
                      </label>
                      {/* <select
                       style={{
                        padding: "11px 0px",
                        borderRadius: "6px",
                      }}
                        id="legal_structure"
                        className="NumberofEmployees border border-1 border-dark w-100"
                        value={formData?.legal_structure || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            legal_structure: e.target.value,
                          })
                        }
                      >
                        <option value="">Select</option>
                        <option value="Intern">Intern</option>
                        <option value="Associate">Associate</option>
                        <option value="Junior">Junior</option>
                        <option value="Senior">Senior</option>
                        <option value="Manager">Manager</option>
                        <option value="Executive">Executive</option>
                      </select> */}
                      <select
                        style={{
                          padding: "10px",
                          borderRadius: "6px",backgroundColor: "white", border: "1px solid #a8a8a8"
                        }}
                        id="numberOfEmployees"
                        className="NumberofEmployees form-control"
                        value={formData?.legal_structure || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            legal_structure: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Select</option>
                        <option value="corporation">Corporation</option>
                        <option value="partnership">Partnership</option>
                        <option value="sole proprietorship">
                          Sole Proprietorship
                        </option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="col-lg-6 mb-1">
                      <label className="f-14 mb-2">
                        <p>Trade License No.</p>
                      </label>
                      <input
                        name="Trade License No."
                        className="form-control"
                        placeholder="Unique License No."
                        type="text"
                        value={formData?.tradeLicenseNo || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            tradeLicenseNo: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-lg-6 mb-1">
                      <label className="f-14 mb-2">
                        <p>Date of Establishment</p>
                      </label>
                      <input
                        name="Date of Establishment"
                        className="form-control"
                        placeholder="Date"
                        type="date"
                        required
                        value={formData?.dateOfEstablishment || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            dateOfEstablishment: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <button className="common-btn w-100 mb-1 text-light" type="submit">
                        Submit
                      </button>
                      <p className="f-14">
                      Already have an account?{" "}
                        <Link to="/login-company" className="fw-700 t-color">
                          Log in here
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
              <figure className="form-right-figure  align-items-md-end d-none d-lg-flex">
                <img src={form_imgg} className="img-fluid" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpCompany;
