import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getPrivacyContent } from '../../../services/userServices';

function PolicyModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [content, setContent] = useState([])
  const fetchData = async () => {
      try {
          const resp = await getPrivacyContent();
          // console.log(resp, "resp");
          if (resp.status === 200) {
              console.log(resp, "testing ");
              setContent(resp.data.content);
          }
      } catch (error) {
          console.log(error, "error");
      }
  };
  useEffect(() => {
      fetchData();
  }, []);
  return (
    <>
    <label onClick={handleShow}>Privacy Policy</label>
      {/* <Button variant="primary" onClick={handleShow}>
      Privacy Policy
      </Button> */}

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body    style={{
                        overflowY: "overlay",
                        maxHeight: "75vh",
                      }}> 
          
                {/* <Row className='py-5'>
                    <Col className='list-style'> */}
                        <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    {/* </Col>
                </Row> */}
         
           </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default PolicyModal;