import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/u-vault-logo.svg";
import auth from "../../assets/images/signup-banner.png";
import form_img from "../../assets/images/girl-sit.png";
import { fireToast } from "../../common/Toster";
import axios from "axios";
import { BaseUrl } from "../../common/BaseUrl";
import PasswordChecklist from "react-password-checklist";

const ResetCompanyPassword = () => {
  const navigate = useNavigate();
  const storedFormData = JSON?.parse(localStorage?.getItem("companyFormData"));
  const token = storedFormData?.data?.token;

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    const handleWindowClose = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleWindowClose);

    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      fireToast("error", "Passwords do not match.");
      return;
    }
    try {
      const response = await axios.post(
        BaseUrl + `api/company/resetPassword`,
        {
          new_password: password,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      fireToast("success", response?.data?.message);
      navigate("/");
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  return (
    <>
      <div className="mxh-100 auth-wrapper  d-md-flex position-relative">
        <div className="row w-100">
          <div className=" auth-wrapper-inner lamp-box d-flex align-items-center justify-content-center mxh-100 col-md-5 col-sm-12">
            <Link className="site-logo" to="/">
              <img src={Logo} alt="" />
            </Link>
            <div className="AuthIms">
              <img src={auth} alt="auth" />
            </div>
          </div>
          <div className="col-md-7 col-sm-12 d-flex  p-5 rounded-4 FormDiv lamp-box">
            <form className="row FormStyle" onSubmit={handleSubmit}>
              <div className="col-12">
                <h2 className="mb-4 f-40">Reset Password</h2>
                <small>Enter your new password which</small>
                <div className="mb-3">
                  <label className="f-14 mb-2 mt-4">Password</label>
                  <input
                    name="Password"
                    className="form-control"
                    placeholder="Set Password"
                    type="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <input
                    name="confirmPassword"
                    className="form-control my-3"
                    placeholder="Confirm Password"
                    type="Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <PasswordChecklist
                    className="text-xs d-flex w-100 forgetPwdCorporate"
                    iconSize={8}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    rules={[
                      "capital",
                      "specialChar",
                      "minLength",
                      "lowercase",
                      "number",
                    ]}
                    minLength={8}
                    value={password}
                    messages={{
                      capital: "At least one uppercase letter (A-Z)",
                      specialChar:
                        "At least one special character (e.g., !, @, #, $, etc.)",
                      minLength: "At least 8 digits",
                      lowercase: "At least one lowercase letter (a-z)",
                      number: "At least one number (0-9)",
                    }}
                  />
                </div>

                <div className="">
                  <button className="common-btn w-100 mb-3 text-light">
                    Submit
                  </button>
                </div>
              </div>
            </form>
            <figure className="form-right-figure  align-items-md-end d-none d-lg-flex">
              <img src={form_img} className="img-fluid" />
            </figure>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetCompanyPassword;
