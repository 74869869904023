import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/u-vault-logo.svg";
import axios from "axios";
import { BaseUrl } from "../../common/BaseUrl";
import { fireToast } from "../../common/Toster";
import auth from "../../assets/images/signup-banner.png";
import form_img from "../../assets/images/girl-sit.png";
import OTPInput from "react-otp-input";
const TwoFactorAuth = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const storedFormData = JSON?.parse(localStorage?.getItem("companyFormData"));
      const data = storedFormData?.data?.id;
      const dtoken = localStorage.getItem("deviceToken");
      const response = await axios.post(BaseUrl + `api/company/login_otp`,{
        deviceToken: dtoken ?? "",
        otp: email,
        userId: data
      });
      fireToast("success", response?.data?.message);
      localStorage.setItem("authUser", JSON.stringify(response.data));
      navigate("/corporate-dashboard");
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <div className=" auth-wrapper mxh-100 d-md-flex position-relative">
        <div className="row w-100">
          <div className=" auth-wrapper-inner lamp-box d-flex align-items-center justify-content-center mxh-100 col-md-6 col-sm-12">
            <Link className="site-logo" to="/">
              <img src={Logo} alt="" />
            </Link>
            <div className="AuthIms">
              <img src={auth} alt="auth" />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 d-flex p-5 rounded-4 FormDiv lamp-box">
            <form className="row FormStyle" onSubmit={handleSubmit}>
              <div className="col-12">
                <h2 className="mb-4 f-40">Two Factor Authentication</h2>
                <small>Password will be in your Google Authenticator</small>
                <div className="mb-3 auth-password">
                  <label className="f-14 my-4">Enter 6 digit pin</label>
                  <OTPInput
                    value={email}
                    onChange={(email) => {
                      setEmail(email);
                      // if (email.length === 6) {
                      //   handleSubmit();
                      // }
                    }}
                    numInputs={6}
                    renderInput={(props, index) => (
                      <input {...props} key={index} />
                    )}
                  />
                  {/* <input
                    name="email"
                    className="form-control"
                    placeholder="Email/Number"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  /> */}
                </div>
                <div>
                  <button
                    type="submit"
                    className="common-btn w-100 mb-3 text-light"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
            <figure className="form-right-figure  align-items-md-end d-none d-lg-flex">
              <img src={form_img} className="img-fluid" />
            </figure>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoFactorAuth;
