import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../../../assets/images/u-vault.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../../../common/BaseUrl";

export const HeaderPublic = () => {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const token = authUser ? authUser.token : null;

  const [profile, setProfile] = useState("");
  const [profileEmail, setProfileEmail] = useState("");
  console.log(profile, "profile");

  useEffect(() => {
    getMyProfile();
  }, []);

  const getMyProfile = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(BaseUrl + "api/user/myProfile", {
        headers: {
          Authorization: token,
        },
      });

      setProfile(response?.data?.data?.userDetails);
      setProfileEmail(response?.data?.data?.emails[0]?.email);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };
  return (
    <>
      <header className="site-header py-3">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="d-flex align-items-center justify-content-between HeaderMenu">
                <a href="/">
                  <img src={Logo} alt="" />
                </a>
                <div className="d-flex align-items-center justify-content-end HeaderMenuInner">
                  <ul className="manu-list d-flex align-items-center">
                    {!token && (
                      <>
                        <li>
                          <Link to="/login">Log in</Link>
                        </li>
                        <li>
                          <Link className="custom-btn" to="/sign-up">
                            Sign Up
                          </Link>
                        </li>
                      </>
                    )}
                    {token && (
                     <li className="profileButton">
                     <Link className="custom-btn d-flex w-100" to="/dashboard">
                       <img
                         src={BaseUrl + `/uploads/${profile?.profilePic}`}
                         alt=""
                         width={40}
                         height={40}
                         className="rounded-circle ms-3 col-2 "
                       />
                       <div className="text-white col-10 ps-2">
                         <h6 className="mb-0">
                 
                           {profile?.firstName} {profile?.lastName}
                         </h6>
                         <p className="f-11 text-white"> {profileEmail}</p>
                       </div>
                     </Link>
                   </li>
                    )}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};
