import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import MoveFile from "../modals/MoveFile";
import RenameFile from "../modals/RenameFile";
import ShareFolder from "../modals/ShareFolder";
import { getMyRecentFiles } from "../../services/userServices";
import DeleteConfirmFile from "../modals/DeleteConfirmFile";
import FileOpen from "../folder-sidebar/FileOpen";
import axios from "axios";
import { BaseUrl } from "../../common/BaseUrl";
import { fireToast } from "../../common/Toster";
import MainIcons from "../MainIcons/MainIcons";

const RecentFiles = () => {
  const [moveFile, setMoveFile] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [folderShare, setFolderShare] = useState(false);
  const [fileReNameModal, setFileReNameModal] = useState(false);
  const [folderData, setFolderData] = useState();
  const [canvasModalStatus, setCanvasModalStatus] = useState(false);

  const [files, setFiles] = useState([]);
  const [dlfiles, setDlFiles] = useState();
  const fetchData = async () => {
    try {
      const resp = await getMyRecentFiles();
      // console.log(resp, "resp");
      if (resp.status === 200) {
        setFiles(resp.data.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchOpenFile = async (val) => {
    try {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.post(
        BaseUrl + `api/folder/generate-link`,
        {
          filePath: val?.Key || val?.key,
        },
        {
          headers: { Authorization: token },
        }
      );
      if (response) {
        setDlFiles(response?.data?.data);
        window.open(response?.data?.data);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchBlockDomain = async (val) => {
    try {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.post(
        BaseUrl + `api/folder/blockADomain`,
        {
          file_id: val?._id,
        },
        {
          headers: { Authorization: token },
        }
      );
      if (response) {
        fireToast("success", response?.data?.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  const handlePdfEditor = async (val) => {
    setCanvasModalStatus(true);
    setFolderData(val);
    console.log(val, "val");
    localStorage.setItem("activeFile", JSON.stringify(val));

    try {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.post(
        BaseUrl + `api/folder/generate-link`,
        {
          filePath: val?.Key || val?.key,
        },
        {
          headers: { Authorization: token },
        }
      );
      if (response) {
        // setDlFiles(response?.data?.data);
        // window.open("/pdf?qr-url=https://cdn.shopify.com/s/files/1/1246/6441/files/image4_1f06554e-419e-4014-b471-aa37ebd5a8ff.png&add-qr=" + response?.data?.data);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  return (
    <>
      <FileOpen
        folderData={folderData}
        canvasModalStatus={canvasModalStatus}
        setCanvasModalStatus={setCanvasModalStatus}
      />
      <MoveFile
        moveFile={moveFile}
        setMoveFile={setMoveFile}
        folderData={folderData}
      />
      <ShareFolder
        folderShare={folderShare}
        setFolderShare={setFolderShare}
        folderData={folderData}
      />
      <DeleteConfirmFile
        folderData={folderData}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
      />
      <RenameFile
        folderData={folderData}
        fileReNameModal={fileReNameModal}
        setFileReNameModal={setFileReNameModal}
      />
      <div className="recent-data table-responsive">
        <table className="">
          <thead>
            <tr>
              <th>Name</th>
              <th>Received On</th>
              <th>Document Size</th>
              <th>Last Modified</th> <th>Issuer</th>
              <th>Document ID</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {files?.map((val) => (
              <tr>
                <td  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setCanvasModalStatus(true);
                    setFolderData(val);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <span className="file-icon">
                      <MainIcons val={val.extension}/>
                    </span>
                    <span className="file-name f-14 t-color fw-500">
                      {val.name}
                    </span>
                  </div>
                </td>
                <td
                  onClick={() => {
                    setCanvasModalStatus(true);
                    setFolderData(val);
                  }}
                >
                  {moment(val.createdAt).format("ll")}
                </td>
                <td
                  onClick={() => {
                    setCanvasModalStatus(true);
                    setFolderData(val);
                  }}
                >
                  {val.formated_size}
                </td>
                <td
                  onClick={() => {
                    setCanvasModalStatus(true);
                    setFolderData(val);
                  }}
                >
                  {moment(val.updatedAt).format("ll")}
                </td>
                <td
                  onClick={() => {
                    setCanvasModalStatus(true);
                    setFolderData(val);
                  }}
                >
                  {val?.metadata?.SourceDomain}
                </td>{" "}
                <td
                  onClick={() => {
                    setCanvasModalStatus(true);
                    setFolderData(val);
                  }}
                >
                  {val?.metadata?.VaultId}
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="bg-transparent border-0 w-auto after-none"
                      id="f-option"
                    >
                      <BsThreeDotsVertical className="t-color" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="f-14">
                      {[".pdf"].includes(val.extension) ? (
                        <Dropdown.Item
                          onClick={() => {
                            handlePdfEditor(val);
                          }}
                        >
                          Add Stamp
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}
                      {/* <Dropdown.Item
                        onClick={() => {
                          setCanvasModalStatus(true);
                          setFolderData(val);
                        }}
                      >
                        Open
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => {
                          setMoveFile(true);
                          setFolderData(val);
                        }}
                      >
                        Move File
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setDeleteModal(true);
                          setFolderData(val);
                        }}
                      >
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setFileReNameModal(true);
                          setFolderData(val);
                        }}
                      >
                        Rename
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setFolderShare(true);
                          setFolderData(val);
                        }}
                      >
                        Share{" "}
                      </Dropdown.Item>
                      {/* <Dropdown.Item onClick={() =>{ setFolderShare(true); setFolderData(val);}}>
                        Download{" "}
                      </Dropdown.Item>    */}
                      <Dropdown.Item
                        onClick={() => {
                          fetchOpenFile(val);
                        }}
                      >
                        Download
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchBlockDomain(val);
                        }}
                      >
                        Block Domain
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RecentFiles;
