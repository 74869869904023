import "./Modals.css";
import { useEffect, useState } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import axios from "axios";
import { FaCopy, FaEye, FaEyeSlash } from "react-icons/fa6";
import { MdRemoveCircleOutline } from "react-icons/md";
import { ImUserPlus } from "react-icons/im";
import { fireToast } from "../../common/Toster";
import { BaseUrl } from "../../common/BaseUrl";
import { GiCheckMark } from "react-icons/gi";

const ShareFile = ({
  folderShare,
  setFolderShare,
  folderData,
  folderDatas,
}) => {
  const [files, setFiles] = useState([]);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [getdata, setGetdata] = useState([]);
  const [ids, setids] = useState("");
  console.log("folder id", folderData?._id);
  const [days, setDays] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const initialSecurityOption = ids?.pass ? "private" : "public";
  const [securityOption, setSecurityOption] = useState(ids?.pass === ids?.pass ? "private" : "public");


  const handleClose = () => {
    setFolderShare(false);
    setModalUpdate(false);
  };

  useEffect(() => {
    ModalGetData();
  }, [folderShare]);

  const [modalUpdate, setModalUpdate] = useState(false);

  const fetchFileShare = async (e) => {
    e.preventDefault();
    try {  
      if (!days) {
        console.error("Email and Days are required");
        return;
      }
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      if (ids && ids.folderId) {
        if (securityOption !== "private") {
        const response = await axios.patch(
          BaseUrl + `api/folder-shares/${ids._id}`,
          {
            emails: getdata,
            expiresAt: days,
            password: "",
            folderId: folderData?._id || folderDatas?._id,
          },
          {
            headers: { Authorization: token },
          }
        );
        if (response) {
          setModalUpdate(true);
          setFiles(response?.data?.data);
        }
        }else{
          const response = await axios.patch(
            BaseUrl + `api/folder-shares/${ids._id}`,
            {
              emails: getdata,
              expiresAt: days,
              password: password,
              folderId: folderData?._id || folderDatas?._id,
            },
            {
              headers: { Authorization: token },
            }
          );
          if (response) {
            setModalUpdate(true);
            setFiles(response?.data?.data);
          }
        }
       
      } else {
        const response = await axios.post(
          BaseUrl + `api/folder-shares`,
          {
            emails: getdata,
            expiresAt: days,
            folderId: folderData?._id || folderDatas?._id,
            password: password === "" ? "" : password,
          },
          {
            headers: { Authorization: token },
          }
        );

        if (response) {
          setModalUpdate(true);
          setFiles(response?.data?.data);
        }
      }
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    const linkToCopy = `https://u-vault.io/shared-folder/id:${files?._id || ids?._id}/folderid:${folderData?._id || folderDatas?._id}`;
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => setCopySuccess(true))
      .catch((error) => console.error("Error copying link:", error));
    fireToast("success", "Copied");
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  const ModalGetData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(
        BaseUrl + `api/folder-shares?folderId=${folderData?._id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const emails = response?.data?.data[0]?.emails || [];
      setGetdata(emails);
      setPassword(ids?.pass)
      setids(response?.data?.data[0]);
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  const [users, setUsers] = useState([]);

  const addUser = async () => {
    if (email.trim() !== "") {
      setUsers([...users, email]);
      setGetdata([...getdata, email]);
      await setEmail("");
    }
  };

  // const removeUser = (index) => {
  //   const updatedUsers = [...users];
  //   updatedUsers.splice(index, 1);
  //   setUsers(updatedUsers);
  // };
  const removeUser = (index) => {
    const updatedUsers = [...users];
    updatedUsers.splice(index, 1);
    setUsers(updatedUsers);

    const updatedGetdata = [...getdata];
    updatedGetdata.splice(index, 1);
    setGetdata(updatedGetdata);
  };
  const remainingDays = () => {
    const expiresAtDate = new Date(ids?.expiresAt);
    const currentDate = new Date();
    const differenceInMs = expiresAtDate - currentDate;
    const daysRemaining = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    setDays(daysRemaining+1)
  };
  useEffect(() => {
    remainingDays()
  }, [ids])
  return (
    <>
      <Modal show={folderShare} onHide={handleClose} centered className="shareModal">
        <Modal.Header closeButton className="border-0">
          <div className="w-100 text-center">
            <Modal.Title className="f-20 fw-500">
              {/* Share ‘ {folderData?.name && folderData?.name?.length > 22
                ? folderData?.name?.substring(0, 22) + "..."
                : folderData?.name} ’ */}
              Share
            </Modal.Title>
            {/* <p className="f-14 text-black text-center">
            Share records, certificates and manage access.
            </p> */}
            <p className="f-14 text-black text-center">
              Share records, certificates and manage access.
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          {modalUpdate ? (
            <div className="text-center">
              <div className="mb-4">
                <p>Share this link with others</p>
                <div className="input-group">
                  <input
                    type="text"
                    value={`https://u-vault.io/shared-folder/id:${
                      files?._id || ids?._id
                    }/folderid:${folderData?._id || folderDatas?._id}`}
                    className="form-control"
                    readOnly
                  />
                  <button
                    type="button"
                    className="border-btn round-end"
                    onClick={copyToClipboard}
                  >
                    {copySuccess ? <GiCheckMark /> : <FaCopy />}
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <button className="common-btn" onClick={handleClose}>
                  OK
                </button>
              </div>
            </div>
          ) : (
            <Form className="mb-3" onSubmit={fetchFileShare}>
              <div className="p-3">
                <h6 className="f-16 fw-700 td-gray">People with access</h6>
                <div className="search-block position-relative border w-100">
                  <input
                    className="form-control"
                    placeholder="Enter user Email"
                    value={email}
                    defaultValue={""}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span
                    className="search-icon position-absolute"
                    onClick={addUser}
                  >
                    <RiAddCircleLine />
                  </span>
                </div>
                <div className="d-flex">
                  <div className="col-8">
                    {getdata.map((user, index) => (
                      <div
                        className="user-info d-flex align-items-center p-1 m-1 w-75 "
                        key={index}
                      >
                        <div className="user-icon">
                          <MdRemoveCircleOutline
                            className="remove-icon"
                            onClick={() => removeUser(index)}
                          />
                        </div>
                        <div className="ps-2">
                          <strong className="f-12 d-block">{user}</strong>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <div
                    className={
                      getdata.length - 3 < 1
                        ? "d-none col-4 d-flex justify-content-end"
                        : "col-4 d-flex justify-content-end"
                    }
                  >
                    <p className="mt-2 me-1">
                      <ImUserPlus /> {getdata.length - 3}
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="my-4 border-top border-bottom py-4">
                <h6 className="f-16 fw-700 td-gray">Security</h6>
                <div className=" align-items-start justify-content-between">
                  <div className="d-flex">
                    <Form.Check
                      type="radio"
                      label="General"
                      name="securityOption"
                      id="publicRadio"
                      checked={securityOption === "public"}
                      onChange={() => setSecurityOption("public")}
                    />
                    <Form.Check
                      type="radio"
                      className="ms-3"
                      label="Passcode"
                      name="securityOption"
                      id="privateRadio"
                      checked={securityOption === "private"}
                      onChange={() => setSecurityOption("private")}
                    />
                  </div>
                  <div className="">
                    {securityOption === "private" && (
                      <div className="search-block position-relative border mt-3 w-100">
                        <input
                          className="form-control"
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div
                          className="position-absolute end-0 me-4 top-50 translate-middle-y"
                          style={{ cursor: "pointer" }}
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <h6 className="f-16 fw-700 td-gray mb-2">Expiration</h6>
              <p className="t-gray f-12 fw-500">
                Specify and choose an expiry time of the link
              </p>
              <div className="text-start">
                <div className="d-flex w-25 align-items-center">
                  <input
                    className="form-control me-2"
                    type="number"
                    placeholder="Days from today"
                    max={30}
                    value={days}
                    onChange={(e) => setDays(e.target.value)}
                  />
                  <span className="text-muted">Days</span>
                </div>
              </div>
              {/* <div className="d-flex mt-5 align-items-center justify-content-center">
                <button
                  onClick={fetchFileShare}
                  className="common-btn"
                >
                  Submit
                </button>\
              </div> */}
              <div className="d-flex mt-5 align-items-center justify-content-center">
                <button
                  type="submit"
                  onClick={fetchFileShare}
                  className="common-btn me-3"
                >
                  Submit
                </button>{" "}
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareFile;
