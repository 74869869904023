import React, { useEffect, useState } from "react";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { FaBell, FaCopy, FaRegCircleQuestion, FaRegFolderClosed, FaRegUser, FaWhmcs } from "react-icons/fa6";
import { HiOutlineBars3CenterLeft } from "react-icons/hi2";
import { Link, NavLink, Navigate, Outlet, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/u-vault.svg";
import { BiX } from "react-icons/bi";
import moment from "moment";
import Bellicon from "../../assets/images/n-bell.svg";
import { BaseUrl } from "../../common/BaseUrl";
import axios from "axios";
import { fireToast } from "../../common/Toster";
import { CorporateSidebar } from "./Sidebar";
import NotificationDeleteModal from "../../components/modals/NotificationDeleteModal";
import { GiCheckMark } from "react-icons/gi";
import { MdOutlineContacts } from "react-icons/md";

const routes = [
  {
    path: "/corporate-dashboard",
    name: "Dashboard",
    icon: <FaRegFolderClosed />,
  },
  {
    path: "/corporate-user",
    name: "User",
    icon: <FaRegUser />,
  },
  {
    path: "/corporate-edit-profile",
    name: "Edit Profile",
    icon: <MdOutlineContacts />,
  },
  {
    path: "/corporate-settings",
    name: "Settings",
    icon: <FaWhmcs />,
  },
  {
    path: "/Help-support",
    name: "Help & Support",
    icon: <FaRegCircleQuestion />,
  },
];

const CorporateLayout = () => {
  const [show, setShow] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownSearch, setShowDropdownSearch] = useState(false);
  const [notification, setNotification] = useState("");
  const [read, setRead] = useState("");
  const [folderSearch, setFolderSearch] = useState("");
  const [fileSearch, setFileSearch] = useState([]);
  const [canvasModalStatus, setCanvasModalStatus] = useState(false);
  const [folderData, setFolderData] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [profile, setProfile] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getProfile()
    getNotification(unread)
  }, []);

  const userInfo = JSON.parse(localStorage.getItem("authUser"));
  if (!userInfo?.data?.token) {
    return <Navigate to="/" />;
  }

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  const getNotification = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data?.token;
      const response = await axios.get(BaseUrl + "api/company/myNotifications", {
        headers: {
          Authorization: token,
        },
      });
      setNotification(response?.data?.data?.all);
      setRead(response?.data?.data?.unread);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  
    const handleCopy = ({ text }) => {
      navigator.clipboard.writeText(text);
      setIsCopied(true);
      fireToast("success", "Copied");
      // Reset the "Copied" state after a short delay
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    };
  
    const getProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("authUser")).data.token;
        const response = await axios.get(BaseUrl + "api/company/myProfile", {
          headers: {
            Authorization: token,
          },
        });
        setProfile(response?.data?.data?.user[0]);
        return response.data;
      } catch (error) {
        console.error("API Error:", error);
        fireToast("error", error?.response?.data?.error);
        if (error?.response?.data?.error === "Unauthorized") {
          handleLogout();
        }
      }
    };
    // const handleLogout = () => {
    //   localStorage.clear();
    //   //  navigate("/")
    //   window.location.href = "/";
    // };
    const handleLogout = async() => {
      try {
        const localId = JSON.parse(localStorage.getItem("authUser"));
        const id = localId?.data?.id
        console.log(id.data?.id,"cccccc");
        const response = await axios.get(BaseUrl + `api/company/logout?userId=${id}`);
        localStorage.clear();
        Navigate("/")
        return response.data;
      } catch (error) {
        console.error("API Error:", error);
        localStorage.clear();
        window.location.href = "/";
      }
    };

    const unread = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("authUser")).data?.token;
        const response = await axios.get(BaseUrl + `api/company/readNotifications`, {
          headers: {
            Authorization: token,
          },
        });
        getNotification();
        handleCloseDropdown();
        return response.data;
      } catch (error) {
        console.error("API Error:", error);
      }
    };
  return (
    <>
      <div className="mobile-header d-lg-none d-flex align-items-center justify-content-between p-3">
        <div>
          <Link className="mobile-logo" to="/">
            <img src={Logo} alt="" />
          </Link>
          <span onClick={handleShow}>
            <HiOutlineBars3CenterLeft className="f-28 ms-3" />{" "}
          </span>
        </div>
        <div className="d-flex align-items-center">
          <div className="notify-bell ms-3">
            <Dropdown show={showDropdown} onToggle={setShowDropdown}>
              <Dropdown.Toggle
                className="position-relative bg-transparent border-0 w-auto after-none p-0 text-black"
                id="f-option"
              >
                <FaBell />
                <span
                  className={`notify-count position-absolute ${
                    read === 0 && "d-none"
                  }`}
                >
                  {read}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="notification-block f-14">
                <div className="d-flex align-items-center justify-content-between p-3">
                  <h5 className="notify-title">Notifications</h5>
                  <span>
                    <NotificationDeleteModal
                      getNotification={() => getNotification()}
                      handleCloseDropdown={() => {
                        handleCloseDropdown();
                      }}
                    />
                    <button
                      className="bg-transparent"
                      onClick={handleCloseDropdown}
                    >
                      <BiX />
                    </button>
                  </span>
                </div>
                <div className="notification-wrapper">
                  {notification &&
                    notification?.map((notifications) => (
                      <div className="noify-block position-relative d-flex align-items-start justify-content-between">
                        <img
                          className="n-bell position-absolute"
                          src={Bellicon}
                          alt=""
                        />
                        <div className="notify-box">
                          <p className="f-14">{notifications?.body}</p>
                        </div>
                        <div className="status-time f-12">
                          {moment(notifications?.updatedAt)?.format("LT")}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="text-center py-3">
                  <button className="common-btn" onClick={unread}>
                    Mark all as read
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className="d-flex layout-block">
      <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Link to="/">
              <img src={Logo} alt="" /> 
            </Link>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/* <Sidebar handleClose={handleClose}/> */}
            <div className="sidebar-box" onClick={handleClose}>
        <div className="user-info d-flex align-items-center mt-xl-5 mt-4 p-1 mb-4">
          <div className="user-icon">
            <img
            className="img-fluid"
              src={BaseUrl + `uploads/${profile.profilePic}`}
              alt="custom-pic"
            />
          </div>
          <div className="user-info ps-2">
            <strong className="f-12 d-block">
              {profile?.company_details?.[0]?.company_name}
            </strong>
            <span className="f-12 fw-400">
              {profile?.firstName} {profile?.lastName}
              <span
                className="ms-1"
                onClick={() => handleCopy({ text: profile?.firstName +  profile?.lastName })}
                style={{ cursor: "pointer" }}
              >
                {isCopied ? <GiCheckMark /> : <FaCopy />}
              </span>
            </span>
          </div>
        </div>
        <ul className="pages-links h-100 overflow-auto">
          {routes.map((route) => (
            <NavLink to={route.path} key={route.name}>
              <span className="link-icon align-middle">{route.icon}</span>
              <label className="link-name align-middle ps-2 pointer">
                {route.name}
              </label>
            </NavLink>
          ))}
        </ul>
        <button
          className="common-btn position-absolute logout-btn"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
          </Offcanvas.Body>
        </Offcanvas>
        <div className="layout-sidebar d-lg-block d-none position-relative">
          <CorporateSidebar />
        </div>
        <div className="layout-right p-lg-5 p-4">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default CorporateLayout;
