import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import file from "../../../assets/images/file.png";
import Bellicon from "../../../assets/images/n-bell.svg";
import Logo from "../../../assets/images/u-vault.svg";
import qr from "../../../assets/images/qr.png";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../../common/BaseUrl";

import userimg from "../../../assets/images/userimg.png";
import truecopylogo from "../../../assets/images/truecopylogo.jpg";
import { Header } from "../Landing/header/Header";
import { Footer } from "../Landing/footer/Footer";

const VerifyPage = () => {
  const { id } = useParams();
  const [detail, setDetail] = useState({});
  console.log(detail, "gggg");

  const getPdfDetail = async () => {
    try {
      const response = await axios.get(
        BaseUrl + "api/folder/document_details?file_id=" + id
      );
      if (response) {
        setDetail(response?.data?.data?.result);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  useEffect(() => {
    getPdfDetail();
  }, []);
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const token = authUser ? authUser.token : null;

  return (
    <>
      <Container fluid className="d-flex flex-column nopadding">
        {/* <Header /> */}
        <header className="site-header py-3">
          <Container>
            <Row>
              <Col xs={12}>
                <div className="d-flex align-items-center justify-content-between HeaderMenu">
                  <a href="/">
                    <img src={Logo} alt="" />
                  </a>
                  <div className="d-flex align-items-center justify-content-end HeaderMenuInner">
                    <ul className="manu-list d-flex align-items-center">
                      {!token && (
                        <>
                          <li>
                            <Link to="/login">Log in</Link>
                          </li>
                          <li>
                            <Link className="custom-btn" to="/sign-up">
                              Sign Up
                            </Link>
                          </li>
                        </>
                      )}
                    </ul>
                    {token && (
                      <Link className="custom-btn" to="/dashboard">
                        Dashboard
                      </Link>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        <div className="VaultId">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="UserImgDetail d-flex align-items-center">
                  <div className="UserImg">
                    <img
                      src={BaseUrl + `uploads/${detail?.data_owner_pic}`}
                      alt=""
                    />
                  </div>
                  <div className="UserDetail">
                    This Document ID is owned {detail?.data_owner_firstname}{" "}
                    {detail?.data_owner_lastname} and was generated on{" "}
                    {detail?.date_vaulted}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="Pipe_C">
              <h3 className="fw-bold">true copy<sup>TM</sup></h3>
               <span className="PipeId">{detail?.VaultId}</span>
                <img src={truecopylogo} alt="" />
              </div>
            </div>
            <div className="col-md-8">
              <div className="CopyVault">
                <h2>True Copy Vaulted record</h2>
                <p className="Orign">Origin : {detail?.source}</p>
                <p>
                  The true copy badge signifies that the corresponding
                  credential was transmitted electronically by email and
                  emanated from the designated domain .{" "}
                </p>
                <p>
                  The credential was securely transmitted to the recipients
                  U-Vault account on the indicated date, ensuring the
                  confidentiality and integrity of the information during the
                  delivery process. The credential has been securely vaulted
                  within the U-Vault system on the specified date. This
                  additional layer of security guarantees that the document is
                  safely stored and easily accessible when needed. The badge
                  also attests that the credential stored in U-Vault has not
                  undergone any alterations or tampering since its transmission
                  and vaulting. It remains an accurate and unmodified
                  representation of the information received to the holders
                  U-Vault account. To ensure the complete integrity of the
                  credential and to personally verify its relevance and
                  credibility, we recommend requesting a digital copy of the
                  document.
                </p>

                <div className="TableDiv mt-5 mb-5">
                  <table>
                    <tr>
                      <td>Document ID:</td>
                      <td>{detail?.VaultId}</td>
                    </tr>
                    <tr>
                      <td>Date Vaulted:</td>
                      <td>{detail?.date_vaulted}(GMT)</td>
                    </tr>
                    <tr>
                      <td>Time Vaulted:</td>
                      <td>{detail?.time_vaulted}</td>
                    </tr>
                    <tr>
                      <td>Origin:</td>
                      <td>{detail?.source}</td>
                    </tr>
                    <tr>
                      <td>Document Type: </td>
                      <td>{detail?.type}</td>
                    </tr>
                    <tr>
                      <td>File Name:</td>
                      <td>{detail?.file_name}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Row className="flex-grow-1 align-items-center justify-content-center">
        <Col className="text-center">
          <div className="d-flex justify-content-center align-items-center mb-3">
            <img src={file} alt="File" className="mb-3" />
            <p>{detail?.file_name ? detail?.file_name : "Sample-File.pdf" }</p>
          </div>
          <div>
            <img src={`https://chart.apis.google.com/chart?cht=qr&chs=100x100&chld=L|0&chl=https://uvault.zip2box.com/doc/${id}`} alt="QR Code" />
          </div>
          <div className="border bgGrey text-white p-5 d-inline-block rounded mt-5">
            <p>
              <strong>Document ID:</strong> {detail?.VaultId ? detail?.VaultId : "" }
            </p>
            <p>
              <strong>Date Vaulted:</strong> {detail?.date_vaulted ? detail?.date_vaulted : "" }
            </p>
            <p>
              <strong>Time Vaulted:</strong> {detail?.time_vaulted ? detail?.time_vaulted : "" }
            </p>
            <p>
              <strong>Source: </strong> {detail?.source ? detail?.source : "" }
            </p>
            <p>
              <strong>Data Owner:</strong> {detail?.data_owner ? detail?.data_owner : "" }
            </p>
            <p>
              <strong>Domain ID:</strong> {detail?.domain_id ? detail?.domain_id : "" }
            </p>
            <p>
              <strong>Type: </strong> {detail?.type ? detail?.type : "" }
            </p>
          </div>
        </Col>
      </Row> */}
      </Container>
      <div className="ClearBoth"></div>
      <Footer />
    </>
  );
};

export default VerifyPage;
