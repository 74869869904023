import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/u-vault.svg";
import * as Yup from "yup";
import auth from "../../assets/images/domain-v.png";
import { RxCross2 } from "react-icons/rx";
import { BaseUrl } from "../../common/BaseUrl";
import axios from "axios";
import { fireToast } from "../../common/Toster";
import QRCode from "qrcode.react";
import AuthModal from "../pages/Corporate/Modal/AuthModal";

const DomainListing = () => {
  const storedFormData = JSON?.parse(localStorage?.getItem("companyFormData"));
  const data = storedFormData?.data;

  const navigate = useNavigate();
  const [domains, setDomains] = useState([""]); // Initial domain
  const [formattedDomain, setFormattedDomain] = useState([""]);

  const handleAddMore = () => {
    setDomains([...domains, ""]); // Add an empty domain
  };

  const handleCancel = (index) => {
    setDomains(domains.filter((_, i) => i !== index)); // Remove domain by index
  };

  const handleSubmit = () => {
    console.log("Submitted domains:", domains);
    const formattedDomains = domains
      .map((domain) => domain.trim())
      .filter((domain) => domain !== "");
    console.log("Formatted Domains:", formattedDomains);
    custom_domains(formattedDomains);
    setFormattedDomain(formattedDomains);
  };

  const custom_domains = async (formattedDomains) => {
    try {
      const response = await axios.post(
        BaseUrl + `api/custom_domains/verification-file`,
        {
          userId: data?.userId,
          name: formattedDomains,
        }
      );
      console.log("API Response:", response.data);
      localStorage.setItem("companyFormData", JSON.stringify(response.data));
      fireToast("success", response?.data?.message);
      navigate("/VerifyYourDomain");
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem("companyFormData"));
    const data = storedFormData;
  }, []);

  return (
    <>
      <div className="mxh-100 d-md-flex position-relative">
        <div className="row w-100">
          <div className="d-flex align-items-center justify-content-center mxh-100 col-md-6 col-sm-12">
            <Link className="site-logo" to="/">
              <img src={Logo} alt="" />
            </Link>
            <img src={auth} alt="" />
          </div>
          <div className="col-md-6 col-sm-12 bg-white p-5 rounded-4 FormDiv d-flex align-items-center justify-content-center">
            <div className="w-75">
              <h2>Domain Listing</h2>
              <p>Enter your domain name</p>
              <ul>
                {domains.map((domain, index) => (
                  <li key={index} className="my-2">
                    <input
                      className="w-75 mb-3"
                      type="text"
                      value={domain}
                      placeholder="@sample.com"
                      onChange={(e) =>
                        setDomains(
                          domains.map((d, i) =>
                            i === index ? e.target.value : d
                          )
                        )
                      }
                    />
                    <span
                      className={`domain-cancel rounded-circle ms-2 w-25${
                        domains.length === 1 ? "disabled" : ""
                      }`}
                      onClick={() => domains.length > 1 && handleCancel(index)}
                    >
                      <RxCross2 />
                    </span>
                  </li>
                ))}
              </ul>
              <button onClick={handleAddMore} className="me-3 common-btn ">
                Add More
              </button>
              <button onClick={handleSubmit} className="common-btn ">
                Submit
              </button>
              {/* <AuthModal
                UserId={data?.userId}
                Domains={formattedDomain}
                Submit={() => handleSubmit()}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DomainListing;
