import React, { useEffect, useState } from "react";
import "./TopBar.css";
import { FaSistrix } from "react-icons/fa6";
import { FaBell } from "react-icons/fa6";
import Dropdown from "react-bootstrap/Dropdown";
import { BiX } from "react-icons/bi";
import Bellicon from "../../assets/images/n-bell.svg";
import axios from "axios";
import { BaseUrl } from "../../common/BaseUrl";
import moment from "moment";
import FileOpen from "../folder-sidebar/FileOpen";
import { useLocation } from "react-router-dom";
import NotificationDeleteModal from "../modals/NotificationDeleteModal";
import MainIcons from "../MainIcons/MainIcons";
import { Spinner } from "react-bootstrap";

const TopBar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownSearch, setShowDropdownSearch] = useState(false);
  const [notification, setNotification] = useState("");
  const [read, setRead] = useState("");
  const [folderSearch, setFolderSearch] = useState("");
  const [fileSearch, setFileSearch] = useState([]);
  const [canvasModalStatus, setCanvasModalStatus] = useState(false);
  const [folderData, setFolderData] = useState();
  const [isLoading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const pathParts = pathname.split("/");
  const lastPart = pathParts[1];
  console.log("Dynamic Param:", lastPart);
  console.log(notification, "setNotification");
  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    getNotification(unread);
    // search();
  }, []);

  const getNotification = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(BaseUrl + "api/user/myNotifications", {
        headers: {
          Authorization: token,
        },
      });
      setNotification(response?.data?.data?.all);
      setRead(response?.data?.data?.unread);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const search = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(
        BaseUrl + `api/folder/searchfilefolder?search=${folderSearch}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setLoading(false);
      setFileSearch(response?.data?.data?.files);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      setLoading(false);
    }
  };

  const unread = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(BaseUrl + `api/user/readNotifications`, {
        headers: {
          Authorization: token,
        },
      });
      getNotification();
      handleCloseDropdown();
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  return (
    <>
      <FileOpen
        folderData={folderData}
        canvasModalStatus={canvasModalStatus}
        setCanvasModalStatus={setCanvasModalStatus}
      />
      <div className="page-top-search mb-lg-5 mb-4">
        <div className="row">
          <div className="col-md-6 mb-md-0 mb-4">
            <h3 className="f-28 text-capitalize">
              {window.location.pathname.split("/").pop() == ""
                ? "Dashboard"
                : window.location.pathname.split("/").pop()}
            </h3>
          </div>
          <div className="col-md-6 d-md-flex justify-content-end align-items-center">
            <div className="search-block position-relative">
              {lastPart === "dashboard" && (
                <div className="d-flex align-items-center">
                  <input
                    className="form-control ms-auto"
                    type="text"
                    value={folderSearch}
                    onChange={(e) => setFolderSearch(e.target.value)}
                    placeholder="Search files"
                  />
                  <Dropdown
                    show={showDropdownSearch}
                    onToggle={setShowDropdownSearch}
                  >
                    <Dropdown.Toggle
                      className="position-relative bg-transparent border-0 w-auto after-none p-0 text-black"
                      id="f-option"
                    >
                      <FaSistrix
                        onClick={search}
                        className="search-icon position-absolute"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="mt-4"
                      style={{
                        transform: "translate(1px, 35px, 0px)",
                        minWidth: "337px",
                      }}
                    >
                      <ul
                        style={{
                          overflowY: "overlay",
                          maxHeight: "250px",
                        }}
                      >
                        {isLoading ? (
                          <span className="d-flex justify-content-center">
                            <Spinner animation="border" />
                          </span>
                        ) : fileSearch[0] === "" ||
                          fileSearch[0] === null ||
                          fileSearch[0] === undefined ? (
                          <li className="text-center">File Not Found !!</li>
                        ) : (
                          fileSearch?.map((file) => (
                            <li
                              key={file?._id}
                              className="px-2 py-3"
                              onClick={() => {
                                setCanvasModalStatus(true);
                                setFolderData(file);
                              }}
                            >
                              <MainIcons val={file?.extension} />{" "}
                              {file?.file_without_extension?.length > 22
                                ? `${
                                    file?.file_without_extension?.substring(
                                      0,
                                      22
                                    ) + "..."
                                  }${file?.extension}`
                                : `${file?.file_without_extension}${file?.extension}`}
                            </li>
                          ))
                        )}
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
            <div className="notify-bell ms-4 d-lg-block d-none">
              <Dropdown show={showDropdown} onToggle={setShowDropdown}>
                <Dropdown.Toggle
                  className="position-relative bg-transparent border-0 w-auto after-none p-0 text-black"
                  id="f-option"
                >
                  <FaBell />
                  <span
                    className={`notify-count position-absolute ${
                      read === 0 && "d-none"
                    }`}
                  >
                    {read}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="notification-block f-14">
                  <div className="d-flex align-items-center justify-content-between p-3">
                    <h5 className="notify-title">Notifications</h5>{" "}
                    <span>
                      <NotificationDeleteModal
                        getNotification={() => getNotification()}
                        handleCloseDropdown={() => {
                          handleCloseDropdown();
                        }}
                      />
                      <button
                        className="bg-transparent"
                        onClick={handleCloseDropdown}
                      >
                        <BiX />
                      </button>
                    </span>
                  </div>
                  <div className="notification-wrapper">
                    {notification &&
                      notification?.map((notifications) => (
                        <div
                          className={`noify-block position-relative d-flex align-items-start justify-content-between ${
                            notifications?.isSeen !== true &&
                            "unreadnotifications"
                          }`}
                        >
                          <img
                            className="n-bell position-absolute"
                            src={Bellicon}
                            alt=""
                          />
                          <div className="notify-box">
                            <p className="f-14">{notifications?.body}</p>
                            {/* <button className="border-btn">Move</button> */}
                          </div>
                          <div className="status-time f-10">
                            {/* {moment(notifications?.createdAt)?.format("LT")} */}
                            {moment(notifications?.createdAt).format("lll")}
                          </div>
                          {/* <div className="notify-unread"></div> */}
                        </div>
                      ))}
                  </div>
                  <div className="text-center py-3">
                    <button className="common-btn" onClick={unread}>
                      Mark all as read
                    </button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBar;
