import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/images/u-vault-logo-white.svg";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { userSignup } from "../../services/userServices";
import { fireToast } from "../../common/Toster";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import { FaCheckCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import { AiFillCloseCircle } from "react-icons/ai";
import { Button, OverlayTrigger } from "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs";
import { BaseUrl } from "../../common/BaseUrl";
import PasswordChecklist from "react-password-checklist";
import auth from "../../assets/images/auth.webp";

const SignIn = () => {
  //meta title
  document.title = "SignUp";
  const { email } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const dtoken = localStorage.getItem("deviceToken");
  // console.log(dtoken, "teuilbjskdbc lnkl");
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      deviceToken: dtoken ?? "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      dial_code: "",
      country_code: "",
      password: "",
      confirm: "",
      uVaultEmail: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        // .email("Invalid Email Format")
        .required("This Field is Required"),
      password: Yup.string()
        // .email("Invalid User")
        .required("This Field is Required"),
      password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol"),
      confirm: Yup.string().oneOf(
        [Yup.ref("password"), null],
        'Must match "password" field value'
      ),
    }),
    onSubmit: async (values) => {
      try {
        setButtonDisabled(true);
        if (emailAvalable) {
          // console.log("sdfsdfsdfasdf");
          // CustomEmail
          // changeApiStatus(true, "");
          const loginResponse = await userSignup({
            ...values,
            uVaultEmail: CustomEmail.toLowerCase().replace(/\s/g, ''),

          });
          if (loginResponse.status === 200) {
            fireToast("success", loginResponse.message);
            localStorage.setItem(
              "UserInfo",
              JSON.stringify({
                ...values,
                uVaultEmail: CustomEmail.toLowerCase(),
              })
            );
            navigate("/verify-signup");
          } else {
            setButtonDisabled(false);
            // fireToast("error", "err?.response?.data?.error");
            // changeApiStatus(false, "");
            // throw new Error(loginResponse.error);
          }
        }
      } catch (err) {
        console.log(err);
        fireToast("error", err?.response?.data?.error);
        setButtonDisabled(false);
      }
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [emailAvalable, setEmailAvalable] = useState();
  const [emailError, setEmailError] = useState();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    fetchemailexists();
  }, [
    validation?.values?.uVaultEmail,
    validation?.values?.firstName,
    validation?.values?.lastName,
  ]);

  const fetchemailexists = async () => {
    try {
      const response = await axios.post(
        BaseUrl + "api/user/email-already-exists",
        {
          email: CustomEmail,
        }
      );
      if (response.status === 200) {
        // throw new Error("test");
        setEmailAvalable(response?.data?.message);
      } else {
        setEmailAvalable("");
      }
    } catch (error) {
      setEmailAvalable("");
      setEmailError(error?.response?.data?.message);

      console.error("API Error:", error);
    }
  };

  const CustomEmail =
    validation?.values?.firstName +
    "." +
    validation?.values?.lastName +
    validation?.values?.uVaultEmail +
    "@u-vault.io";

  // console.log(CustomEmail, "customemail");

  const renderTooltip = (props) => (
    <Tooltip className="f-12" id="button-tooltip" {...props}>
      <strong>Please include and ‘I’ icon and minimum password rules :</strong>
      <div className="text-start my-1">
        <p className="mb-0">
          <strong>Minimum Length:</strong> 8 characters
        </p>

        <strong>Character Types:</strong>
      </div>
      <ul className="text-start">
        <li>At least one uppercase letter (A-Z)</li>
        <li> At least one lowercase letter (a-z)</li>
        <li> At least one number (0-9)</li>
        <li>At least one special character (e.g., !, @, #, $, etc.)</li>
      </ul>
    </Tooltip>
  );
  const renderTooltips = (props) => (
    <Tooltip className="f-12" id="button-tooltip" {...props}>
      <strong>Please include and ‘I’ icon and minimum password rules :</strong>
      <div className="text-start my-1">
        <p className="mb-0">
          <strong>Minimum Length:</strong> 8 characters
        </p>

        <strong>Character Types:</strong>
      </div>
      <ul className="text-start">
        <li>At least one uppercase letter (A-Z)</li>
        <li> At least one lowercase letter (a-z)</li>
        <li> At least one number (0-9)</li>
        {/* <li>At least one special character (e.g., !, @, #, $, etc.)</li> */}
      </ul>
    </Tooltip>
  );

  const capitalize = (str) => {
    const sanitizedStr = str.replace(/[^a-zA-Z- ]/g, "");
    return sanitizedStr.charAt(0).toUpperCase() + sanitizedStr.slice(1);
  };
  const capitaliz = (str) => {
    const sanitizedStr = str.replace(/[^a-zA-Z- ]/g, "");
    return sanitizedStr;
  };
  const uvemail = (str) => {
      const sanitizedStr = str.replace(/[^\w\s-]/g, '');
      return sanitizedStr.charAt(0).toUpperCase() + sanitizedStr.slice(1);
    };
    
  return (
    <>
      <div className="auth-wrapper user-login mxh-100 d-md-flex position-relative">
        <div className="mxh-100 w-100 d-md-flex position-relative">
          <div formattedValue className="row  w-100">
            <div className=" auth-wrapper auth-wrapper-inner d-flex align-items-center justify-content-center col-md-6 col-sm-12">
              <Link className="site-logo" to="/">
                <img src={Logo} alt="" />
              </Link>
              <div className="AuthIms">
                <img src={auth} alt="auth" />
              </div>
              <div className="row w-100">
                <div className="Welcome">
                  <h1 className="text-white f-48">Get vaulted!</h1>
                  <p className="text-white f-32">Your journey starts here…</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 d-flex bg-white p-5 rounded-4 FormDiv">
              <form
                className="row FormStyle auth-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="col-md-12">
                  <h2 className="mb-4 f-40">Registration</h2>
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <label className="f-14 mb-2">
                        <strong>First Name</strong>
                      </label>
                      <input
                        name="firstName"
                        className="form-control"
                        placeholder="First Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={capitalize(validation?.values?.firstName) || ""}
                        invalid={
                          validation.touched.firstName &&
                          validation.errors.firstName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.firstName &&
                      validation.errors.firstName ? (
                        <p className="text-danger">
                          {validation.errors.firstName}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label className="f-14 mb-2">
                        <strong>Last Name</strong>
                      </label>
                      <input
                        name="lastName"
                        className="form-control"
                        placeholder="Last Name"
                        type="lastName"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={capitalize(validation.values.lastName) || ""}
                        invalid={
                          validation.touched.lastName &&
                          validation.errors.lastName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.lastName &&
                      validation.errors.lastName ? (
                        <p className="text-danger">
                          {validation.errors.lastName}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label className="f-14 mb-2">
                        <strong>Email ID</strong>
                      </label>
                      <input
                        name="email"
                        className="form-control"
                        placeholder="Enter email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />

                      {validation.touched.email && validation.errors.email ? (
                        <p className="text-danger">{validation.errors.email}</p>
                      ) : null}
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label className="f-14 mb-2">
                        <strong>Phone Number</strong>
                      </label>
                      <PhoneInput
                        enableSearch={true}
                        value={value}
                        onChange={(phoneNumber, v) => {
                          setValue(phoneNumber);
                          validation.setFieldValue("phoneNo", phoneNumber);
                          validation.setFieldValue("dial_code", v?.dialCode);
                          validation.setFieldValue(
                            "country_code",
                            v?.countryCode
                          );
                        }}
                      />
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="d-flex align-items-baseline">
                        <label className="f-14 mb-2">
                          <strong>Choose Password</strong>
                        </label>{" "}
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <Button className="p-0 bg-transparent text-black border-0">
                            <BsInfoCircle className="ms-2" />
                          </Button>
                        </OverlayTrigger>
                      </div>
                      <div className="input-group">
                        <input
                          name="password"
                          className="form-control"
                          placeholder="Enter password"
                          type={showPassword ? "text" : "password"}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text py-3"
                            onClick={handleTogglePassword}
                            style={{ cursor: "pointer" }}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                      </div>

                      {/* {validation.touched.password &&
                      validation.errors.password ? (
                        <p className="text-danger">
                          {validation.errors.password}
                        </p>
                      ) : null} */}
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label className="f-14 mb-2 d-flex align-items-baseline">
                        <strong> U-Vault Email</strong>{" "}
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltips}
                        >
                          <Button className="p-0 bg-transparent text-black border-0">
                            <BsInfoCircle className="ms-2" />
                          </Button>
                        </OverlayTrigger>
                      </label>
                      <div className="d-flex align-items-center ">
                        <div className=" d-flex form-control overflow-x-auto ps-2 p-0 align-items-center">
                          <span style={{ width: "max-content" }}>
                            {capitalize(validation?.values?.firstName)
                              ?.toLowerCase()
                              .replace(/\s/g, "")}
                            {validation?.values?.lastName?.length > 0
                              ? "."
                              : null}
                            {capitalize(validation?.values?.lastName)
                              ?.toLowerCase()
                              .replace(/\s/g, "")}
                          </span>
                          <input
                            name="uVaultEmail"
                            maxLength={4}
                            className="form-control border-transparent px-0"
                            onChange={validation.handleChange}
                            value={uvemail(validation.values.uVaultEmail) || ""}
                          />
                        </div>
                        <span className="w-100 fs-14 text-nowrap">
                          @u-vault.io
                        </span>
                        <div className="d-none">
                          <input
                            name="uVaultEmail"
                            className="form-control border border-light"
                            placeholder="U-Vault Email"
                            value={"@u-vault.io"}
                            readOnly
                          />
                        </div>
                      </div>
                      {validation.touched.confirm &&
                      validation.errors.confirm ? (
                        <p className="text-danger">
                          {validation.errors.confirm}
                        </p>
                      ) : null}
                      <div
                        className={
                          validation.values.lastName.length > 1 ? "" : "d-none"
                        }
                      >
                        {emailAvalable === "This email is available" ? (
                          <p className="text-success">
                            Email Available <FaCheckCircle />
                          </p>
                        ) : (
                          <p className="text-danger">
                            Email Unavailable <AiFillCloseCircle />
                          </p>
                        )}
                      </div>
                    </div>
                    {validation.values.password ? (
                      <div className="col-12 commonIcon" id="passwordCheck">
                        <PasswordChecklist
                          className="text-xs d-flex  w-100  "
                          iconSize={8}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                          rules={[
                            "capital",
                            "specialChar",
                            "minLength",
                            "lowercase",
                            "number",
                          ]}
                          minLength={8}
                          value={validation.values.password}
                          messages={{
                            capital: "At least one uppercase letter (A-Z)",
                            specialChar:
                              "At least one special character (e.g., !, @, #, $, etc.)",
                            minLength: " At least 8 digit",
                            lowercase: "At least one lowercase letter (a-z)",
                            number: " At least one number (0-9)",
                          }}
                        />
                      </div>
                    ) : null}
                    <div className="col-12 mt-4">
                      <button className="common-btn w-100 mb-3" type="submit"  disabled={isButtonDisabled}>
                         {isButtonDisabled ? 'Submitting...' : 'Register'}
                      </button>
                      <p className="f-14">
                        Already have an account?{" "}
                        <Link to="/login" className="fw-700 t-color">
                          Log in here
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
{
  /* <input
                            name="uVaultEmail"
                            className="form-control border-light text-lowercase"
                            value={
                              validation.values.firstName +
                              "." +
                              validation.values.lastName +
                              (validation.values.uVaultEmail || "")
                            }
                            onChange={validation.handleChange}
                          /> */
}
