import React from "react";
import { Link } from "react-router-dom";
import Envelope from "../../assets/images/mail.svg";
import Logo from "../../assets/images/u-vault.svg";
import axios from "axios";
import { BaseUrl } from "../../common/BaseUrl";
import { Button } from "react-bootstrap";
import { fireToast } from "../../common/Toster";


const VerifySignup = ({ value }) => {
  console.log(value, "signup value");
  let email = JSON.parse(localStorage.getItem("UserInfo")).email;

  const signupagain = async () => {
    try {
      // Retrieve user information from local storage
      const userInfo = JSON.parse(localStorage.getItem("UserInfo"));
  
      const dtoken = localStorage.getItem("deviceToken");
      if (userInfo) {
        const response = await axios.post(
          BaseUrl + `api/user/sign-up`,
          {
            deviceToken: dtoken ?? "",
            password: userInfo.password,
            dial_code: userInfo.dial_code,
            country_code: userInfo.country_code,
            phoneNo: userInfo.phoneNo,
            email: userInfo.email,
            lastName: userInfo.lastName,
            firstName: userInfo.firstName,
            uVaultEmail: userInfo.uVaultEmail,
          }
        );
  
        if (response) {
          fireToast("success","Verify link send to your register Email")
        }
      } else {
        console.error("User information not found in local storage");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  
  document.title = "Verify Signup";
  return (
    <>
      <div className="py-3">
        {/* <Link className="site-logo d-lg-block d-none" to="/login">
          <img src={Logo} alt="" />
        </Link> */}
        <div className="container">
        <div className="">
              <Link className='mb-5 pb-lg-4 d-block' to="/">
                  <img src={Logo} alt="" />
                </Link>
              </div>
        </div>
        <div className="verify-signup">
          <div className="row">           
                  <div className="col-12 text-center">
                    <img src={Envelope} alt="" />
                    <h2 className="mb-2 mt-4 f-40">Please verify your email</h2>
                    <p className="f-24 fw-300  mb-lg-5 mb-4">
                      You’re almost there! We sent an email to{" "}
                      <strong className="d-block fw-600">{email}</strong>
                    </p>
                  </div>
                  <div className="col-12 text-center mt-5">
                    <p className="f-16 mb-lg-5 mb-4">
                      Just click on the link in that email to complete your
                      signup. <br />
                      If you don’t see it, you may need to check your spam folder
                      or resend the link.{" "}
                    </p>
                    <div className="text-center">
                    <button className="common-btn mb-2 me-md-3" onClick={signupagain}>Resend Link</button>
                    <Link className="common-btn ms-md-3" to={"/login"} >Login</Link>
                    </div>
                  </div>
          
           
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifySignup;
