import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/u-vault-logo-white.svg";
// Formik validation
import * as Yup from "yup";
import auth from "../../assets/images/auth.webp";
import { useFormik } from "formik";
import { userLogin } from "../../services/userServices";
import { fireToast } from "../../common/Toster";
import Icon from "react-icons-kit";
import { FiEye, FiEyeOff } from "react-icons/fi";
const Login = () => {
  //meta title
  document.title = "Login";
  const navigate = useNavigate();

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(<FiEyeOff />);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(<FiEye />);
      setType("text");
    } else {
      setIcon(<FiEyeOff />);
      setType("password");
    }
  };
  const dtoken = localStorage.getItem("deviceToken");
  console.log(dtoken, "dtoken");
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      deviceToken: dtoken ?? "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        // .email("Invalid Email Format")
        .required("This Field is Required"),
      password: Yup.string().required("This Field is Required"),
      password: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol"),
    }),
    onSubmit: async (values) => {
      try {
        // changeApiStatus(true, "");
        const loginResponse = await userLogin(values);
        if (loginResponse.status === 200) {
          // fireToast('success', loginResponse.message)
          console.log(loginResponse, "loginResponse");
          // changeApiStatus(false, "");
          localStorage.setItem("authUser", JSON.stringify(loginResponse.data));
          navigate("/dashboard");
        } else {
          // changeApiStatus(false, "");
          // throw new Error(loginResponse.error);
        }
      } catch (err) {
        console.log(err);
        fireToast("error", err.response.data.error);
      }
    },
  });

  return (
    <>
      <div className="mxh-100 d-md-flex position-relative">
        <div className="row w-100">
          {/* Left Side Part */}
          <div className=" auth-wrapper user-login auth-wrapper-inner d-flex align-items-center justify-content-center mxh-100 col-md-6 col-sm-12">
            <Link className="site-logo" to="/">
              <img src={Logo} alt="" />
            </Link>
            <div className="AuthIms">
              <img src={auth} alt="auth" />
            </div>
            <div className="row w-100">
              <div className="Welcome">
                <h1 className="text-white f-48">Welcome Back!</h1>
                <p className="text-white f-32">Sign into your account</p>
              </div>
            </div>
            {/* <img src={auth} alt="" /> */}
          </div>
          {/* Left Side Part Ends */}
          <div className="col-md-6 col-sm-12 d-flex bg-white p-5 rounded-4 FormDiv">
            <form
              className="row FormStyle"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="col-12">
                <h2 className="mb-4 f-40">Log in</h2>
                <div className="mb-3">
                  <label className="f-14 mb-2">Email ID</label>
                  <input
                    name="email"
                    className="form-control"
                    placeholder="Enter email"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <p className="text-danger">{validation.errors.email}</p>
                  ) : null}
                </div>
                <div className="mb-2 col-12 ">
                  <label className="f-14 mb-2">Password</label>
                  <div className="position-relative">
                  <input
                    name="password"
                    className="form-control col-10"
                    value={validation.values.password || ""}
                    type={type}
                    placeholder="Enter Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  
                  <span
                    className="position-absolute password-toggle passwordeye"
                    onClick={handleToggle}
                    aria-label="Toggle password visibility"
                  >
                    {icon}
                  </span>
                  </div>
                  {validation.touched.password && validation.errors.password ? (
                    <p className="text-danger">{validation.errors.password}</p>
                  ) : null}
                </div>
                <div className="text-end mb-4">
                  <Link className="f-14 fw-700 t-color" to="/forgot-password">
                    Forgot Password?
                  </Link>
                </div>
                <div className="">
                  <button className="common-btn w-100 mb-3">Log in</button>
                  <p className="f-14">
                    Don’t have an account?{" "}
                    <Link to="/sign-up" className="fw-700">
                      Register here
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
