import React, { useEffect } from "react";
import MobileDetect from "mobile-detect";
import QRCode from "qrcode.react";
import authenticator from "../../assets/images/google-authenticator.svg";
import appStore from "../../assets/images/app-store.svg";
import playStore from "../../assets/images/Google_Authenticator_for_Android_icon.svg.svg";

const DeviceDetect = () => {
//   useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    console.log(md.os());
    if (md.os() === "iOS") {
      window.location.href = "https://apps.apple.com/us/app/google-authenticator/id388497605";
    } else if (md.os() === "AndroidOS") {
      window.location.href = "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US";
    } else {
      window.location.href = "https://uvault.zip2box.com/u-vault-application";
    }
//   }, []);

  return (
    <div className="container">
    <div className="text-center">
      <p
        className="mb-3 f-16 mt-5"
        style={{
          fontFamily: "inter",
          color: "#0B2443",
        }}
      >
        Please download and install the Google Authenticator app on your phone
      </p>
      <div className="row">
        <div className="col-md-6 d-flex align-items-center justify-content-center mb-3 mb-md-0">
          <img
            src={authenticator}
            alt="Google Authenticator"
            width={250}
            className="my-3"
          />
        </div>
        <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
          <a href="https://apps.apple.com/us/app/google-authenticator/id388497605">
            <img src={appStore} alt="App Store" width={250} className="mb-3" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US">
            <img src={playStore} alt="Play Store" width={250} />
          </a>
        </div>
      </div>
    </div>
  </div>
  );
};

export default DeviceDetect;
