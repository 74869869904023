import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/u-vault-logo.svg";
import auth from "../../assets/images/signup-banner.png";
import form_img from "../../assets/images/girl-sit.png";
import { fireToast } from "../../common/Toster";
import axios from "axios";
import { BaseUrl } from "../../common/BaseUrl";
const LoginPwdcompany = () => {
  document.title = "Login";
  const navigate = useNavigate();
  const storedFormData = JSON?.parse(localStorage?.getItem("companyFormData"));
  const data = storedFormData?.data?.email
  // console.log("Stored Form Dassssssssssta:", data)
  const [password, setPassword] = useState("");
  const dtoken = localStorage.getItem("deviceToken");
  console.log(dtoken, "dtoken");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(BaseUrl + `api/company/login_password`, {
        deviceToken: dtoken ?? "",
        password:password,
        email: data
      });
      // console.log("API Response:", response.data);
      fireToast("success", response?.data?.message);
      localStorage.setItem("authUser", JSON.stringify(response.data));
      navigate("/corporate-dashboard");
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  const handleresend = async (e) => {
    try {
      const response = await axios.post(BaseUrl + `api/company/resendpassword`, {
        email:data
      });
      fireToast("success", response?.data?.message);
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };
  return (
    <>
      <div className="mxh-100 auth-wrapper  d-md-flex position-relative">
        <div className="row w-100">
           
          {/* Left Side Part */}
          <div className=" auth-wrapper-inner lamp-box d-flex align-items-center justify-content-center mxh-100 col-md-5 col-sm-12">
            <Link className="site-logo" to="/">
              <img src={Logo} alt="" />
            </Link>
             <div className="AuthIms">
              <img src={auth} alt="auth" />
            </div>
            {/* <div className="row w-100">
              <div className="Welcome">
                <h1 className="text-white f-48">Welcome Back!</h1>
                <p className="text-white f-32">Sign into your account</p>
              </div>
            </div> */}
            {/* <img src={auth} alt="" /> */}
          </div>
          {/* Left Side Part Ends */}
          <div className="col-md-7 col-sm-12 d-flex  p-5 rounded-4 FormDiv lamp-box">
            <form
              className="row FormStyle"
              onSubmit={handleSubmit}
              // onSubmit={(e) => {
              //   e.preventDefault();
              //   validation.handleSubmit();
              //   return false;
              // }}
            >
              <div className="col-12">
                <h2 className="mb-4 f-40">Password</h2>
                <small>Enter password which is sent on your Email {data}</small>
                <div className="mb-3">
                  <label className="f-14 mb-2 mt-4">Password</label>
                  <input
                    name="email"
                    className="form-control"
                    placeholder="Enter Password"
                    type="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    // onChange={validation.handleChange}
                    // onBlur={validation.handleBlur}
                    // value={validation.values.email || ""}
                    // invalid={
                    //   validation.touched.email && validation.errors.email
                    //     ? true
                    //     : false
                    // }
                  />
                  {/* {validation.touched.email && validation.errors.email ? (
                    <p className="text-danger">{validation.errors.email}</p>
                  ) : null} */}
                </div>
               
               
                <div className="">
                  <button className="common-btn w-100 mb-3 text-light">Login</button>
                  <p className="f-14">
                  Didn’t receive the OTP?{" "}
                    <Link onClick={handleresend} className="fw-700">
                      Resend Password
                    </Link>
                  </p>
                </div>
              </div>
            </form>
            <figure className="form-right-figure  align-items-md-end d-none d-lg-flex">
                <img src={form_img} className="img-fluid" />
              </figure>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPwdcompany;
