import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/u-vault-logo.svg";
import axios from "axios";
import { BaseUrl } from "../../common/BaseUrl";
import { fireToast } from "../../common/Toster";
import auth from "../../assets/images/signup-banner.png";
import form_img from "../../assets/images/girl-sit.png";

const ForgetCompany = () => {
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(BaseUrl + `api/company/forgotPassword`, {
        email: email,
      });
      console.log("API Response:", response?.data?.data?.token);
      // fireToast("success", response?.data?.message);
      navigate("/Verify-forgot-pwd");
      localStorage.setItem("companyFormData", JSON.stringify(email));
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  return (
    <>
      <div className=" auth-wrapper mxh-100 d-md-flex position-relative">
        <div className="row w-100">
          <div className=" auth-wrapper-inner lamp-box d-flex align-items-center justify-content-center mxh-100 col-md-6 col-sm-12">
            <Link className="site-logo" to="/">
              <img src={Logo} alt="" />
            </Link>
            <div className="AuthIms">
              <img src={auth} alt="auth" />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 d-flex p-5 rounded-4 FormDiv lamp-box">
            <form className="row FormStyle" onSubmit={handleSubmit}>
              <div className="col-12">
                <h2 className="mb-4 f-40">Forgot Password</h2>
                <small>OTP will be sent on your Email/Number.</small>
                <div className="mb-3">
                  <label className="f-14 mb-2 mt-4">
                    Email Address/Mobile Number
                  </label>
                  <input
                    name="email"
                    className="form-control"
                    placeholder="Email/Number"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="">
                  <button className="common-btn w-100 mb-3 text-light">
                    Submit
                  </button>
                </div>
              </div>
            </form>
            <figure className="form-right-figure  align-items-md-end d-none d-lg-flex">
              <img src={form_img} className="img-fluid" />
            </figure>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetCompany;
