import React, { useEffect, useState } from "react";
import CorporateTopBar from "../TopBar/Topbar";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown } from "react-bootstrap";
import axios from "axios";
import { BaseUrl } from "../../../../common/BaseUrl";
import { fireToast } from "../../../../common/Toster";
import moment from "moment";
import AddUser from "../Modal/AddUser";

const CorporateUser = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getUser = async (pageNumber) => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data?.token;
      const response = await axios.get(
        `${BaseUrl}api/company/userlisting?pageLimit=10&pageNumber=${
          pageNumber || currentPage
        }`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setUsers(response?.data?.data?.all);
      setTotalPages(response?.data?.data?.totalPages);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  const deactivateUser = async (userId) => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data?.token;
      const response = await axios.post(
        `${BaseUrl}api/company/activate-deactivate`,
        { id: userId },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      fireToast("success", response?.data?.message);
      getUser();
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  const deleteUser = async (userId) => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).data?.token;
      const response = await axios.delete(
        `${BaseUrl}api/company/delete_subadmin?id=${userId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      fireToast("success", response?.data?.message);
      getUser();
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  useEffect(() => {
    getUser(currentPage);
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const role = JSON.parse(localStorage.getItem("authUser"))?.data?.role;

  return (
    <div>
      <CorporateTopBar />
      <div className="d-flex justify-content-between align-items-center my-2">
        <strong>User Details</strong>
        <AddUser
          getUser={() => {
            getUser();
          }}
        />
      </div>
      <div className="recent-data table-responsive">
        <table className="">
          <thead>
            <tr>
              <th>User</th>
              <th>Platform Role</th>
              <th>Last Login</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr
                key={user.id}
                className={user?.isBlocked === true ? "text-danger" : ""}
              >
                <td style={{ cursor: "pointer" }}>
                  <div className="d-flex align-items-center">
                    <span
                      className={`file-name f-14 t-color fw-500 ${
                        user?.isBlocked === true ? "text-danger" : ""
                      }`}
                    >
                      {user?.firstName} {user.lastName}
                    </span>
                  </div>
                </td>
                <td>{user?.role}</td>
                <td>{moment(user?.loggedOutTime).format("ll")}</td>
                {user?.role === "Admin" ? null : (
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="bg-transparent border-0 w-auto after-none"
                        id="f-option"
                      >
                        <BsThreeDotsVertical className="t-color" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="f-14">
                        <Dropdown.Item
                          onClick={() => {
                            if (
                              window.confirm(
                                `Are you sure you want to ${
                                  user?.isBlocked === true
                                    ? "Activate"
                                    : "Deactivate"
                                } this user?`
                              )
                            ) {
                              deactivateUser(user._id);
                            }
                          }}
                        >
                          {user?.isBlocked === true ? "Activate" : "Deactivate"}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            if (
                              window.confirm(
                                `Are you sure you want to Delete this user ?`
                              )
                            ) {
                              deleteUser(user._id);
                            }
                          }}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className="pagination">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            className={page === currentPage ? "active" : ""}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        ))}
      </div> */}
    </div>
  );
};

export default CorporateUser;

//   onClick={() => {
//     handlePdfEditor(val);
//   }}
