import axios from "axios";
import "./Modals.css";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { fireToast } from "../../common/Toster";
import { BaseUrl } from "../../common/BaseUrl";

const RenameFile = ({
  fileReNameModal,
  setFileReNameModal,
  folderData,
  fetchData,
}) => {
  const [show, setShow] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const handleClose = () => {
    setShow(false);
    setFileReNameModal(false);
  };

  useEffect(() => {
    setShow(fileReNameModal);
  }, [fileReNameModal]);
  // console.clear("");
  console.log(folderData, "folderData={folderData}");
  const fetchRenameFolder = async () => {
    try {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.put(
        BaseUrl + `api/folder/renameFile`,
        {
          new_name: newFolderName + folderData?.extension,
          old_name: folderData?.name,
          folder_id: folderData?.folderId,
        },
        {
          headers: { Authorization: token },
        }
      );
      if (response.status === 200) {
        setShow(false);
        fireToast("success", response?.message || "File Renamed Successfully");
        window.location.reload();
        fetchData();
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      fetchRenameFolder();
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="border-0">
          <div>
            <Modal.Title className="f-20 fw-500">Rename file</Modal.Title>
            <p className="f-14 text-black">Enter a new name for this file.</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form className="my-4" action="">
            <label className="mb-3">File name</label>
            <input
              className="form-control"
              type="text"
              name=""
              id=""
              value={newFolderName} // Set the input value from state
              onChange={(e) => setNewFolderName(e.target.value)} // Update the state on input change
              onKeyPress={handleKeyPress}
            />
          </form>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button className="border-btn" onClick={handleClose}>
            Cancel
          </button>
          <button className="common-btn" onClick={fetchRenameFolder}>
            Rename
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RenameFile;
