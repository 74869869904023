// Firebase Cloud Messaging Configuration File. 
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyAtJOYnpCYdx-Txq_DigP7I_APaothFStY",
  authDomain: "u-vault-btb.firebaseapp.com",
  projectId: "u-vault-btb",
  storageBucket: "u-vault-btb.appspot.com",
  messagingSenderId: "182587380619",
  appId: "1:182587380619:web:294896b3e39068a499d58e",
  measurementId: "G-XCPPTGSPK9"
};


initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: `BFcrOBMEjluqMApvahCEE_XGjLT3rYpuIiTrWBevNi61M4afTQQTShLLVw2yo4OzD9j7tkV6xnaan794J-F2poc` })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem("deviceToken", currentToken);
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        localStorage.setItem("deviceToken", '');
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

  
