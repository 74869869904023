import React, { useEffect, useState } from "react";
import './video.css';


const imagebox = [
    [{ src: require("../../../../assets/images/Pic01.jpg") }, { src: require("../../../../assets/images/Pic02.jpg") }, { src: require("../../../../assets/images/Pic03.jpg") }, { src: require("../../../../assets/images/Pic04.jpg") },],
    [ { src: require("../../../../assets/images/Pic05.jpg") },{ src: require("../../../../assets/images/Pic06.jpg") },{ src: require("../../../../assets/images/Pic07.jpg") }, { src: require("../../../../assets/images/Pic08.jpg") }],
    [ { src: require("../../../../assets/images/Pic09.jpg") }, { src: require("../../../../assets/images/Pic10.jpg") },{ src: require("../../../../assets/images/Pic11.jpg") },{ src: require("../../../../assets/images/Pic12.jpg") }],
    [{ src: require("../../../../assets/images/Pic13.jpg") }, { src: require("../../../../assets/images/Pic14.jpg") }, { src: require("../../../../assets/images/Pic15.jpg") }, { src: require("../../../../assets/images/Pic16.jpg") }],
    [{ src: require("../../../../assets/images/Pic17.jpg") }, { src: require("../../../../assets/images/Pic18.jpg") },{ src: require("../../../../assets/images/Pic19.jpg") }, { src: require("../../../../assets/images/Pic20.jpg") }  ]
]

console.log(imagebox);
const BannerAnimation = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        let intervalId = setInterval(() => {
            
            let randomIndex = Math.random() * imagebox.flat().length;
            setActiveIndex(Math.round(randomIndex));
        }, 2500);
        return () => {
            clearInterval(intervalId);
        }
    }, [])
    return (
        <>
            <div className=" video-outer-box">
                {imagebox.map((imagesRow, j) => {

                    return (
                        <div className="row" key={j}>{
                            imagesRow.map((image, i) => {
                                return (
                                    <div className={`col-3 blur-img  ${((j*imagesRow.length)+(i+1)) === activeIndex ? "active" : ""}`} key={i}>
                                        <figure className="figure-box  rounded-circle"><img src={image.src} /></figure>
                                    </div>
                                )
                            })
                        }
                        </div>)
                })
                }
            </div>
        </>
    )
}

export default BannerAnimation;