import React, { useEffect, useState } from "react";
import { Link, NavLink, Route, useNavigate } from "react-router-dom";
import Logo from "../assets/images/u-vault.svg";
import User from "../assets/images/u.svg";
import Cloud from "../assets/images/cloud.svg";
import { FaCopy, FaRegFolderClosed } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa6";
import { FaWhmcs } from "react-icons/fa6";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { getMyFolder, logOut } from "../services/userServices";
import ProgressBar from "react-bootstrap/ProgressBar";
import { GiCheckMark } from "react-icons/gi";
import { fireToast } from "../common/Toster";
import { BaseUrl } from "../common/BaseUrl";
import axios from "axios";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <FaRegFolderClosed />,
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    icon: <FaRegUser />,
  },
  {
    path: "/settings",
    name: "Settings",
    icon: <FaWhmcs />,
  },
  {
    path: "/Help-support",
    name: "Help & Support",
    icon: <FaRegCircleQuestion />,
  },
];

export const Sidebar = ({handleClose}) => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState("");

  const handleLogOut = async () => {
    const userInfo = JSON.parse(localStorage.getItem("authUser"));
    // console.log(userInfo, "testing")
    try {
      const result = await logOut(userInfo._id);
      let deviceToken = localStorage.getItem("deviceToken");
      console.log(deviceToken, "device tokenm")
      localStorage.clear();
      localStorage.setItem("deviceToken", deviceToken);
      navigate("/")
      // window.location.href = "/"
    } catch (error) {}
  };

  const now = 20;

  const [myFolders, setMyFolders] = useState([]);
  // console.log(myFolders,"myFoldersmyFoldersmyFolders");
  const fetchFolder = async () => {
    try {
      const resp = await getMyFolder();
      // console.log(resp, "resp");
      if (resp.status === 200) {
        setMyFolders(resp.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    fetchFolder();
    getMyProfile();
  }, []);

  const usedSpaceMB = myFolders?.totalstorage_mb?.replace(/[^0-9.]/g, "");
  const totalSpaceGB = 600;

  const totalSpaceMB = totalSpaceGB * 1024 * 1024;
  const calculatePercentage = (used, total) => (used / total) * 100;
  const percentage = calculatePercentage(usedSpaceMB, totalSpaceMB);

  let user = JSON.parse(localStorage.getItem("authUser"));

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = ({ text }) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
fireToast("success", "Copied")
    // Reset the "Copied" state after a short delay
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };


  const getMyProfile = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.get(BaseUrl + "api/user/myProfile", {
        headers: {
          Authorization: token,
        },
      });

      setProfile(response?.data?.data?.userDetails);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  const email = user?.uvaultEmail[0]?.email;
  return (
    <>
      <div className="sidebar-box" onClick={handleClose}>
        <Link className="d-lg-block d-none" to="/">
          <img src={Logo} alt="" />
        </Link>
        <div className="user-info d-flex align-items-center mt-xl-5 mt-4 mb-4">
          <div className="user-icon">
          <img src={BaseUrl + `uploads/${profile.profilePic}`} alt="" height={40} width={40} className="rounded-circle"/>
           
          </div>
          <div className="ps-2">
            <strong className="f-12 d-block">
              {user?.firstName} {user?.lastName}
            </strong>
            <span className="f-12 fw-400">
              {email}
              <span
                className="ms-1"
                onClick={() => handleCopy({ text: email })}
                style={{ cursor: "pointer" }}
              >
                {isCopied ? <GiCheckMark /> : <FaCopy />}
              </span>
            </span>
          </div>
        </div>
        <ul className="pages-links">
          {routes.map((route) => (
            <NavLink to={route.path} key={route.name}>
              <span className="link-icon align-middle">{route.icon}</span>
              <label className="link-name align-middle ps-2 pointer">
                {route.name}
              </label>
            </NavLink>
          ))}
        </ul>
        <div className="about-storage">
          <img src={Cloud} alt="" />
          {/* <div className="f-12 mb-3">
            <span className="fw-600 t-color">
              {percentage?.toFixed(2)}% Space{" "}
            </span>
            In-use
          </div> */}
          <div className="text-center">

          <span className="fw-600 t-color">
          {myFolders?.totalstorage_mb && Number(myFolders?.totalstorage_mb)?.toFixed(2)}
            </span>
            {" "} : 
            MB Used
          </div> 

          {/* <ProgressBar
            now={percentage}
            label={`${percentage}%`}
            visuallyHidden
          /> */}
          {/* <div className="d-flex align-items-center justify-content-between w-100 mb-4 mt-1">
            <span>{myFolders.totalstorage_mb}</span>
            <span>800GB</span>
          </div>
          <button className="common-btn w-100">Upgrade</button> */}
        </div>
        <button
          className="common-btn position-absolute logout-btn"
          onClick={handleLogOut}
        >
          Logout
        </button>
      </div>
    </>
  );
};
