import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/u-vault-logo.svg";
import auth from "../../../assets/images/auth.webp";
import verifyDomain from "../../../assets/images/verifydomain.svg";

const VerifyYourDomain = () => {
  //meta title
  document.title = "Login";
  const navigate = useNavigate();

  const jsonDownload = () => {
    const storedFormData = JSON?.parse(
      localStorage?.getItem("companyFormData")
    );
    const data = storedFormData?.data?.json;
    const jsonData = { data };
    const jsonString = JSON.stringify(jsonData, null, 2); // null and 2 are for formatting
    const blob = new Blob([jsonString], { type: "application/json" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "file.json";
    link.click();
  };

  return (
    <>
      <div className="mxh-100 d-md-flex position-relative">
        <div className="row w-100">
          {/* Left Side Part */}
          <div className="col-md-6 col-sm-12 d-flex bg-white p-5 rounded-4 FormDiv">
            <Link className="site-logo" to="/">
              <img src={Logo} alt="" />
            </Link>
            <div className="d-flex justify-content-center align-items-center mt-5">
              <div>
                <h2 className="ms-5">Verify your domain</h2>
                {/* <p>{data?.description}</p> */}
                <div className="col-md-6 offset-md-1 position-relative w-100">
                  <div className="mb-4">
                    <h4>Step 1: Download the JSON file:</h4>
                    <div className="d-flex contact-step align-items-center">
                      <div className="contact-number">
                        <span>01</span>
                      </div>
                      <div className="contact-content ps-4">
                        <p>
                          Click the "Download" button on our website to get the
                          verification JSON file.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <h4>Step 02 : Set up on your domain server:</h4>
                    <div className="d-flex contact-step align-items-center">
                      <div className="contact-number">
                        <span>02</span>
                      </div>
                      <div className="contact-content ps-4">
                        <p>
                          On your domain server, create a folder named "U-vault"
                          in the main/root directory of your website.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <h4>Step 03:Upload the JSON file:</h4>
                    <div className="d-flex contact-step align-items-center">
                      <div className="contact-number">
                        <span>03</span>
                      </div>
                      <div className="contact-content ps-4">
                        <p>
                          Place the downloaded JSON file into the "U-vault"
                          folder on your server.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <h4>Step04: Verify on our website:</h4>
                    <div className="d-flex contact-step align-items-center">
                      <div className="contact-number">
                        <span>04</span>
                      </div>
                      <div className="contact-content ps-4">
                        <p>
                          Log in to our website, go to your account settings,
                          and click on the "Verify Domain" button. We'll handle
                          the verification process automatically using the file
                          you uploaded.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    {/* <a href="">Index.js</a>
                <br /> */}
                    <button className="common-btn mt-2" onClick={jsonDownload}>
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Left Side Part Ends */}
          <div className="  d-flex align-items-center justify-content-center mxh-100 col-md-6 col-sm-12">
            <div className="row w-100">
              <div className="Welcome bg-transparent">
                <img src={verifyDomain} alt="" />
              </div>
            </div>
            {/* <img src={auth} alt="" /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyYourDomain;
