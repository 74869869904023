import "./Modals.css";
import { useEffect, useState } from "react";
import { FaSistrix, FaAngleDown, FaAngleRight } from "react-icons/fa";
import { BsDot } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Photo from "../../assets/images/folder.png";
import axios from "axios";
import { fireToast } from "../../common/Toster";
import { getMyFolder } from "../../services/userServices";
import { BaseUrl } from "../../common/BaseUrl";

const MoveFile = ({ moveFile, setMoveFile, folderData }) => {
  const [show, setShow] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState({
    name: "",
    id: "",
  });
  const [subfolderVisibility, setSubfolderVisibility] = useState({});

  const handleClose = () => {
    setShow(false);
    setMoveFile(false);
  };

  useEffect(() => {
    setShow(moveFile);
  }, [moveFile]);

  const [myFolders, setMyFolders] = useState([]);

  const fetchFolder = async () => {
    try {
      const resp = await getMyFolder();
      if (resp.status === 200) {
        setMyFolders(resp.data.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    fetchFolder();
  }, []);

  const handleFolderChange = (folderName, folderId) => {
    setSelectedFolder({
      name: folderName,
      id: folderId,
    });
  };

  const toggleSubfolderVisibility = (folderId) => {
    setSubfolderVisibility((prevVisibility) => ({
      ...prevVisibility,
      [folderId]: !prevVisibility[folderId],
    }));
  };

  const fetchMoveFolder = async (e) => {
    e.preventDefault();

    try {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.put(
        BaseUrl + `api/folder/moveFile`,
        {
          key: folderData?.key || folderData?.Key,
          folder_id: selectedFolder.id,
        },
        {
          headers: { Authorization: token },
        }
      );
      if (response) {
        fireToast("success", response?.message || "File Moved Successfully");
        window.location.reload();
        setShow(false);
      }
    } catch (error) {
      console.error("API Error:", error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  const renderFolder = (folder) => {
    const isSubfolderVisible = subfolderVisibility[folder?._id];

    return (
      <div
        key={folder?._id}
        className="moveModalFolderlist"
        onClick={() => handleFolderChange(folder?.name, folder?._id)}
      >
        <div className="mt-3">
          <div
            className="d-flex align-items-center"
            onClick={(e) => {
              e.preventDefault();
              toggleSubfolderVisibility(folder?._id);
            }}
          >
            <span className="file-icon">
              <img src={Photo} alt="" />
            </span>
            <div>
              <span className="file-name f-14 td-gray fw-600">
                {folder?.name?.match(regex)?.[0]}
              </span>
              <span className="d-block f-12 t-gray">
                {folder?.totalFiles} <BsDot /> {folder?.totalSize}
              </span>
            </div>
            {folder?.children && folder?.children.length > 0 && (
              <button className="btn btn-link p-0">
                {isSubfolderVisible ? <FaAngleDown /> : <FaAngleRight />}
              </button>
            )}
            <div className="ms-auto radioWidth">
              <Form.Check
                type="radio"
                name="folderRadio"
                aria-label={folder?.name}
                onChange={() => handleFolderChange(folder?.name, folder?._id)}
                checked={selectedFolder.id === folder?._id}
              />
            </div>
          </div>
        </div>
        {folder?.children && folder?.children.length > 0 && isSubfolderVisible && (
          <div style={{ marginLeft: "20px" }} onClick={(e) => e.stopPropagation()}>
            {folder?.children.map((subfolder) => (
              <div
                key={subfolder?._id}
                onClick={() => handleFolderChange(subfolder?.name, subfolder?._id)}
              >
                {renderFolder(subfolder)}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const regex = /([^/]+)$/;

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="border-0">
        <div>
          <Modal.Title className="f-20 fw-500">Move file</Modal.Title>
          <p className="f-14 text-black">Search or select a folder.</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form className="mb-3" action="" onSubmit={fetchMoveFolder}>
          {/* <div className="search-block position-relative border w-100 mb-4">
            <input
              className="form-control ms-auto"
              type="text"
              placeholder="Search folders"
            />
            <button className="search-icon position-absolute">
              <FaSistrix />
            </button>
          </div> */}
          <h6 className="f-16">Folders</h6>
          <div
            style={{
              overflowY: "overlay",
              maxHeight: "250px",
            }}
          >
            {myFolders?.map((folder) => renderFolder(folder))}
          </div>
          <div className="d-flex justify-content-end align-items-center mt-4">
            <Button className="border-btn" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="secondary" className="common-btn ms-3">
              Move
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MoveFile;
