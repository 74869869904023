import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { changePassword } from "../../services/userServices";
import { fireToast } from "../../common/Toster";
import { useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";

const ChangePassword = () => {
  const navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("This Field is Required"),
      newPassword: Yup.string()
        .min(8, "Password must be 8 characters long")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter")
        .matches(/[^\w]/, "Password requires a symbol")
        .required("This Field is Required"),
      confirm: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        'Must match "password" field value'
      ),
    }),
    onSubmit: async (values) => {
      try {
        const localData = JSON.parse(localStorage.getItem("authUser"));
        const token = localData?.token;
        const resetPassword = await changePassword(values, token);
        if (resetPassword.status === 200) {
          fireToast("success", resetPassword.message);
          navigate("/edit-profile");
        } else {
          throw new Error(resetPassword.error);
        }
      } catch (err) {
        fireToast("error", err.response?.data?.error || "An error occurred");
      }
    },
  });

  return (
    <>
      <form
         onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <div className="row">
          <div className="col-12 mb-3">
            <label className="f-14 fw-400 mb-2">Old Password</label>
            <input
              name="oldPassword"
              className="form-control"
              placeholder="Enter Old Password"
              type="password"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.oldPassword || ""}
            />
            {validation.touched.oldPassword &&
              validation.errors.oldPassword && (
                <p className="text-danger">{validation.errors.oldPassword}</p>
              )}
          </div>
          <div className="col-12 mb-4">
            <label className="f-14 fw-400 mb-2">New Password</label>
            <input
              name="newPassword"
              className="form-control"
              placeholder="Enter New Password"
              type="password"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.newPassword || ""}
            />
            {validation.values.newPassword && (
              <div className="commonIcon mt-2" id="passwordCheck">
                <PasswordChecklist
                  className="text-xs d-flex w-100"
                  iconSize={8}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  rules={[
                    "capital",
                    "specialChar",
                    "minLength",
                    "lowercase",
                    "number",
                  ]}
                  minLength={8}
                  value={validation.values.newPassword}
                  messages={{
                    capital: "At least one uppercase letter (A-Z)",
                    specialChar:
                      "At least one special character (e.g., !, @, #, $, etc.)",
                    minLength: "At least 8 digits",
                    lowercase: "At least one lowercase letter (a-z)",
                    number: "At least one number (0-9)",
                  }}
                />
              </div>
            )}
          </div>
          <div className="col-12 text-end">
            <button type="submit" className="common-btn profilesave w-100">
              Change Password
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ChangePassword;
