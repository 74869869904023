import "./FolderOffcanvas.css";
import { useEffect, useState, useRef } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaFolderMinus } from "react-icons/fa6";
import Photo from "../../assets/images/photo.png";
import Video from "../../assets/images/video.png";
import Folder from "../../assets/images/folder.png";
import File from "../../assets/images/file.png";
import { MdArrowBackIosNew } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "axios";
import PdfViewerComponent from "./PdfViewerComponent";
import { BaseUrl } from "../../common/BaseUrl";
import downloadFolder from "../../assets/images/downloadfolder.png";

const FileOpen = ({
  canvasModalStatus,
  setCanvasModalStatus,
  folderData,
  openfile,
}) => {
  const [show, setShow] = useState(false);
  const [files, setFiles] = useState();

  const handleClose = () => {
    setShow(false);
    setCanvasModalStatus(false);
  };

  useEffect(() => {
    console.log(folderData, "folderDatafolderData");
    setShow(canvasModalStatus);
  }, [canvasModalStatus]);

  useEffect(() => {
    fetchOpenFile();
  }, [show]);

  const fetchOpenFile = async () => {
    try {
      let token = JSON.parse(localStorage.getItem("authUser")).token;
      const response = await axios.post(
        BaseUrl + `api/folder/generate-link`,
        {
          filePath: folderData?.Key || folderData?.key,
        },
        {
          headers: { Authorization: token },
        }
      );
      if (response) {
        setFiles(response?.data?.data);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const renderFileContent = () => {
    console.log(folderData, "folderDatafolderData");

    const fileExtension = folderData?.extension?.toLowerCase();
    let token = JSON.parse(localStorage.getItem("authUser")).token;

    if ([".pdf"].includes(fileExtension)) {
      if (folderData?.stamped_key) {
        return (
          <iframe
            title="Doc Viewer"
            width="100%"
            height="100%"
            src={`https://docs.google.com/viewer?embedded=true&url=${files}`}
            frameBorder="0"
          />
        );
      } else {
        return (
          <iframe
            title="Doc Viewer"
            width="100%"
            height="100%"
            src={
              "https://pdf.u-vault.io?accesstoken=" +
              token +
              "&qr-url=" +
              folderData?._id +
              "&add-qr=" +
              files
            }
            frameBorder="0"
          />
        );
      }

      //  <iframe title="Doc Viewer" width="100%" height="100%" src={"https://pdf.u-vault.io?accesstoken="+token+"&qr-url="+folderData?._id+"&add-qr="+files} frameBorder="0" />
      // return (
      //   <iframe
      //     id="myFrame"
      //     title="Doc Viewer"
      //     width="100%"
      //     height="100%"
      //     src={
      //       "/pdf?accesstoken=" +
      //       token +
      //       "&qr-url=" +
      //       folderData?._id +
      //       "&add-qr=" +
      //       files
      //     }
      //     frameBorder="0"
      //   />
      // );
    } else if (
      [
        ".doc",
        ".docx",
        ".txt",
        ".csv",
        ".xml",
        ".pptx",
        ".ppt",
        ".xlsx",
        ".xls",
        ".zip",
      ].includes(fileExtension)
    ) {
      return (
        // <iframe
        //   title="Doc Viewer"
        //   width="100%"
        //   height="600"
        //   src={files}
        //   frameBorder="0"
        // />
        <div class="downloadLinkDiv downloadLinkDiv02 d-flex justify-content-center text-center">
          {/* <h2>oops!</h2>
          <h4>Looks like You’re Lost.</h4> */}
          <h4>
            We're currently unable to open this file on our platform. We're
            working on a fix but in the meantime, you can download it from the
            below.
          </h4>
          <div>
            <a href={files} download="" class="downloadLink">
              Download
            </a>
          </div>
        </div>
      );
    } else if ([".png", ".jpg", ".gif", ".jpeg"].includes(fileExtension)) {
      return <img src={files} alt="" className="image-fluid" />;
    } else if ([".mp4", ".mkv"].includes(fileExtension)) {
      return (
        <video width="100%" height="600" controls className="image-fluid">
          <source src={files} type="video/mp4" />
        </video>
      );
    }
    return null;
  };

  function handleIframeMessage(event) {
    if (event.data === "iframeElementClicked") {
      // Code to hide the parent div
      // alert("testing");
      console.log("Test>>>>>>>>>>>>>>>")
      handleClose()
      // document.getElementById("parentDiv").style.display = "none";
    }
  }

  // // Add an event listener to listen for messages from the iframe
  window.addEventListener("message", handleIframeMessage);
 
  // useEffect(() => {
    
  // // // Add an event listener to the iframe when it's loaded
  // document.getElementById("myFrame").onload = function () {
  //   // Access the contentWindow of the iframe (note: this won't work if the iframe is from a different origin)
  //   var iframeWindow = document.getElementById("myFrame").contentWindow;

  //   // Do something with the iframe content if needed
  // };
  // }, [])

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header className="px-4 py-3 d-flex align-items-center border-bottom">
        <Link
          className=" text-dark d-flex align-items-center"
          onClick={handleClose}
        >
          <MdArrowBackIosNew /> Back
        </Link>

        <Offcanvas.Title>
          <span className="folder-icon align-middle">
            <FaFolderMinus />
            <img
              src={
                [".png", ".jpg", ".gif", ".jpeg"].includes(
                  folderData?.extension
                )
                  ? Photo
                  : [".mp4", ".mkv"].includes(folderData?.extension)
                  ? Video
                  : [".mp3"].includes(folderData?.extension)
                  ? ""
                  : [".doc", ".docx", ".txt", ".csv", ".xml", ".pdf"].includes(
                      folderData?.extension
                    )
                  ? File
                  : Folder
              }
              alt=""
            />
          </span>
          <span className="ps-3">
            {" "}
            {folderData?.name && folderData?.name?.length > 22
              ? folderData?.name?.substring(0, 22) +
                "..." +
                folderData?.extension
              : folderData?.name}
          </span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">{renderFileContent()}</Offcanvas.Body>
    </Offcanvas>
  );
};

export default FileOpen;
