import React, { useEffect, useState } from 'react'
import './PrivacyPolicy.css'
import { Header } from '../Landing/header/Header'
import { Footer } from '../Landing/footer/Footer'
import { Col, Container, Row } from 'react-bootstrap'
import { getPrivacyContent } from '../../../services/userServices'

const PrivacyPolicy = () => {
    useEffect(() => {
        fetchData();
    }, []);
    const [content, setContent] = useState([])
    const fetchData = async () => {
        try {
            const resp = await getPrivacyContent();
            if (resp.status === 200) {
                setContent(resp.data.content);
            }
        } catch (error) {
            console.log(error, "error");
        }
    };

    return (
        <>
            <Header />
            <Container>
                <Row className='py-5'>
                    <Col className='list-style'>
                        <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default PrivacyPolicy